import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, disputeReasonsBuyer, disputeReasonsSeller, maxDescriptionLength, acceptedImageTypes, maxImagesLength, maxImagesSize } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function DisputeEvidenceModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (state.inputModal?.action != "upload-evidence") return;

        var { listing, button } = state.inputModal?.data;

        console.log(listing.disputeFiles);

        setData(values => (
            { ...values, listing: listing, button: button }
        ));
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;

        $(data.button).html(textStrings["upload_evidence"]?.[state.language]).attr("disabled", false);
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    useEffect(() => {
        $("textarea").each(function() {
            this.setAttribute("style", "height:" + this.scrollHeight + "px; overflow-y: hidden");
        }).on("input", function() {
            this.style.height = 0;
            this.style.height = this.scrollHeight + "px";
        });
    }, [inputs.description]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleImageChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const files = event.target.files;

        if (files?.length + inputs?.files?.length > maxImagesLength) {
            // add the files that aren't over the limit to the inputs list

            $("label[for='images'], span.images-left").addClass("limit");

            setTimeout(() => {
                $("label[for='images'], span.images-left").removeClass("limit");
            }, 1000);

            return event.preventDefault();
        };

        var validSize = Object.keys(files).every(file => files[file].size <= maxImagesSize);

        if (!validSize) {
            alert("You cannot upload files larger than " + Math.floor(maxImagesSize / 1000000) + "MB");
            // have the max size text flash red

            return event.preventDefault();
        };

        var validFormat = Object.keys(files).forEach(file => console.log(files[file]));

        var validFormat = Object.keys(files).every(file => acceptedImageTypes.includes(files[file].type));

        if (!validFormat) {
            alert("You can only upload PDF, JPEG, JPG, PNG, WEBP");

            return event.preventDefault();
        };

        var photoArr = inputs.files || [];

        var thumbnailImg = photoArr.length < 1 ? files[0] : inputs.thumbnail;

        Object.keys(files).forEach(file => photoArr.push(files[file]));

        setInputs(values => (
            { ...values, [name]: photoArr, thumbnail: thumbnailImg }
        ));
    };

    function handleRemoveImage(index) {
        var imagesCopy = inputs.files;

        imagesCopy.splice(index, 1);

        setInputs(values => (
            { ...values, files: imagesCopy, thumbnail: imagesCopy[0] }
        ));
    };

    function handleConfirm(event) {
        event.preventDefault();

        if (!inputs?.files && !inputs?.description) return;

        $("button.confirm").html("<i class='fad fa-spinner-third'></i> Submitting").attr("disabled", true);

        var showSuccess = (res) => {
            $("div.input-modal, section").removeClass("active");

            setInputs(() => ({}));

            dispatch({
                type: "fetchDisputes",
                value: true
            });

            $("button.confirm").html(textStrings["submit"]?.[state.language]).attr("disabled", false);

            $(data.button).html("Upload Evidence");

            $("div.error-modal").addClass("success").find("div.text").html("Evidence has been successfully submitted");
        };

        var showError = (message, error) => {
            $("div.error-modal").addClass("error").find("div.text").html(message || "There was an error authenticating your request, please try again");

            $("button.confirm").html(textStrings["submit"]?.[state.language]).attr("disabled", false);
        };
        
        Listing.uploadEvidence(state.session.auth.actor, data.listing?.disputeId, inputs?.files, inputs?.description).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message?.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Listing.uploadEvidence(state.session.auth.actor, data.listing?.disputeId, inputs?.files, inputs?.description).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                showError(message);
            };
        });
    };

    return (
        <section className="upload-evidence">
            <div className="content">
                <div className="form-title"><i className="fal fa-folders"></i> { textStrings["upload_evidence"]?.[state.language] }</div>

                <div className="create-dispute-wrapper">
                    <form onSubmit={ handleConfirm }>
                        <div className="dispute-summary">
                            <div>
                                <span>Dispute Number</span>
                                <div>{ data.listing?.disputeId }</div>
                            </div>

                            <div>
                                <span>Dispute Date</span>
                                <div>{ new Date(data.listing?.disputeCreated * 1000).toLocaleDateString() }, { new Date(data.listing?.disputeCreated * 1000).toLocaleTimeString().slice(0, 5) }</div>
                            </div>

                            <div>
                                <span>Counterparty</span>
                                <div>{ data.listing?.sellerId }</div>
                            </div>

                            <div>
                                <span>Order Total</span>
                                <div>{ ((parseFloat(data.listing?.purchasedVariation.price) * data.listing?.purchasedQuantity) + parseFloat(data.listing?.purchasedShipping || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                            </div>
                        </div>

                        <div className="input-group images">
                            <label>Evidence</label>

                            <div className="image-buttons">
                                <label htmlFor="files"><i className="fal fa-images"></i> Upload Evidence</label>
                            </div>

                            <input type="file" name="files" id="files" onChange={ handleImageChange } accept=".pdf,.jpg,.jpeg,.png,.webp" multiple/>

                            <div className={ "image-previews" + (inputs.files?.length > 0 || data.listing?.disputeFiles.length > 0 ? "" : " empty") }>
                                {
                                    data.listing?.disputeFiles?.map((image, index) => (
                                        <div key={ index } className="image-wrapper uploaded">
                                            {
                                                (image.src.includes(".pdf")) && (
                                                    <>
                                                        <i className="fal fa-file-pdf"></i>
                                                        <span>Document { image.id }</span>
                                                    </>
                                                )
                                            }

                                            {
                                                (!image.src.includes(".pdf")) && (
                                                    <img src={ image.src }/>
                                                )
                                            }
                                        </div>
                                    ))
                                }

                                {
                                    inputs.files?.map((image, index) => (
                                        <div key={ index } className="image-wrapper">
                                            {
                                                (image.type == "application/pdf") && (
                                                    <>
                                                        <i className="fal fa-file-pdf"></i>
                                                        <span>{ image.name }</span>
                                                    </>
                                                )
                                            }

                                            {
                                                (image.type != "application/pdf") && (
                                                    <img src={ URL.createObjectURL(image) }/>
                                                )
                                            }

                                            <i className="fal fa-times click" onClick={ () => handleRemoveImage(index) }></i>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="input-group">
                            <label htmlFor="description">Additional Notes</label>

                            <textarea type="text" name="description" autoComplete="none" maxLength={ maxDescriptionLength } value={ decodeURIComponent(inputs.description || "") } onChange={ handleChange }/>

                            <span className="chars-left">{ (inputs.description?.length || 0) + "/" + maxDescriptionLength + " characters" }</span>
                        </div>

                        <div className="notice">By clicking <b>{ textStrings["submit"][state.language] }</b> you certify that the above information is true and accurate to the best of your knowledge and you understand you may subject your account to penalties in the event that the above information is found to be falsified.</div>

                        <button type="submit" className="submit confirm">{ textStrings["submit"][state.language] }</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default DisputeEvidenceModal;