import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, regionCodesDictionary, countryCodesDictionary, supportedCurrencies, networkMemos } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Paymex from "assets/javascript/paymex";

function CreateAccount() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (!state.inputModal) return;
    }, [state.inputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (name == "username") {
            value = value.replace(/[^a-z1-5]/g, "");

            const lengthCheck = (value.length >= 4 && value.length <= 12);
            const characterCheck = (value.length > 0 && !value.match(/[^a-z1-5]/g));
            const caseCheck = (value.length > 0 && !value.match(/[A-Z]/g));

            if (lengthCheck && characterCheck && caseCheck) {
                const timestamp = Date.now();

                Paymex.accountExists(value).then(res => {
                    if (inputs.timestamp > timestamp) return;

                    setInputs(values => (
                        { ...values, timestamp: Date.now(), uniqueCheck: !res }
                    ));
                });
            };

            setInputs(values => (
                { ...values, lengthCheck: lengthCheck, characterCheck: characterCheck, caseCheck: caseCheck }
            ));
        };

        setInputs(values => (
            { ...values, [name]: value }
        ));

        $(".form-alert").removeClass("active");
    };

    function handleSubmit(event) {
        event.preventDefault();

        $("button.create-account").html("<i class='fad fa-spinner-third'></i> Creating account").attr("disabled", true);

        Paymex.accountExists(inputs.username).then(res => {
            if (!res) {
                Paymex.createAccount(state, dispatch, state.w3aUser, inputs.username).then(res => {
                    dispatch({
                        type: "setWallet",
                        value: { session: { auth: { actor: res, permission: "active" } }, link: null }
                    });
            
                    dispatch({
                        type: "setAuth",
                        value: { id: res }
                    });

                    $("div.input-modal, section").removeClass("active");

                    $("button.create-account").html(textStrings["create_account"][state.language]).attr("disabled", false);

                    navigate("/account/settings");
                }).catch(error => {
                    console.log(error);

                    $("button.create-account").html(textStrings["create_account"][state.language]).attr("disabled", false);
                });
            };
        }).catch(error => {
            console.log(error);

            $("button.create-account").html(textStrings["create_account"][state.language]).attr("disabled", false);
        });
    };

    function handleLogout() {
        if (state.w3aConnected) return Paymex.disconnectWallet(state, dispatch);

        if (!state.link || !state.session) return;

        state.link.removeSession("Storex", state.session.auth).then(res => {
            dispatch({
                type: "setAuth",
                value: null
            });

            dispatch({
                type: "setWallet",
                value: null
            });
        });
    };

    return (
        <section className="create-account">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-user-circle"></i> Create Account <span className="click sign-out" onClick={ handleLogout }>Sign Out</span></div>

                <div className="create-wrapper">
                    <form className="refund-order" onSubmit={ handleSubmit }>
                        <div className="input-group full">
                            <label htmlFor="email">Email</label>
                            <i className="fal fa-lock"></i>
                            <input type="email" name="email" value={ state.w3aUser?.email } disabled/>
                        </div>

                        <div className="input-group full">
                            <i className="fal fa-at"></i>
                            <label htmlFor="username">Username</label>
                            <input type="text" name="username" autoComplete="off" minLength="4" maxLength="12" value={ inputs?.username || "" } onChange={ handleChange } required/>
                        </div>

                        <div className="conditions">
                            <span>{ inputs.uniqueCheck ? <><i className="fal fa-check-circle"></i> Available</> : <><i className="fal fa-times-circle"></i> Not Available</> }</span>
                            <span>{ inputs.lengthCheck ? <i className="fal fa-check-circle"></i> : <i className="fal fa-times-circle"></i> } Between 4-12 characters long</span>
                            <span>{ inputs.characterCheck ? <i className="fal fa-check-circle"></i> : <i className="fal fa-times-circle"></i> } Contains letters (a-z) and numbers (1-5)</span>
                            <span>{ inputs.caseCheck ? <i className="fal fa-check-circle"></i> : <i className="fal fa-times-circle"></i> } Contains lowercase letters</span>
                        </div>

                        <button type="submit" className="submit create-account" disabled={ !inputs.uniqueCheck || !inputs.lengthCheck || !inputs.characterCheck || !inputs.caseCheck }>Submit</button>

                        <div className="info">By clicking the Submit button you agree you have read and accepted our <Link to="/user-agreement" target="_blank">User Agreement</Link> and <Link to="/user-agreement" target="_blank">Privacy Notice</Link> regarding the processing of your data.</div>
                    </form>
                </div>
            
            </div>
        </section>
    );
};

export default CreateAccount;