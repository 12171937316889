import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, dummyListings } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

function MyCart() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ checkoutDisabled: true });
    const [data, setData] = useState({ categories: dummyCategories, listing: dummyWeeklyDeal, dummyListings: dummyListings });

    useEffect(() => {
        // fetch updated listing stocks/prices from server

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        setInputs(values => ({
            ...values,
            checkoutDisabled: !(state.cart.length > 0)
        }));
    }, [state.cart]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/cart"
            });
            
            return navigate("/", { replace: true });
        };

        if (state.authenticated && state.account.deliveryAddress.length < 1) return determineLocation();

        setLocation(state.account?.deliveryAddress?.[0]?.country);
    }, [state.account]);

    function determineLocation() {
        const f = async () => {
            var req = await fetch("https://ipinfo.io/json?token=d637d120b18ed1");
            var data = await req.json();

            if (data.hasOwnProperty("country")) {
                if (regionDictionary[data.region]) {
                    setLocation(regionDictionary[data.region]);
                } else {
                    setLocation(data.country);
                };
            };
        };

        f();
    };

    function setLocation(countryCode) {
        if (!countryCode) return;

        setInputs(values => ({ ...values, shippingLocation: countryCode }));
    };

    function handleCart(addItem) {
        if (inputs.listing.isCarted && !addItem) {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res
                });

                //$("div.error-modal").addClass("success").find("div.text").html("Removed listing from shopping cart");
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error removing listing from shopping cart");
            };

            Cart.removeListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.removeListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                            showSuccess();
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        } else {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res.cart
                });

                if (res.exceedStock) {
                    //$("div.error-modal").addClass("warning").find("div.text").html("Added listing to shopping cart with remaining stock");
                } else {
                    //$("div.error-modal").addClass("success").find("div.text").html("Added listing to shopping cart");
                };
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error adding listing to shopping cart");
            };

            Cart.addListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.addListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                            showSuccess();
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        };
    };

    function handleClearCart() {
        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error);
        };

        Cart.clearCart(state.session.auth.actor).then(res => {
            dispatch({
                type: "setCart",
                value: res.cart
            });
        }).catch(err => {
            var { message } = err?.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Cart.clearCart(state.session.auth.actor).then(res => {
                        dispatch({
                            type: "setCart",
                            value: res.cart
                        });
                    }).catch(err => {
                        var { message } = err?.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    var { message } = err?.responseJSON || { message: err };

                    showError(message);
                });
            } else {
                showError(message);
            };
        });
    };

    function handleCart(addItem, itemId, variation) {
        if (!addItem) {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res
                });

                //$("div.error-modal").addClass("success").find("div.text").html("Removed listing from shopping cart");
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error removing listing from shopping cart");
            };

            Cart.removeListing(state.session.auth.actor, itemId, variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.removeListing(state.session.auth.actor, itemId, variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        } else {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res.cart
                });

                if (res.exceedStock) {
                    //$("div.error-modal").addClass("warning").find("div.text").html("Added listing to shopping cart with remaining stock");
                } else {
                    //$("div.error-modal").addClass("success").find("div.text").html("Added listing to shopping cart");
                };
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error adding listing to shopping cart");
            };

            Cart.addListing(state.session.auth.actor, itemId, variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.addListing(state.session.auth.actor, itemId, variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        };
    };

    function handleCoupon(button) {
        $(button).prev().css("border-color", "#d35f5f");

        setTimeout(() => {
            $(button).prev().css("border-color", "");
        }, 1000);
    };

    return (
        <section className="cart">
            <div className="page-header">My Cart</div>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><p>Home</p></Link>
                    <span>></span>
                    <Link to="/cart"><p>{ textStrings["my_cart"][state.language] }</p></Link>
                </div>

                <div className="cart-wrapper">
                    <div className="cart-contents">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th className="tc400">Price / Qty</th>
                                    <th className="n400">Price</th>
                                    <th className="n400">Quantity</th>
                                    <th className="n420">Subtotal</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    (state.cart?.length < 1) && (
                                        <tr>
                                            <td>
                                                <div className="empty">Your shopping cart is empty</div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                }

                                {
                                    state.cart?.map((item, index) => (
                                        <tr key={ index }>
                                            <td className="product">
                                                <Link to={ "/listing/" + item._id }>
                                                    <div className="thumbnail">
                                                        <img src={ item.thumbnail } alt={ item.title }/>
                                                    </div>
                                                </Link>

                                                <div className="text">
                                                    <Link to={ "/listing/" + item._id }>
                                                        <div className="title">{ item.title }</div>
                                                    </Link>
                                                    {
                                                            (item.variation.option0) && (
                                                                <span className="variation">
                                                                    <p>{ item.variation.option0 }</p>

                                                                    {
                                                                        (item.variation.option1) && (
                                                                            <>
                                                                                <p>/</p>
                                                                                <p>{ item.variation.option1 }</p>
                                                                            </>
                                                                        )
                                                                    }

                                                                    {
                                                                        (item.variation.option2) && (
                                                                            <>
                                                                                <p>/</p>
                                                                                <p>{ item.variation.option2 }</p>
                                                                            </>
                                                                        )
                                                                    }
                                                                </span>
                                                            )
                                                        }
                                                    <div className="stock">{ item.variation?.stock > 0 ? <div className="in-stock"><i className="fas fa-check"></i><span>In Stock</span></div> : <div className="out-stock"><i className="fas fa-times"></i><span>Out of Stock</span></div> }</div>
                                                </div>
                                            </td>
                                            <td className="price quantity tc400">
                                                <div className="price">{ parseFloat(item.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                                <div className="qty"><i className="fal fa-minus-circle" onClick={ () => handleCart(false, item._id, item.variation.id) }></i> { item.quantity } <i className="fal fa-plus-circle" onClick={ () => handleCart(true, item._id, item.variation.id) }></i></div>
                                            </td>
                                            <td className="price n400">{ parseFloat(item.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</td>
                                            <td className="quantity n400"><i className="fal fa-minus-circle" onClick={ () => handleCart(false, item._id, item.variation.id) }></i> { item.quantity } <i className="fal fa-plus-circle" onClick={ () => handleCart(true, item._id, item.variation.id) }></i></td>
                                            <td className="total n420">{ parseFloat(parseFloat(item.price) * item.quantity).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                        <div className="cart-actions">
                            {/* {<Link to="/"><button className="shopping secondary"><i className="fal fa-long-arrow-left"></i> Continue Shopping</button></Link>} */}

                            <button className="clear secondary n768" onClick={ handleClearCart }>Clear Cart</button>
                        </div>
                    </div>

                    <div className="cart-sidebar">
                        <div className="cart-summary">
                            <div className="subtitle">Summary</div>

                            {
                                <div className="delivery-estimate">
                                    <i className="fal fa-shield-check"></i>
                                    <p>Escrow Available</p>
                                </div>
                            }

                            <div className="delivery-estimate">
                                <i className="fal fa-globe-americas"></i>
                                <p>Delivery to { countryCodesDictionary[inputs.shippingLocation] }</p>
                            </div>

                            <div className="delivery-estimate">
                                <i className="fal fa-shipping-fast"></i>
                                <p>Express Shipping Available</p>
                            </div>

                            <div className="delivery-estimate">
                                <i className="fal fa-badge-dollar"></i>
                                <p>Cashback Eligibility</p>
                            </div>

                            <div className="total">
                                <p>Subtotal</p>
                                <span>{ state.cart?.reduce((a, b) => a = a + parseFloat(parseFloat(b.price) * b.quantity), 0).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</span>
                            </div>

                            <Link to={ inputs.checkoutDisabled ? "" : "/checkout" }><button className={ inputs.checkoutDisabled ? "checkout disabled" : "checkout" }>Checkout <i className="fal fa-long-arrow-right"></i></button></Link>
                        </div>

                        <div className="cart-voucher">
                            <div className="subtitle">Coupon / Voucher</div>

                            <div className="input-group">
                                <input type="text"/>
                                <button className="apply" onClick={ (e) => handleCoupon(e.target) }>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MyCart;
