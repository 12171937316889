import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { dummyCategories } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

function Footer() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({ newsletter: "" });
    const [data, setData] = useState({ categories: {} });
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        // fetch categories/subcategories from server
        setTimeout(() => {
            setData(values => (
                {
                    ...values,
                    categories: dummyCategories
                }
            ));
        }, 200)
    }, []);

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleNewsletter(event) {
        event.preventDefault();

        $(".subscribe button").attr("disabled", true);
        $("form.subscribe > i").removeClass().addClass("fas fa-spinner-third");
        $("form.subscribe").addClass("pending").find("input[name='newsletter']").attr("onkeypress", "return false").val("Subscribing to newsletter");

        // send subscribe request to API server
        setTimeout(function() {
            $("form.subscribe > i").removeClass().addClass("fas fa-check-circle");
            $("form.subscribe").removeClass("pending").addClass("success").find("input[name='newsletter']").val("Successfully subscribed to newsletter");

            setTimeout(function() {
                $(".subscribe button").attr("disabled", false);
                $("form.subscribe").removeClass("success error").find("input[name='newsletter']").attr("onkeypress", "").val("");
            }, 2000);
        }, 2000);
    };

    return (
        <section className="footer">
            <div className="footer-middle">
                <div className="container">
                    <div className="wrapper">
                        <div className="company-info">
                            <img src="/assets/images/storex_logo.svg" alt=""/>

                            <div className="email">
                                <p>Have a question? Email us</p>
                                <a href="mailto:contact@storex.io" target="_blank"><span>contact@storex.io</span></a>
                            </div>

                            <div className="social">
                                <p>Connect on social media</p>
                                <div className="icons">
                                    <a href="https://twitter.com/storex_" target="_blank"><div className="icon"><i className="fab fa-twitter"></i></div></a>
                                    <a href="https://t.me/storexio" target="_blank"><div className="icon"><i className="fab fa-telegram-plane"></i></div></a>
                                    <a href="https://instagram.com/storex.io" target="_blank"><div className="icon"><i className="fab fa-instagram bold"></i></div></a>
                                    <a href="https://linkedin.com/company/37570591" target="_blank"><div className="icon"><i className="fab fa-linkedin"></i></div></a>
                                </div>
                            </div>
                        </div>

                        <div className="sitemap n480">
                            <div className="map-column">
                                <div className="column-title">Company</div>

                                <a href="https://storex.io" target="_blank">About Us</a>
                                <a href="https://storex.io" target="_blank">Career</a>
                                <Link to="/user-agreement">User Agreement</Link>
                                <Link to="/privacy-notice">Privacy Notice</Link>
                                <Link to="/privacy-notice">Cookie Notice</Link>
                            </div>

                            <div className="map-column">
                                <div className="column-title">Account</div>

                                <Link to="/account/settings">Settings</Link>
                                <Link to="/account/wallet">Wallet</Link>
                                <Link to="/account/orders">Orders</Link>
                                <Link to="/account/selling">Selling</Link>
                                <Link to="/account/arbitration">Arbitration</Link>
                            </div>

                            <div className="map-column">
                                <div className="column-title">Help Center</div>

                                <Link to="/help-center">Frequently Asked Questions</Link>
                                <Link to="/help-center">Account Management</Link>
                                <Link to="/help-center">Wallet Management</Link>
                                <Link to="/help-center">Payment Issues</Link>
                                <Link to="/help-center">Disputes</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="newsletter">
                <div className="container">
                    <div className="info">
                        <i className="fal fa-envelope n420"></i>

                        <div className="text">
                            <div className="title">Subscribe to our newsletter</div>
                            <div className="subtitle">Get the latest information, updates, and promotions</div>
                        </div>
                    </div>

                    <form className="subscribe" onSubmit={ handleNewsletter }>
                        <i></i>
                        <input type="email" name="newsletter" autoComplete="email" placeholder="Enter your email address" value={ inputs.newsletterEmail } onChange={ handleChange }/>
                        <button type="submit">Subscribe <i className="fal fa-long-arrow-right no-spin"></i></button>
                    </form>
                </div>
            </div>

            {
                /*
                    <div className="footer-bottom">
                        <div className="container">
                            {
                                Object.keys(data.categories).filter(category => category != "").map((category, index) => (
                                    <div key={ index } className="cat-row">
                                        <div className="title">{ textStrings[category][state.language] }:</div>

                                        {
                                            Object.keys(data.categories[category]["subcategories"]).map((subcategory, index2) => (
                                                <Link key={ index2 } to={ data.categories[category]["url"] + data.categories[category]["subcategories"][subcategory]["url"] }><span>{ textStrings[subcategory][state.language] }</span></Link>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                */
            }

            <div className="copyright">
                <div className="container">
                    <div className="copyright-notice">Copyright © { new Date().getFullYear() } Storex Marketplace Ltd - Registered in England and Wales - Company Number 12619609</div>

                    <a href="https://xprnetwork.org" target="_blank"><div className="powered-by"><img src="/assets/images/brands/xprnetwork.svg" alt=""/></div></a>
                </div>
            </div>
        </section>
    );
};

export default Footer;
