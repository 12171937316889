import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { dummySpotlight, dummyWeeklyDeal, dummyBestSellers, dummyFilterData, dummyCategoryListings1, dummyRecentlyViewed } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Cart from "assets/javascript/cart";
import * as Wishlist from "assets/javascript/wishlist";
import * as Proton from "assets/javascript/proton";

function Index() {
    const navigate = useNavigate();
    const [slidePosition, setPosition] = useState(0);
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ weeklyDeal: { scrollTop: 0, scrollBottom: 3, variation: 0, option0: [], option1: [], option2: [], isCarted: false, isWished: false, buttonClass: "" }, selectedTab: "bestSellers", selectedLoading: false });
    const [data, setData] = useState({ spotlight: dummySpotlight, filterListings: dummyFilterData, recentlyViewed: dummyRecentlyViewed });

    useEffect(() => {
        Listing.fetchWeeklyDeal().then(res => {
            res.variations.sort((a, b) => a.id - b.id);

            setData(values => (
                {
                    ...values,
                    weeklyDeal: res
                }
            ));
        });

        Listing.fetchFeatured().then(res => {
            setData(values => (
                {
                    ...values,
                    bestSellers: res.bestSellers.map(b => { return { ...b, reviews: { count: 1, rating: 5 } } }),
                    featured: res.featured,
                    newlyListed: res.newlyListed,
                    trending: res.trending
                }
            ));
        });

        Listing.fetchCategoryListings("computers_hardware", "", 8).then(res => {
            res = res.filter(l => l.active);

            setData(values => (
                {
                    ...values,
                    categoryListings1: res
                }
            ));
        });

        Listing.fetchCategoryListings("phones_smart_watches", "", 8).then(res => {
            res = res.filter(l => l.active);

            setData(values => (
                {
                    ...values,
                    categoryListings2: res
                }
            ));
        });

        Listing.fetchCategoryListings("fashion", "", 8).then(res => {
            res = res.filter(l => l.active);

            setData(values => (
                {
                    ...values,
                    categoryListings3: res
                }
            ));
        });

        //window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) return;

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    useEffect(() => {
        if (state.viewDeals) {
            window.scrollTo({ top: $(".storex-deals").offset()?.top - 24, left: 0, behavior: "smooth" });

            dispatch({
                type: "viewDeals",
                value: false
            });
        }; 
    }, [state.viewDeals]);

    useEffect(() => {
        var filterArr = state.cart?.filter(item => item._id == data.weeklyDeal?._id && item.variation.id == inputs.weeklyDeal.variation);

        var isCarted = (filterArr.length > 0) ? true : false;

        var filterArrWish = state.wishlist?.filter(item => item._id == data.weeklyDeal?._id && item.variation.id == inputs.weeklyDeal.variation);

        var isWished = (filterArrWish?.length > 0) ? true : false;

        setInputs(values => (
            { ...values, weeklyDeal: { ...values.weeklyDeal, isCarted: isCarted, isWished: isWished } }
        ));
    }, [state.cart, state.wishlist, inputs.weeklyDeal.variation]);

    useEffect(() => {
        if (data.weeklyDeal?.options.length > 0) {
            data.weeklyDeal?.options.forEach((option, index) => {
                $("input[name='option" + index + "']").first().attr("checked", true);
                handleOptionChange({ target: { name: "option" + option.id, value: $("input[name='option" + option.id + "']").val() } }, true);
            });
        };
    }, [data.weeklyDeal?.options]);

    function handleDataFetch() {
        Listing.fetchRecentlyViewed(state.session.auth.actor).then(res => {
            setData(values => (
                {
                    ...values,
                    recentlyViewed: res
                }
            ));
        });
    };

    function handleProgress(position) {
        var offsetLeft = document.getElementById("slide-" + position).getBoundingClientRect().left;

        document.getElementById("slideshow").scrollBy({ top: 0, left: offsetLeft, behavior: "smooth" });

        setPosition(position);
    };

    function handleRating(rating) {
        var classObject = {};
        var remainder = (rating % 1).toFixed(1);

        for (var i = 0; i < Math.floor(rating); i++) {
            classObject[i] = "fas fa-star fill";
        };

        if (remainder > 0 && remainder < 0.3) {
            classObject[Math.floor(rating)] = "fas fa-star";
        } else if (remainder >= 0.3 && remainder < 0.8) {
            classObject[Math.floor(rating)] = "fad fa-star-half";
        } else if (remainder >= 0.8) {
            classObject[Math.floor(rating)] = "fas fa-star";
        };

        for (var i = 0; i < 5; i++) {
            if (!classObject[i]) classObject[i] = "fas fa-star";
        };

        return (
            <div>
                <i className={ classObject[0] }></i>
                <i className={ classObject[1] }></i>
                <i className={ classObject[2] }></i>
                <i className={ classObject[3] }></i>
                <i className={ classObject[4] }></i>
            </div>
        );
    };

    function handleThumbnail(event, src) {
        if ($(event.target).parent().attr("id").split("-")[1] == inputs.weeklyDeal.scrollTop) handleScroll(false);
        if ($(event.target).parent().attr("id").split("-")[1] == inputs.weeklyDeal.scrollBottom) handleScroll(true);

        setData(values => (
            { ...values, weeklyDeal: { ...values.weeklyDeal, thumbnail: src } }
        ));
    };

    function handleScroll(increment) {
        if (increment) {
            if (inputs.weeklyDeal.scrollBottom > data.weeklyDeal?.images.length - 2) return;

            setInputs(values => (
                { ...values, weeklyDeal: { ...values.weeklyDeal, scrollTop: inputs.weeklyDeal.scrollTop + 1, scrollBottom: inputs.weeklyDeal.scrollBottom + 1 } }
            ));

            document.getElementById("previews").scrollBy({ top: 0, left: 90, behavior: "smooth" });
        } else {
            if (inputs.weeklyDeal.scrollTop < 1) return;

            setInputs(values => (
                { ...values, weeklyDeal: { ...values.weeklyDeal, scrollTop: inputs.weeklyDeal.scrollTop - 1, scrollBottom: inputs.weeklyDeal.scrollBottom - 1 } }
            ));

            document.getElementById("previews").scrollBy({ top: 0, left: -90, behavior: "smooth" });
        };
    };

    function handleOptionChange(event, manual) {
        // initialise vars
        var selectedOptions = {};
        var selectedVariation = { id: -1, price: data.weeklyDeal?.price, stock: 0 };

        // construct object with currently selected options
        data.weeklyDeal?.options.forEach((option, optionIndex) => {
            selectedOptions["option" + optionIndex] = $("input[name='option" + optionIndex + "']:checked").val();
        });

        // iterate variations and check if the option values match the currently selected option values
        data.weeklyDeal?.variations.forEach((variation, variationIndex) => {
            var variationMatch = true;

            Object.keys(selectedOptions).map((optionName) => {
                if (!variationMatch) return;

                if (selectedOptions[optionName] != variation[optionName]) variationMatch = false;
            });

            if (variationMatch) {
                selectedVariation.id = variation.id;
                selectedVariation.price = variation.price;
                selectedVariation.stock = variation.stock;
            };
        });

        // set the selected variation id, price, stock
        setInputs(values => (
            { ...values, weeklyDeal: { ...values.weeklyDeal, variation: selectedVariation.id } }
        ));

        setData(values => (
            { ...values, weeklyDeal: { ...values.weeklyDeal, price: selectedVariation.price, stock: selectedVariation.stock } }
        ));

        // check which option combinations are available with the currently selected options
        
        // make non-selected options disabled, if available, re-enable
        $(".options input").not(":checked").attr("disabled", true);

        // iterate selected options and construct array of which ones need disabling
        Object.keys(selectedOptions).map((optionName) => {
            // iterate variations if optionName val == variation optionName val
            data.weeklyDeal?.variations.forEach((variation, variationIndex) => {
                if (!variation[optionName]) return;

                if (variation[optionName] == selectedOptions[optionName]) {
                    Object.keys(variation).map(name => {
                        if (name.includes("option") && name != optionName) {
                            $("input[name='" + name + "'][value='" + variation[name] + "']").attr("disabled", false);
                        }
                    });
                };
            });
        });
    };

    function handleOptionReset(fullReset) {
        if (data.weeklyDeal?.options.length > 0) {
            data.weeklyDeal?.options.forEach((option, optionIndex) => {
                var optionInputs = $("input[name='option" + optionIndex + "']");

                for (let i = 0; i < optionInputs.length; i++) {
                    $(optionInputs[i]).prop("checked", false).prop("disabled", false);

                    if (i == 0 && !fullReset) $(optionInputs[i]).prop("checked", true);
                };
            });

            if (!fullReset) handleOptionChange({ target: { name: "option0", value: $("input[name='option0']").first().val() } });
        };
    };

    function handlePurchase(event) {
        if (inputs.checkoutDisabled) return;

        if (inputs.weeklyDeal.isCarted) {
            navigate("/checkout");
        } else {
            handleCart(true, true);
        };
    };

    function handleCart(addItem, purchase) {
        if (inputs.checkoutDisabled) return;
        
        if (inputs.weeklyDeal.isCarted && !addItem) {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res
                });

                //$("div.error-modal").addClass("success").find("div.text").html("Removed listing from shopping cart");
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error removing listing from shopping cart");
            };

            Cart.removeListing(state.session.auth.actor, data.weeklyDeal?._id, inputs.weeklyDeal.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                var { message } = error.responseJSON;

                if (message == "no auth") {
                    dispatch({
                        type: "promptLogin",
                        value: "/"
                    });

                    return;
                };

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.removeListing(state.session.auth.actor, data.weeklyDeal?._id, inputs.weeklyDeal.variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        } else {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res.cart
                });
                
                if (purchase) return navigate("/checkout");

                if (res.exceedStock) {
                    //$("div.error-modal").addClass("warning").find("div.text").html("Added listing to shopping cart with remaining stock");
                } else {
                    //$("div.error-modal").addClass("success").find("div.text").html("Added listing to shopping cart");
                };
            };

            var showError = (e) => {
                $("div.error-modal").addClass("error").find("div.text").html(e || "There was an error adding listing to shopping cart");
            };

            Cart.addListing(state.session.auth.actor, data.weeklyDeal?._id, inputs.weeklyDeal.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                var { message } = error.responseJSON;

                if (message == "no auth") {
                    dispatch({
                        type: "promptLogin",
                        value: "/"
                    });

                    return;
                };

                if (message == "cart_ac_vari_no_stock") {
                    showError("This variation is not in stock, please choose a different one");

                    return;
                };

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.addListing(state.session.auth.actor, data.weeklyDeal?._id, inputs.weeklyDeal.variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        };
    };

    function handleWishlist(addItem) {
        if (inputs.weeklyDeal.isWished && !addItem) {
            var showSuccess = (res) => {
                dispatch({
                    type: "setWishlist",
                    value: res
                });

                //$("div.error-modal").addClass("success").find("div.text").html("Removed listing from shopping cart");
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error removing listing from wishlist");
            };

            Wishlist.removeListing(state.session.auth.actor, data.weeklyDeal?._id, inputs.weeklyDeal.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                var { message } = error.responseJSON;

                if (message == "no auth") {
                    dispatch({
                        type: "promptLogin",
                        value: "/"
                    });

                    return;
                };

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Wishlist.removeListing(state.session.auth.actor, data.weeklyDeal?._id, inputs.weeklyDeal.variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        } else {
            var showSuccess = (res) => {
                dispatch({
                    type: "setWishlist",
                    value: res.wishlist
                });
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error adding listing to wishlist");
            };

            Wishlist.addListing(state.session.auth.actor, data.weeklyDeal?._id, inputs.weeklyDeal.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                var { message } = error.responseJSON;

                if (message == "no auth") {
                    dispatch({
                        type: "promptLogin",
                        value: "/"
                    });

                    return;
                };

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Wishlist.addListing(state.session.auth.actor, data.weeklyDeal?._id, inputs.weeklyDeal.variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        };
    };

    function handlePopularFilter(tab) {
        $(".popular-filters .listings-wrapper").css("opacity", 0);

        setTimeout(() => {
            setInputs(values => (
                { ...values, selectedTab: "", selectedLoading: true }
            ));

            setTimeout(() => {
                setInputs(values => (
                    { ...values, selectedTab: tab }
                ));

                setTimeout(() => {
                    setInputs(values => (
                        { ...values, selectedLoading: false }
                    ));

                    setTimeout(() => {
                        $(".popular-filters .listings-wrapper").css("opacity", 1);
                    }, 200);
                }, 200);
            }, 200);
        }, 200);
    };

    return (
        <section className="index">
            <div className="slideshow-wrapper">
                <div className="container-full" id="slideshow">
                    {
                        data.spotlight.map((spotlight, index) => {
                            return (
                                <div key={ index } className={ "slideshow " + spotlight.name } id={ "slide-" + index }>
                                    <img className="slideshow-background" src={ "/assets/images/spotlight/" + spotlight.name + ".png" } alt=""/>

                                    <div className="content-wrapper">
                                        <div className="content">
                                            <div className="title">{ spotlight.title }</div>
                                            <div className="subtitle">{ spotlight.subtitle }</div>
                                            <div className="description">{ spotlight.description }</div>
                                            <Link to={ spotlight.url } className="browse">Browse Products</Link>
                                            { spotlight.brand ? <img src={ "/assets/images/brands/" + spotlight.brand + ".png" } className="brand"/> : null }
                                        </div>

                                        <img src={ "/assets/images/spotlight/" + spotlight.name + "-item.png" } alt=""/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="slideshow-progress">
                    {
                        data.spotlight.map((spotlight, index) => {
                            return (
                                <div key={ index } className={ slidePosition === index ? "slide-" + index +  " active" : "slide-" + index } onClick={ (event) => handleProgress(index) }></div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="features-wrapper">
                <div className="container">
                    <div className="features">
                        <div className="feature">
                            <i className="fal fa-coins"></i>
                            <div className="text">
                                <div className="title">Cryptocurrency</div>
                                <div className="subtitle">Pay with the most popular cryptos</div>
                            </div>
                        </div>

                        <div className="feature">
                            <i className="fal fa-hand-holding-usd"></i>
                            <div className="text">
                                <div className="title">Earn Cashback</div>
                                <div className="subtitle">Receive 5% back on eligible purchases</div>
                            </div>
                        </div>

                        <div className="feature">
                            <i className="fal fa-shield-check"></i>
                            <div className="text">
                                <div className="title">Escrow Protection</div>
                                <div className="subtitle">Purchases can be protected from fraud</div>
                            </div>
                        </div>

                        <div className="feature n768 f600">
                            <i className="fal fa-shipping-fast"></i>
                            <div className="text">
                                <div className="title">Fast Delivery</div>
                                <div className="subtitle">Express delivery options available</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="popular-categories">
                <div className="container">
                    <div className="category">
                        <Link to="/category/sporting" className="click">
                            <img className="category-background" src={ "/assets/images/categories/cat_ban_1.webp" } alt=""/>

                            <div className="content top">
                                <div className="heading">Get up to <span>20% off</span></div>
                                <div className="title">Sporting Goods</div>
                            </div>
                        </Link>
                    </div>

                    <div className="category">
                        <Link to="/category/health-beauty/fragrances" className="click">
                            <img className="category-background" src={ "/assets/images/categories/cat_ban_3.webp" } alt=""/>

                            <div className="content light">
                                <div className="heading">New Arrivals</div>
                                <div className="title">Fragrances</div>
                                <div className="example">Starting at <span>$29.80</span></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="storex-deals">
                <div className="container">
                    <div className="weekly-deal">
                        <div className="title">Deal of the Week <i className="fal fa-badge-percent"></i></div>

                        {
                            (data.weeklyDeal == null) && (
                                <div className="loading">
                                    <i className="fad fa-spinner-third"></i>

                                    <span>Loading</span>
                                </div>
                            )
                        }

                        <div className={ data.weeklyDeal == null ? "listing-wrapper" : "listing-wrapper active" }>
                            <div className="thumbnail-wrapper">
                                <div className="thumbnail">
                                    {
                                        (data.weeklyDeal?.thumbnail.search("youtube") == -1) && (
                                            <img src={ data.weeklyDeal?.thumbnail } alt={ data.weeklyDeal?.title }/>
                                        )
                                    }

                                    {
                                        (data.weeklyDeal?.thumbnail.search("youtube") != -1) && (
                                            <iframe src={ "https://youtube.com/embed/" + (data.weeklyDeal?.thumbnail.split("v=")[1]) } frameBorder="0"></iframe>
                                        )
                                    }
                                </div>

                                <div className="previews-wrapper">
                                    <div className="previews" id="previews">
                                        {
                                            data.weeklyDeal?.images.map((image, index) => (
                                                <div className={ "image click " + (index == 0 ? "active" : "") } key={ index } id={ "image-" + index } onClick={ event => handleThumbnail(event, image.src) }>
                                                    {
                                                        (image.src.search("youtube") == -1) && (
                                                            <img src={ image.src } alt=""/>
                                                        )
                                                    }

                                                    {
                                                        (image.src.search("youtube") != -1) && (
                                                            <>
                                                                <i className="fad fa-play-circle"></i>
                                                                <img src={ "https://i.ytimg.com/vi/" + (image.src.split("v=")[1]) + "/hqdefault.jpg" } alt=""/>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="details-wrapper">
                                <Link to={ "/listing/" + data.weeklyDeal?._id }><div className="listing-title">{ data.weeklyDeal?.title }</div></Link>

                                <div className="listing-price">{ parseFloat(data.weeklyDeal?.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>

                                <div className="listing-reviews" title={ data.weeklyDeal?.feedback?.reduce((a, b) => a + b.rating, 0) / data.weeklyDeal?.feedback?.length || 0 }>{ handleRating(data.weeklyDeal?.feedback?.reduce((a, b) => a + b.rating, 0) / data.weeklyDeal?.feedback?.length) } <span>{ "(" + (data.weeklyDeal?.feedback?.length || 0) + " ratings)" }</span></div>

                                <div className="listing-stock">
                                    { data.weeklyDeal?.variations.filter(variation => variation.id == inputs.weeklyDeal.variation)[0]?.stock > 0 ? <div className="in-stock"><i className="fas fa-check"></i><span>In Stock</span></div> : <div className="out-stock"><i className="fas fa-times"></i><span>Out of Stock</span></div> }
                                </div>

                                <div className="listing-options">
                                    {
                                        (data.weeklyDeal?.options.length < 1) && (
                                            <span className="no-options">No options available</span>
                                        )
                                    }

                                    {
                                        (data.weeklyDeal?.options.length > 0) && (
                                            data.weeklyDeal?.options.map((option, index) => (
                                                <div className="attribute" key={ option.id }>
                                                    <span>{ option.name == "custom" ? option.custom : option.name }:</span>
                                                    <div className="radio-wrapper">
                                                        {
                                                            data.weeklyDeal?.variations.map(variation => {
                                                                return variation["option" + index]
                                                            }).filter((value, index, self) => {
                                                                return self.indexOf(value?.trim()) === index
                                                            }).map((variation, index2) => (
                                                                <div key={ index2 }>
                                                                    <input type="radio" id={ variation + "-" + index } name={ "option" + index } value={ variation } disabled={ inputs.weeklyDeal["option" + index][index2] } onChange={ handleOptionChange }/>
                                                                    <label htmlFor={ variation + "-" + index } className="click">{ variation }</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    }

                                    <div className="reset click" onClick={ handleOptionReset }><i className="fal fa-redo"></i> Reset Options</div>
                                </div>

                                <div className="listing-checkout">
                                    <div className="row">
                                        <button className={ inputs.checkoutDisabled ? "purchase disabled" : "purchase" } onClick={ handlePurchase }><i className="fas fa-tag"></i> Purchase</button>
                                    </div>

                                    <div className="row">
                                        {
                                            inputs.weeklyDeal.isCarted ? <button className="cart remove-cart"><i className="far fa-minus-circle" onClick={ () => handleCart(false) }></i> { state.cart.filter(item => item._id == data.weeklyDeal?._id && item.variation.id == inputs.weeklyDeal.variation)[0]?.quantity + " in cart" } <i className="far fa-plus-circle" onClick={ () => handleCart(true) }></i></button> : <button className={ inputs.checkoutDisabled ? "cart add-cart disabled" : "cart add-cart" } onClick={ handleCart }><i className="fas fa-shopping-cart"></i> Add To Cart</button>
                                        }

                                        <button className={ "wishlist" + (inputs.weeklyDeal.isWished ? " wished" : "") } onClick={ () => inputs.weeklyDeal.isWished ? handleWishlist(false) : handleWishlist(true) }><i className={ "fas" + (inputs.weeklyDeal.isWished ? " fa-heart-broken" : " fa-heart") }></i> { inputs.weeklyDeal.isWished ? "Unwishlist" : "Wishlist" }</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="most-popular">
                        <div className="title">Best Sellers <i className="fal fa-fire-alt"></i></div>

                        {
                            (data.bestSellers == null) && (
                                <div className="loading">
                                    <i className="fad fa-spinner-third"></i>

                                    <span>Loading</span>
                                </div>
                            )
                        }

                        <div className={ data.bestSellers == null ? "listings-wrapper" : "listings-wrapper active" }>
                            {
                                data.bestSellers?.slice(0, 4).map((listing, index) => (
                                    <div key={ index } className="listing">
                                        <Link to={ "/listing/" + listing?._id }>
                                            <div className="thumbnail-wrapper">
                                                <img src={ listing?.thumbnail } alt={ listing?.title }/>
                                            </div>
                                        </Link>

                                        <div className="details-wrapper">
                                            <Link to={ "/listing/" + listing?._id }><div className="listing-title">{ listing?.title }</div></Link>
                                            <div className="listing-reviews" title={ listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length || 0 }>{ handleRating(listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length) } <span>{ "(" + (listing?.feedback?.length || 0) + " ratings)" }</span></div>
                                            <div className="listing-price">{ parseFloat(listing?.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="top-categories">
                <div className="container">
                    <div className="title">Trending Categories</div>

                    <div className="categories-wrapper">
                        <Link to="/category/computers-hardware" className="click">
                            <div className="category">
                                <img src="/assets/images/categories/cat-computers.jpg" alt=""/>
                                <div className="name">Computers</div>
                            </div>
                        </Link>

                        <Link to="/category/home-garden/home-furniture" className="click">
                            <div className="category click">
                                <img src="/assets/images/categories/cat-furniture.jpg" alt=""/>
                                <div className="name">Home Furniture</div>
                            </div>
                        </Link>

                        <Link to="/category/fashion/mens-shoes" className="click">
                            <div className="category click">
                                <img src="/assets/images/categories/cat-shoes.jpg" alt=""/>
                                <div className="name">Shoes</div>
                            </div>
                        </Link>

                        <Link to="/category/hobbies-leisure" className="click">
                            <div className="category click">
                                <img src="/assets/images/categories/cat-sports.jpg" alt=""/>
                                <div className="name">Hobbies & Leisure</div>
                            </div>
                        </Link>

                        <Link to="/category/electronics/video-games" className="click n1050 b860">
                            <div className="category click">
                                <img src="/assets/images/categories/cat-games.jpg" alt=""/>
                                <div className="name">Gaming</div>
                            </div>
                        </Link>

                        <Link to="/category/fashion" className="click n1250 b860">
                            <div className="category click">
                                <img src="/assets/images/categories/cat-fashion.jpg" alt=""/>
                                <div className="name">Fashion</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="popular-filters">
                <div className="container">
                    <div className="filter-wrapper">
                        <div className="filters">
                            <div className={ inputs.selectedTab == "featured" ? "filter click active" : "filter click" } onClick={ () => handlePopularFilter("featured") }>Featured</div>
                            <div className={ inputs.selectedTab == "newlyListed" ? "filter click active" : "filter click" } onClick={ () => handlePopularFilter("newlyListed") }>Newly Listed</div>
                            <div className={ inputs.selectedTab == "bestSellers" ? "filter click active" : "filter click" } onClick={ () => handlePopularFilter("bestSellers") }>Best Sellers</div>
                            <div className={ inputs.selectedTab == "trending" ? "filter click active" : "filter click" } onClick={ () => handlePopularFilter("trending") }>Trending</div>
                        </div>
                    </div>

                    {
                        (data[inputs.selectedTab] == null && !inputs.selectedLoading) && (
                            <div className="loading">
                                <i className="fad fa-spinner-third"></i>

                                <span>Loading</span>
                            </div>
                        )
                    }

                    {
                        (inputs.selectedLoading) && (
                            <div className="loading">
                                <i className="fad fa-spinner-third"></i>

                                <span>Fetching listings</span>
                            </div>
                        )
                    }

                    <div className="listings-wrapper">
                        {
                            (!inputs.selectedLoading) && (
                                data[inputs.selectedTab]?.map((listing, index) => (
                                    <div key={ index } className="listing">
                                        <Link to={ "/listing/" + listing._id }>
                                            <div className="thumbnail-wrapper">
                                                <img src={ listing.thumbnail } alt={ listing.title }/>
                                            </div>
                                        </Link>

                                        <div className="details-wrapper">
                                            <Link to={ "/listing/" + listing._id }><div className="title ">{ listing.title }</div></Link>
                                            <div className="rating" title={ listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length || 0 }>{ handleRating(listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length) } <span>{ "(" + (listing?.feedback?.length || 0) + " ratings)" }</span></div>
                                            <div className="price">{ parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="popular-categories">
                <div className="container">
                    <div className="category">
                        <Link to="/category/health-beauty" className="click">
                            <img className="category-background" src={ "/assets/images/categories/cat_ban_2.webp" } alt=""/>

                            <div className="content light">
                                <div className="heading">New Arrivals</div>
                                <div className="title">Health & Beauty</div>
                            </div>
                        </Link>
                    </div>

                    <div className="category">
                        <Link to="/category/fashion/womens-clothing" className="click">
                            <img className="category-background" src={ "/assets/images/categories/cat_ban_4.webp" } alt=""/>

                                <div className="content">
                                    <div className="heading">Get up to <span>10% off</span></div>
                                    <div className="title">Women's Clothing</div>
                                </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="category-preview">
                <div className="container">
                    <div className="section-title">Computers, Network & Hardware <Link to="/category/computers-hardware"><span>Browse More <i className="fal fa-long-arrow-right"></i></span></Link></div>

                    <div className="content">
                        <div className="category-feature one">
                            <div className="feature-subtitle">New Arrivals</div>
                            <div className="feature-title">NVIDIA RTX 30 Series</div>
                            <Link to="/category/computers-hardware/computer-components-parts"><div className="feature-button">Browse Products</div></Link>
                        </div>

                        <div className="category-listings">
                            {
                                (data.categoryListings1 == null) && (
                                    <div className="loading">
                                        <i className="fad fa-spinner-third"></i>

                                        <span>Loading</span>
                                    </div>
                                )
                            }

                            {
                                data.categoryListings1?.slice(0, 8).map((listing, index) => (
                                    <div key={ index } className="listing">
                                        <Link to={ "/listing/" + listing._id }>
                                            <div className="thumbnail-wrapper">
                                                <img src={ listing.thumbnail } alt={ listing.title }/>
                                            </div>
                                        </Link>

                                        <div className="details-wrapper">
                                            <Link to={ "/listing/" + listing._id }><div className="title ">{ listing.title }</div></Link>
                                            <div className="rating" title={ listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length || 0 }>{ handleRating(listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length) } <span>{ "(" + (listing?.feedback?.length || 0) + " ratings)" }</span></div>
                                            <div className="price">{ parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="category-preview">
                <div className="container">
                    <div className="section-title">Phones, Smart Watches & Accessories <Link to="/category/phones-smart-watches"><span>Browse More <i className="fal fa-long-arrow-right"></i></span></Link></div>

                    <div className="content">
                        <div className="category-feature two">
                            <div className="feature-subtitle">Get up to <span>10% off</span></div>
                            <div className="feature-title">Smart Watches</div>
                            <Link to="/category/phones-smart-watches/smart-watches"><div className="feature-button">Browse Products</div></Link>
                        </div>

                        <div className="category-listings">
                            {
                                (data.categoryListings2 == null) && (
                                    <div className="loading">
                                        <i className="fad fa-spinner-third"></i>

                                        <span>Loading</span>
                                    </div>
                                )
                            }

                            {
                                data.categoryListings2?.slice(0, 8).map((listing, index) => (
                                    <div key={ index } className="listing">
                                        <Link to={ "/listing/" + listing._id }>
                                            <div className="thumbnail-wrapper">
                                                <img src={ listing.thumbnail } alt={ listing.title }/>
                                            </div>
                                        </Link>

                                        <div className="details-wrapper">
                                            <Link to={ "/listing/" + listing._id }><div className="title ">{ listing.title }</div></Link>
                                            <div className="rating" title={ listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length || 0 }>{ handleRating(listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length) } <span>{ "(" + (listing?.feedback?.length || 0) + " ratings)" }</span></div>
                                            <div className="price">{ parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                /*
                <div className="banner">
                    <div className="container">
                        <div className="content">

                        </div>
                    </div>
                </div>
                */
            }

            <div className="category-preview">
                <div className="container">
                    <div className="section-title">Fashion <Link to="/category/fashion"><span>Browse More <i className="fal fa-long-arrow-right"></i></span></Link></div>

                    <div className="content">
                        <div className="category-feature three">
                            <div className="feature-subtitle">Get up to <span>10% off</span></div>
                            <div className="feature-title">Trainers & Sneakers</div>
                            <Link to="/category/fashion/mens-shoes"><div className="feature-button">Browse Products</div></Link>
                        </div>

                        <div className="category-listings">
                            {
                                (data.categoryListings3 == null) && (
                                    <div className="loading">
                                        <i className="fad fa-spinner-third"></i>

                                        <span>Loading</span>
                                    </div>
                                )
                            }

                            {
                                data.categoryListings3?.slice(0, 8).map((listing, index) => (
                                    <div key={ index } className="listing">
                                        <Link to={ "/listing/" + listing._id }>
                                            <div className="thumbnail-wrapper">
                                                <img src={ listing.thumbnail } alt={ listing.title }/>
                                            </div>
                                        </Link>

                                        <div className="details-wrapper">
                                            <Link to={ "/listing/" + listing._id }><div className="title ">{ listing.title }</div></Link>
                                            <div className="rating" title={ listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length || 0 }>{ handleRating(listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing?.feedback?.length) } <span>{ "(" + (listing?.feedback?.length || 0) + " ratings)" }</span></div>
                                            <div className="price">{ parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog n1280">
                <div className="container">
                    <div className="section-title">Our Blog <a href="https://storex.medium.com" target="_blank"><span>View All Posts <i className="fal fa-long-arrow-right"></i></span></a></div>

                    <div className="blog-wrapper">
                        <div className="blog-post">
                            <a href="https://storex.medium.com/storex-roadmap-2024-the-year-of-release-43f1be6ede87" target="_blank">
                                <div className="thumbnail-wrapper">
                                    <img src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" alt=""/>

                                    <div className="date">
                                        <div className="month">JAN</div>
                                        <div className="day">15</div>
                                    </div>
                                </div>
                            </a>

                            <div className="info-wrapper">
                                <a href="https://storex.medium.com/storex-roadmap-2024-the-year-of-release-43f1be6ede87" target="_blank"><div className="title">Storex Roadmap 2024</div></a>
                                <div className="author">by <Link to="/profile/strxdev"><span>Storex</span></Link></div>
                            </div>
                        </div>

                        <div className="blog-post">
                            <a href="https://storex.medium.com/storex-year-in-review-2023-a-year-of-innovation-and-milestones-200834713125" target="_blank">
                                <div className="thumbnail-wrapper">
                                    <img src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" alt=""/>

                                    <div className="date">
                                        <div className="month">DEC</div>
                                        <div className="day">29</div>
                                    </div>
                                </div>
                            </a>

                            <div className="info-wrapper">
                                <a href="https://storex.medium.com/storex-year-in-review-2023-a-year-of-innovation-and-milestones-200834713125" target="_blank"><div className="title">Storex Year in Review 2023</div></a>
                                <div className="author">by <Link to="/profile/strxdev"><span>Storex</span></Link></div>
                            </div>
                        </div>

                        <div className="blog-post">
                            <a href="https://storex.medium.com/storex-deep-dive-4-integrating-the-proton-ecosystem-b5fd01b804df" target="_blank">
                                <div className="thumbnail-wrapper">
                                    <img src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" alt=""/>

                                    <div className="date">
                                        <div className="month">NOV</div>
                                        <div className="day">23</div>
                                    </div>
                                </div>
                            </a>

                            <div className="info-wrapper">
                                <a href="https://storex.medium.com/storex-deep-dive-4-integrating-the-proton-ecosystem-b5fd01b804df" target="_blank"><div className="title">Integrating the Proton Ecosystem</div></a>
                                <div className="author">by <Link to="/profile/strxdev"><span>Storex</span></Link></div>
                            </div>
                        </div>

                        <div className="blog-post">
                            <a href="https://storex.medium.com/storex-deep-dive-3-arbitration-panel-77d21017cdcd" target="_blank">
                                <div className="thumbnail-wrapper">
                                    <img src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" alt=""/>

                                    <div className="date">
                                        <div className="month">OCT</div>
                                        <div className="day">28</div>
                                    </div>
                                </div>
                            </a>

                            <div className="info-wrapper">
                                <a href="https://storex.medium.com/storex-deep-dive-3-arbitration-panel-77d21017cdcd" target="_blank"><div className="title">Arbitration Panel</div></a>
                                <div className="author">by <Link to="/profile/strxdev"><span>Storex</span></Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                (data.recentlyViewed.length > 0) && (
                    <div className="recently-viewed">
                        <div className="container">
                            <div className="section-title">Recently Viewed</div>

                            <div className="listings">
                                {
                                    data.recentlyViewed.slice(0, 8).map((listing, index) => (
                                        <Link key={ index } to={ "/listing/" + listing.id } className={ index == 7 ? "n1280 b950" : index == 6 ? "n1100 b950" : "" }>
                                            <div className="listing" title={ listing.title }>
                                                <div className="thumbnail-wrapper">
                                                    <img src={ listing.thumbnail } alt=""/>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </section>
    );
};

export default Index;
