import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

import Sidebar from "components/account/sidebar";

function Addresses() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({  });
    const [data, setData] = useState({  });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/addresses"
            });
            
            return navigate("/");
        };
    }, [state.authenticated]);

    useEffect(() => {
        if (state.account.id) {
            setData(values => (
                { ...values, regAddress: state.account.registeredAddress, billAddress: state.account.billingAddress, delAddress: state.account.deliveryAddress }
            ));
    
            setInputs(values => (
                { ...values, regAddress: state.account.registeredAddress, billAddress: [], delAddress: [] }
            ));
        };
    }, [state.account]);

    function handleChange(event) {
        const [addressType, name] = event.target.name.split("-");
        var value = event.target.value;

        if (name == "country") {
            if (["US", "UM"].includes(value)) {
                $("label[for='regAddress-state']").html("State");
                $("label[for='regAddress-zipcode']").html("Zipcode");
            } else {
                $("label[for='regAddress-state']").html("County");
                $("label[for='regAddress-zipcode']").html("Postcode");
            };
        };

        setInputs(values => (
            { ...values, [addressType]: { ...values[addressType], [name]: value } }
        ));
    };

    function handleRegisteredAddress(event) {
        event.preventDefault();

        $("button.reg-address").html("<i class='fad fa-spinner-third'></i> Saving address");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Your registered address has been updated");

            $("button.reg-address").html(textStrings["save_changes"][state.language]);
        };

        var showError = (message) => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error with your request: " + message);
        };

        var showAuthError = () => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

            navigate("/");
        };

        Account.updateRegisteredAddress(state.session.auth.actor, inputs).then(res => {
            showSuccess();
        }).catch(err => {
            var { message } = err.responseJSON || { message: err.responseText };

            if (message?.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.updateRegisteredAddress(state.session.auth.actor, inputs).then(res => {
                        showSuccess();
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err.responseText };

                        showError(message);

                        $("button.reg-address").html(textStrings["save_changes"][state.language]);
                    });
                }).catch(err => {
                    showAuthError();
                });
            } else {
                showError(message);

                $("button.reg-address").html(textStrings["save_changes"][state.language]);
            };
        });
    };

    function handleNewAddress(addressType) {
        $("div.input-modal").addClass("active").find("section.new-address").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "add",
                type: addressType
            }
        });
    };

    function handleEditAddress(target, addressType, address) {
        $("div.input-modal").addClass("active").find("section.edit-address").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "edit",
                type: addressType,
                address: address
            }
        });
    };

    function handleDeleteAddress(target, addressType, addressId) {
        $(target).html("<i class='fad fa-spinner-third'></i> Deleting address");

        var showSuccess = (res) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your address has been deleted");

            dispatch({
                type: "setAccount",
                value: {
                    account: {
                        id: res._id || defaultAccount.id,
                        fullName: res.fullName || defaultAccount.fullName,
                        email: res.email || defaultAccount.email,
                        phone: res.phone || defaultAccount.phone,
                        country: res.country || defaultAccount.country,
                        referral: res.referral || defaultAccount.referral,
                        registeredAddress: res.registeredAddress || defaultAccount.registeredAddress,
                        billingAddress: res.billingAddress || defaultAccount.billingAddress,
                        deliveryAddress: res.deliveryAddress || defaultAccount.deliveryAddress
                    }
                }
            });

            $(target).html(textStrings["delete"][state.language]);
        };

        var showError = (message) => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error with your request: " + message);

            $(target).html(textStrings["delete"][state.language]);
        };

        var showAuthError = () => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

            navigate("/");
        };

        Account.deleteAddress(state.session.auth.actor, inputs, addressType, addressId).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err.responseText };

            if (message?.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.deleteAddress(state.session.auth.actor, inputs, addressType, addressId).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err.responseText };

                        showError(message);
                    });
                }).catch(err => {
                    showAuthError();
                });
            } else {
                showError(message);
            };
        });
    };

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><p>Home</p></Link>
                    <span>></span>
                    <Link to="/account"><p>{ textStrings["my_account"][state.language] }</p></Link>
                    <span>></span>
                    <Link to="/account/addresses"><p>{ textStrings["account_addresses"][state.language] }</p></Link>
                </div>

                <div className="account-wrapper">
                    <Sidebar></Sidebar>

                    <div className="content-wrapper">
                        <section className="reg-address">
                            <div className="content-title"><i className="fal fa-map-marker-alt"></i> { textStrings["account_reg_address"][state.language] }</div>

                            <form onSubmit={ handleRegisteredAddress }>
                                <div className="input-group">
                                    <label htmlFor="regAddress-addressLine1">Address Line 1</label>
                                    <input type="text" name="regAddress-addressLine1" autoComplete="address-line1" value={ inputs?.regAddress?.addressLine1 || "" } onChange={ handleChange } required/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="regAddress-addressLine2">Address Line 2 <span className="optional">Optional</span></label>
                                    <input type="text" name="regAddress-addressLine2" autoComplete="address-line2" value={ inputs?.regAddress?.addressLine2 || "" } onChange={ handleChange }/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="regAddress-cityTown">City/Town</label>
                                    <input type="text" name="regAddress-cityTown" autoComplete="address-level2" value={ inputs?.regAddress?.cityTown || "" } onChange={ handleChange } required/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="regAddress-state">County</label>
                                    <input type="text" name="regAddress-state" autoComplete="address-level1" value={ inputs?.regAddress?.state || "" } onChange={ handleChange } required/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="regAddress-zipcode">Postcode</label>
                                    <input type="text" name="regAddress-zipcode" autoComplete="postal-code" value={ inputs?.regAddress?.zipcode || "" } onChange={ handleChange } required/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="regAddress-country">Country</label>
                                    <i className="fal fa-chevron-down"></i>
                                    <select className="country" name="regAddress-country" autoComplete="country" value={ inputs?.regAddress?.country || "" } onChange={ handleChange } required>
                                        <option value="" style={{ display: "none" }}></option>
                                        {
                                            countryCodes.map((country, index) => (
                                                <option key={ index } value={ country.countryCode }>{ country.name }</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <button type="submit" className="submit reg-address">{ textStrings["save_changes"][state.language] }</button>
                            </form>
                        </section>

                        <section className="bill-address">
                            <div className="content-title divider"><i className="fal fa-map-marker-alt"></i> { textStrings["account_bill_address"][state.language] }</div>

                            <ul>
                                {
                                    data.billAddress?.map((address, index) => (
                                        <li key={ index }>
                                            <div className="bold">{ address.fullName }</div>
                                            <div>
                                                { address.addressLine1 }
                                                {
                                                    (address.addressLine2) && (
                                                        <span>, { address.addressLine2 }</span>
                                                    )
                                                }
                                            </div>
                                            <div>{ address.cityTown }, { address.state }</div>
                                            <div>{ address.zipcode }</div>
                                            <div>{ countryCodesDictionary[address.country] }</div>

                                            <div className="actions">
                                                <button onClick={ (e) => handleEditAddress(e.target, "billing", address) }>Edit</button>
                                                <button onClick={ (e) => handleDeleteAddress(e.target, "billing", address._id) }>Delete</button>
                                            </div>
                                        </li>
                                    ))
                                }

                                <li className="add-new" onClick={ () => handleNewAddress("billing") }>
                                    <i className="fal fa-plus"></i>
                                    <div>Add Address</div>
                                </li>
                            </ul>
                        </section>

                        <section className="del-address">
                            <div className="content-title divider"><i className="fal fa-map-marker-alt"></i> { textStrings["account_del_address"][state.language] }</div>

                            <ul>
                                {
                                    data.delAddress?.map((address, index) => (
                                        <li key={ index }>
                                            <div className="bold">{ address.fullName }</div>
                                            <div>
                                                { address.addressLine1 }
                                                {
                                                    (address.addressLine2) && (
                                                        <span>, { address.addressLine2 }</span>
                                                    )
                                                }
                                            </div>
                                            <div>{ address.cityTown }, { address.state }</div>
                                            <div>{ address.zipcode }</div>
                                            <div>{ countryCodesDictionary[address.country] }</div>

                                            <div className="actions">
                                                <button onClick={ (e) => handleEditAddress(e.target, "delivery", address) }>Edit</button>
                                                <button onClick={ (e) => handleDeleteAddress(e.target, "delivery", address._id) }>Delete</button>
                                            </div>
                                        </li>
                                    ))
                                }

                                <li className="add-new" onClick={ () => handleNewAddress("delivery") }>
                                    <i className="fal fa-plus"></i>
                                    <div>Add Address</div>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Addresses;
