import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import { useQRCode } from "next-qrcode";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, regionCodesDictionary, countryCodesDictionary, supportedCurrencies, networkMemos } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";

import CreateAccount from "components/modal/create-account";
import VerifyWallet from "components/modal/verify-wallet";
import TradeModal from "components/modal/trade";
import PrivateKeyModal from "components/modal/private-key";
import VerifyEmailModal from "components/modal/verify-email";
import KYCModal from "components/modal/kyc";
import BuyVoucherModal from "components/modal/buy-voucher";
import RedeemVoucherModal from "components/modal/redeem-voucher";
import DisputeModal from "components/modal/create-dispute";
import DisputeEvidenceModal from "components/modal/upload-evidence";
import ViewEvidenceModal from "components/modal/view-evidence";
import VoteDisputeModal from "components/modal/vote-dispute";
import ImportListingModal from "components/modal/import-listing";
import LeaveReviewModal from "components/modal/leave-review";

function InputModal() {
    const navigate = useNavigate();
    const [paymentCountdown, setPaymentCountdown] = useState(119);
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ withdrawFee: null, selectedAddress: 0, options: [], variations: [], internationalShipping: [], currency: { chains: [] }, addresses: { "XPR Network": "" }, selectedChain: "XPR Network", requiredPayments: {}, purchasedListings: [], payDisabled: false });
    const { Image } = useQRCode();

    useEffect(() => {
        return () => {
            clearInterval(window.paymentCountdown);
        };
    }, []);

    useEffect(() => {
        if (!state.inputModal) return;

        setInputs(values => (
            { 
                ...values, 
                options: [],
                variation: 0,
                variations: [{}],
                internationalShipping: []
            }
        ));

        if (state.inputModal.action == "make-payment") {
            setInputs(values => (
                { ...values, requiredPayments: state.inputModal.payment, purchasedListings: state.inputModal.listings, deliveryAddress: state.inputModal.delivery, orderNotes: state.inputModal.notes }
            ));

            clearInterval(window.paymentCountdown);

            setPaymentCountdown(119);

            const max = -219.99078369140625;

            $(".progress").each((index, value) => {
                var percent = (value.getAttribute("data-progress") > 100) ? 100 : value.getAttribute("data-progress");

                value.querySelector(".fill").setAttribute("style", "stroke-dashoffset: " + ((100 - percent) / 100) * max);
            });

            window.paymentCountdown = setInterval(() => {
                setPaymentCountdown((count) => {
                    const max = -219.99078369140625;

                    $(".progress").each((index, value) => {
                        var percent = (value.getAttribute("data-progress") > 100) ? 100 : value.getAttribute("data-progress");

                        value.querySelector(".fill").setAttribute("style", "stroke-dashoffset: " + ((100 - percent) / 100) * max);
                    });

                    if (count <= 1 && !inputs.payDisabled) {
                        setInputs(values => (
                            { ...values, payDisabled: true }
                        ));
                    };

                    return (count < 1 ? 0 : count - 1);
                });
            }, 1000);
        };

        if (state.inputModal.action == "refund-order") {
            setInputs(values => (
                { ...values, listing: state.inputModal.listing }
            ));
        };

        if (state.inputModal.action == "order-details") {
            setInputs(values => (
                { ...values, listing: state.inputModal.listing }
            ));
        };

        if (state.inputModal.action == "deposit") {
            setInputs(values => (
                { ...values, currency: state.inputModal.data.currency, addresses: { "XPR Network": state.session.auth.actor, ...state.inputModal.data.addresses }, fireblocks: { "XPR Network": state.session.auth.actor, "Ethereum": "0xEC26bd5671a7438A13aaba0590AEFBcC8B215546", "Binance (BEP20)": "0xEC26bd5671a7438A13aaba0590AEFBcC8B215546" }, selectedChain: Object.keys(state.inputModal.data.currency.chains)[0] }
            ));
        };

        if (state.inputModal.action == "withdraw") {
            setInputs(values => (
                { ...values, currency: state.inputModal.data.currency, currencyBalance: state.inputModal.data.balance, selectedChain: Object.keys(state.inputModal.data.currency.chains)[0] }
            ));
        };

        if (state.inputModal.action == "change") {
            setInputs(values => (
                { ...values, addressType: state.inputModal.type, selectedAddress: state.inputModal.selected }
            ));
        };

        if (state.inputModal.action == "edit") {
            setInputs(values => (
                { ...values, editAddress: state.inputModal.address }
            ));
        };

        if (state.inputModal.action == "add-variation") {
            setInputs(values => (
                { ...values, options: state.inputModal.options, variations: state.inputModal.variations }
            ));
        };

        if (state.inputModal.action == "add-international") {
            setInputs(values => (
                { ...values, internationalShipping: state.inputModal.internationalShipping }
            ));
        };

        return () => {
            clearInterval(window.paymentCountdown);
        };
    }, [state.inputModal]);

    useEffect(() => {
        if (state.inputModal?.action != "withdraw") return;

        setInputs(values => (
            { ...values, memo: null, withdrawFee: null }
        ));

        Proton.calculateFee(inputs.currency?.name, inputs.currency?.networks?.[inputs.selectedChain]).then(res => {
            setInputs(values => (
                { ...values, withdrawQuote: res.quoteId, withdrawFee: res.estimatedFee }
            ));
        }).catch(err => {
            console.log(err);
        });
    }, [inputs.selectedChain]);

    function handleClick(event) {
        const target = $(event.target);

        if (target.hasAnyClass("close input-modal")) {
            if ($("section.create-account").hasClass("active")) return;
            if ($("section.verify-wallet").hasClass("active")) return;

            clearInterval(window.paymentCountdown);

            setPaymentCountdown(119);

            const max = -219.99078369140625;

            $(".progress").each((index, value) => {
                value.querySelector(".fill").setAttribute("style", "stroke-dashoffset: " + ((100 - 100) / 100) * max);
            });

            dispatch({
                type: "closeInputModal",
                value: Date.now()
            });

            $("div.input-modal, section").removeClass("active");
        };
    };

    function handleChangeBase(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (name == "amount") value = value.replace(/\D\./g, "");

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };
    
    function handleChange(event) {
        const [addressType, name] = event.target.name.split("-");
        var value = event.target.value;
    
        if (name == "country") {
            if (["US", "UM"].includes(value)) {
                $("label[for='newAddress-state']").html("State");
                $("label[for='newAddress-zipcode']").html("Zipcode");

                $("label[for='editAddress-state']").html("State");
                $("label[for='editAddress-zipcode']").html("Zipcode");
            } else {
                $("label[for='newAddress-state']").html("County");
                $("label[for='newAddress-zipcode']").html("Postcode");

                $("label[for='editAddress-state']").html("County");
                $("label[for='editAddress-zipcode']").html("Postcode");
            };
        };

        if (name == "amount") {
            value = value.replace(/[^0-9.]/g, "");
        };
    
        setInputs(values => (
            { ...values, [addressType]: { ...values[addressType], [name]: value } }
        ));
    };

    function handleChangeVariation(event) {
        const name = event.target.name;
        const value = event.target.value;
        const [varStr, optStr] = name.split("/");
        const [, varId] = varStr.split("-");
        const [optName, optId] = optStr.split("-");

        var varCopy = inputs.variations;

        if (optId) {
            varCopy[varId]["option" + optId] = value;
        } else {
            if (optName == "price") {
                //varCopy[varId][optName] = parseFloat(value);
                varCopy[varId][optName] = value.replace(/[^0-9.]/g, "");
            } else if (optName == "stock") {
                varCopy[varId][optName] = value.replace(/[^0-9.]/g, "");
            };
        };

        setInputs(values => (
            { ...values, variations: varCopy }
        ));
    };

    function handleChangeInternational(event) {
        const name = event.target.name;
        var value = event.target.value;
        const [intStr, optStr] = name.split("/");
        const [, intId] = intStr.split("-");
        const [optName] = optStr.split("-");

        var interCopy = inputs.internationalShipping;

        console.log(optName);

        if (optName == "price") {
            var rawValue = value.replace(/[^0-9.]/g, "");

            console.log(rawValue);
        };

        interCopy[intId][optName] = rawValue || value;

        setInputs(values => (
            { ...values, internationalShipping: interCopy }
        ));
    };

    function handleNewAddress(event) {
        event.preventDefault();

        const addressType = state.inputModal.type;

        $("button.new-address").html("<i class='fad fa-spinner-third'></i> Saving address");

        var showSuccess = () => {
            $("div.input-modal, section").removeClass("active");

            $("div.error-modal").addClass("success").find("div.text").html("Your new address has been added");

            $("button.new-address").html(textStrings["save_changes"][state.language]);

            setInputs(values => (
                { ...values, "newAddress": {} }
            ));
        };

        var showError = (message) => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error with your request: " + message);

            $("button.new-address").html(textStrings["save_changes"][state.language]);
        };

        var showAuthError = () => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

            navigate("/");
        };

        Account.addAddress(state.session.auth.actor, inputs, addressType).then(res => {
            showSuccess();

            dispatch({
                type: "setAccount",
                value: {
                    account: {
                        id: res._id || defaultAccount.id,
                        fullName: res.fullName || defaultAccount.fullName,
                        email: res.email || defaultAccount.email,
                        phone: res.phone || defaultAccount.phone,
                        country: res.country || defaultAccount.country,
                        referral: res.referral || defaultAccount.referral,
                        registeredAddress: res.registeredAddress || defaultAccount.registeredAddress,
                        billingAddress: res.billingAddress || defaultAccount.billingAddress,
                        deliveryAddress: res.deliveryAddress || defaultAccount.deliveryAddress
                    }
                }
            });
        }).catch(err => {
            var { message } = err.responseJSON || { message: err.responseText };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.addAddress(state.session.auth.actor, inputs, addressType).then(res => {
                        showSuccess();

                        dispatch({
                            type: "setAccount",
                            value: {
                                account: {
                                    id: res._id || defaultAccount.id,
                                    fullName: res.fullName || defaultAccount.fullName,
                                    email: res.email || defaultAccount.email,
                                    phone: res.phone || defaultAccount.phone,
                                    country: res.country || defaultAccount.country,
                                    referral: res.referral || defaultAccount.referral,
                                    registeredAddress: res.registeredAddress || defaultAccount.registeredAddress,
                                    billingAddress: res.billingAddress || defaultAccount.billingAddress,
                                    deliveryAddress: res.deliveryAddress || defaultAccount.deliveryAddress
                                }
                            }
                        });
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err.responseText };

                        showError(message);
                    });
                }).catch(err => {
                    showAuthError();
                });
            } else {
                showError(message);
            };
        });
    };

    function handleEditAddress(event) {
        event.preventDefault();

        $("button.edit-address").html("<i class='fad fa-spinner-third'></i> Saving address");

        var showSuccess = () => {
            $("div.input-modal, section").removeClass("active");

            $("div.error-modal").addClass("success").find("div.text").html("Your address has been updated");

            $("button.edit-address").html(textStrings["save_changes"][state.language]);

            setInputs(values => (
                { ...values, "editAddress": {} }
            ));
        };

        var showError = (message) => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error with your request: " + message);

            $("button.edit-address").html(textStrings["save_changes"][state.language]);
        };

        var showAuthError = () => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

            navigate("/");
        };

        Account.editAddress(state.session.auth.actor, inputs, state?.inputModal?.type, state?.inputModal?.address?._id).then(res => {
            showSuccess();

            dispatch({
                type: "setAccount",
                value: {
                    account: {
                        id: res._id || defaultAccount.id,
                        fullName: res.fullName || defaultAccount.fullName,
                        email: res.email || defaultAccount.email,
                        phone: res.phone || defaultAccount.phone,
                        country: res.country || defaultAccount.country,
                        referral: res.referral || defaultAccount.referral,
                        registeredAddress: res.registeredAddress || defaultAccount.registeredAddress,
                        billingAddress: res.billingAddress || defaultAccount.billingAddress,
                        deliveryAddress: res.deliveryAddress || defaultAccount.deliveryAddress
                    }
                }
            });
        }).catch(err => {
            var { message } = err.responseJSON || { message: err.responseText };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.editAddress(state.session.auth.actor, inputs, state?.inputModal?.type, state?.inputModal?.address?._id).then(res => {
                        showSuccess();

                        dispatch({
                            type: "setAccount",
                            value: {
                                account: {
                                    id: res._id || defaultAccount.id,
                                    fullName: res.fullName || defaultAccount.fullName,
                                    email: res.email || defaultAccount.email,
                                    phone: res.phone || defaultAccount.phone,
                                    country: res.country || defaultAccount.country,
                                    referral: res.referral || defaultAccount.referral,
                                    registeredAddress: res.registeredAddress || defaultAccount.registeredAddress,
                                    billingAddress: res.billingAddress || defaultAccount.billingAddress,
                                    deliveryAddress: res.deliveryAddress || defaultAccount.deliveryAddress
                                }
                            }
                        });
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err.responseText };

                        showError(message);
                    });
                }).catch(err => {
                    showAuthError();
                });
            } else {
                showError(message);
            };
        });
    };

    function handleAddVideo(event) {
        event.preventDefault();

        $("button.add-video").html("<i class='fad fa-spinner-third'></i> Importing video");
    };

    function handleVariationRow(event) {
        setInputs(values => (
            { ...values, variations: [...inputs.variations, { id: inputs.variations.length }] }
        ));
    };

    function handleRemoveVariationRow(index) {
        var variationsCopy = inputs.variations;

        variationsCopy.splice(index, 1);
        
        setInputs(values => (
            { ...values, variations: variationsCopy }
        ));
    };

    function handleAddVariation(event) {
        event.preventDefault();

        dispatch({
            type: "setVariations",
            value: inputs.variations
        });

        $("div.input-modal, section").removeClass("active");
    };

    function handleInternationalRow(event) {
        setInputs(values => (
            { ...values, internationalShipping: [...inputs.internationalShipping, {}] }
        ));
    };

    function handleRemoveInternationalRow(index) {
        var internationalCopy = inputs.internationalShipping;

        internationalCopy.splice(index, 1);
        
        setInputs(values => (
            { ...values, internationalShipping: internationalCopy }
        ));
    };

    function handleAddInternational(event) {
        event.preventDefault();

        dispatch({
            type: "setInternational",
            value: inputs.internationalShipping
        });

        $("div.input-modal, section").removeClass("active");
    };

    function toggleTab(event, chain) {
        setInputs(values => (
            { ...values, selectedChain: chain }
        ));
    };

    function generateAuth(coin, wallet) {
        if (!coin) return console.log("missing_params");

        if ($(".qr-wrapper div.empty").hasClass("disabled")) return;

        $(".qr-wrapper div.empty").addClass("disabled").html("<i class='fad fa-spinner-third'></i>");

        var showSuccess = (res) => {
            dispatch({
                type: "fetchWalletBalances",
                value: true
            });
            
            setInputs(values => (
                { ...values, addresses: { ...inputs.addresses, [res.chain]: res.address } }
            ));
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error authenticating your request, please try again");

            $(".qr-wrapper div.empty").removeClass("disabled").html("<i class='fal fa-wallet no-spin'></i><span>Create Address</span>");
        };

        Proton.generateAuth(state).then(res => {
            if (!res.transaction || !res.signatures || !res.signer) return;

            Account.generateDepositAddress(coin, wallet, res.signer, res.signatures, res.transaction).then(res2 => {
                showSuccess(res2);
            }).catch(err2 => {
                console.log(err2);

                var { error } = err2.responseJSON;

                showError(error);
            });
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            showError(message);
        });
    };

    function handlePrint(event) {
        event.preventDefault();

        window.print();
    };

    function handleRefund(event) {
        event.preventDefault();

        $("section.refund-order button.submit").html("<i class='fad fa-spinner-third'></i> Refunding");

        var showSuccess = (res) => {
            $("div.input-modal, section").removeClass("active");

            $("div.error-modal").addClass("success").find("div.text").html("The refund has been sent");

            $("section.refund-order button.submit").html(textStrings["send_refund"][state.language]);

            dispatch({
                type: "fetchSellingHistory",
                value: true
            });
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error authenticating your request, please try again");

            $("section.refund-order button.submit").html(textStrings["send_refund"][state.language]);
        };

        var refundString = ((inputs.refundOrder?.amount || 0) / ((parseFloat(inputs.listing?.purchasedVariation.price) * inputs.listing?.purchasedQuantity) + parseFloat(inputs.listing?.purchasedShipping || 0)) * inputs.listing?.purchasedCurrency?.split(" ")[0]).toFixed(inputs.listing?.purchasedCurrency?.split(" ")[0]?.split(".")[1].length) + " " + inputs.listing?.purchasedCurrency?.split(" ")[1];

        if (inputs.listing?.escrow) {
            Proton.refundOrder(state, inputs.listing?.orderNumber, refundString).then(tx => {
                if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

                var txId = tx.processed?.id || "";

                Listing.refundOrder(state.session.auth.actor, inputs.listing?.orderNumber, inputs.refundOrder?.amount, refundString, txId).then(res => {
                    showSuccess(res);
                }).catch(err => {
                    var { message } = err.responseJSON;
        
                    if (message.indexOf("Missing Cookie") != -1) {
                        Account.requestToken(state.session.auth.actor).then(res => {
                            Listing.refundOrder(state.session.auth.actor, inputs.listing?.orderNumber, inputs.refundOrder?.amount, refundString, txId).then(res => {
                                showSuccess(res);
                            }).catch(err => {
                                var { message } = err.responseJSON;
        
                                showError(message);
                            });
                        }).catch(err => {
                            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
        
                            navigate("/");
                        });
                    } else {
                        showError(message);
                    };
                });
            }).catch(err => {
                showError(err.error?.details?.[0]?.message || err);
            });
        } else {
            Proton.refundOrderManual(state, inputs.listing?.orderNumber, supportedCurrencies.filter(c => c.ticker == refundString.split(" ")[1])[0].contract, inputs.listing?.sellerId, inputs.listing?.buyerId, refundString).then(tx => {
                if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

                var txId = tx.processed?.id || "";

                Listing.refundOrderManual(state.session.auth.actor, inputs.listing?.orderNumber, inputs.refundOrder?.amount, refundString, txId).then(res => {
                    showSuccess(res);
                }).catch(err => {
                    var { message } = err.responseJSON;
        
                    if (message.indexOf("Missing Cookie") != -1) {
                        Account.requestToken(state.session.auth.actor).then(res => {
                            Listing.refundOrderManual(state.session.auth.actor, inputs.listing?.orderNumber, inputs.refundOrder?.amount, refundString, txId).then(res => {
                                showSuccess(res);
                            }).catch(err => {
                                var { message } = err.responseJSON;
        
                                showError(message);
                            });
                        }).catch(err => {
                            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
        
                            navigate("/");
                        });
                    } else {
                        showError(message);
                    };
                });
            }).catch(err => {
                showError(err.error?.details?.[0]?.message || err);
            });
        };
    };

    function handleMakePayment(event) {
        if (inputs.payDisabled) return;

        $("button.pay").html("<i class='fad fa-spinner-third'></i> Sending payment");

        var formedActions = Object.keys(inputs.requiredPayments).map(id => {
            var token = inputs.requiredPayments[id];

            return {
                account: token.contract,
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "storexcore",
                    quantity: token.quantity,
                    memo: "purchase #" + id
                },
                authorization: [state.session.auth]
            }
        });

        var successPayment = (tx) => {
            if (!tx.processed) return errorPurchase("The transaction has not been broadcast to the blockchain, please try again");

            var txId = tx.processed?.id || "";

            var successPurchase = () => {
                $("button.pay").html(textStrings["make_payment"][state.language]);

                $("button.checkout").html(textStrings["place_order"][state.language] + "<i class='fal fa-long-arrow-right no-spin'></i>");

                dispatch({
                    type: "setCart",
                    value: []
                });

                dispatch({
                    type: "setOrder",
                    value: {
                        txId: txId,
                        orderId: Object.keys(inputs.requiredPayments)[0]
                    }
                });

                navigate("/order-confirmation");

                $("div.input-modal, section").removeClass("active");
            };

            var errorPurchase = (error) => {
                $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

                $("button.pay").html(textStrings["make_payment"][state.language]);

                $("button.checkout").html(textStrings["place_order"][state.language] + "<i class='fal fa-long-arrow-right no-spin'></i>");
            };

            Listing.purchaseListing(state.session.auth.actor, txId, inputs.purchasedListings, inputs.deliveryAddress, inputs.orderNotes).then(res => {
                successPurchase();
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };

                if (message?.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.purchaseListing(state.session.auth.actor, txId, inputs.purchasedListings, inputs.deliveryAddress).then(res => {
                            successPurchase();
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };

                            errorPurchase(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                        navigate("/");
                    });
                } else {
                    errorPurchase(message);
                };
            });
        };

        var errorPayment = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $("button.pay").html(textStrings["make_payment"][state.language]);

            $("button.checkout").html(textStrings["place_order"][state.language] + "<i class='fal fa-long-arrow-right no-spin'></i>");
        };

        Proton.purchaseListings(state, formedActions).then(tx => {
            successPayment(tx);
        }).catch(err => {
            errorPayment(err.error?.details?.[0]?.message || err);
        });
    };

    function handleAddressChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const id = event.target.id;

        setInputs(values => (
            {
                ...values,
                selectedAddress: id
            }
        ));
    };

    function handleChangeAddress(event, addressType) {
        event.preventDefault();

        dispatch({
            type: (addressType == "billing") ? "setSelectedBillingAddress" : "setSelectedShippingAddress",
            value: inputs.selectedAddress
        });
    };

    function addNewAddress(event) {
        event.preventDefault();

        $("div.input-modal, section").removeClass("active");

        navigate("/account/addresses");
    };

    function handleWithdraw(event) {
        event.preventDefault();

        $("button.sendTx").html("<i class='fad fa-spinner-third'></i> Sending transaction").attr("disabled", true);

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            dispatch({
                type: "fetchWalletBalances",
                value: true
            });

            Proton.fetchBalance(state.session.auth.actor, inputs.currency).then(res => {
                const [balanceStr] = res;
                const [balance, ticker] = (balanceStr || "").split(" ");

                setInputs(values => (
                    {
                        ...values,
                        address: null,
                        memo: null,
                        amount: null,
                        currencyBalance: balance
                    }
                ));
            }).catch(err => {
                setInputs(values => (
                    {
                        ...values,
                        address: null,
                        memo: null,
                        amount: null,
                        currencyBalance: inputs.currencyBalance - inputs.amount - inputs.withdrawFee
                    }
                ));
            });

            $("button.sendTx").html(textStrings["send_tx"][state.language]).attr("disabled", false);
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(JSON.stringify(err) || "There was an error sending your request, please contact support");

            $("button.sendTx").html(textStrings["send_tx"][state.language]).attr("disabled", false);
        };

        if (inputs.selectedChain == "XPR Network") {
            Proton.sendTokens(state, inputs.currency, inputs.selectedChain, inputs.address, inputs.amount, inputs.memo).then(tx => {
                showSuccess(tx.processed.id);
            }).catch(err => {
                showError(err.error?.details?.[0]?.message || err);
            });
        } else {
            Proton.signWithdraw(state, inputs.currency, inputs.selectedChain, inputs.address, inputs.memo, inputs.amount, inputs.withdrawFee).then(tx => {
                console.log(tx);
                Proton.sendWithdraw(tx, inputs.withdrawQuote).then(res => {
                    showSuccess(res.id);
                }).catch(err => {
                    showError(err.error?.details?.[0]?.message || err);
                });
            }).catch(err => {
                console.log("deb", err);
                showError(err.error?.details?.[0]?.message || err);
            });
        };
    };

    function handleMaxWithdraw(event) {
        event.preventDefault();

        if (inputs.currencyBalance - inputs.withdrawFee < 0) return;

        setInputs(values => (
            {
                ...values,
                amount: inputs.currencyBalance - inputs.withdrawFee
            }
        ));
    };

    function handlePaste(event) {
        event.preventDefault();

        const target = event.target;
        const name = $(target).prev().attr("name");

        navigator.clipboard.readText().then(paste => {
            setInputs(values => (
                {
                    ...values,
                    [name]: paste
                }
            ));
        });
    };

    return (
        <div>
            <div className="input-modal" onMouseDown={ handleClick }>
                <CreateAccount/>
                <VerifyWallet/>
                <TradeModal/>
                <PrivateKeyModal/>
                <VerifyEmailModal/>
                <KYCModal/>
                <BuyVoucherModal/>
                <RedeemVoucherModal/>
                <DisputeModal/>
                <DisputeEvidenceModal/>
                <ViewEvidenceModal/>
                <VoteDisputeModal/>
                <ImportListingModal/>
                <LeaveReviewModal/>

                <section className="change-address">
                    <div className="content">
                        <div className="form-title">Change { state?.inputModal?.type?.[0].toUpperCase() + state?.inputModal?.type?.substring(1) } Address</div>

                        <form className="change-address" action="">
                            {
                                state.account?.[state?.inputModal?.type + "Address"]?.map((address, index) => (
                                    <div className="address">
                                        <input type="radio" id={ index } name="change-address" onChange={ handleAddressChange } checked={ index == inputs.selectedAddress ? true : false }required/>
                                        <label htmlFor={ index }>
                                            <div className="name">{ address.fullName }</div>
                                            <div className="address">
                                                { address.addressLine1 }
                                                {
                                                    (address.addressLine2) && (
                                                        <span>, { address.addressLine2 }</span>
                                                    )
                                                }
                                            </div>
                                            <div className="city">{ address.cityTown }</div>
                                            <div className="zipcode">{ address.zipcode }</div>
                                            <div className="country">{ countryCodesDictionary[address.country] }</div>
                                        </label>
                                    </div>
                                ))
                            }
                            
                            <button onClick={ addNewAddress }><i className="fal fa-plus"></i> Add Address</button>

                            <button type="submit" onClick={ (e) => handleChangeAddress(e, state?.inputModal?.type) }>{ textStrings["select_address"][state.language] }</button>
                        </form>
                    </div>
                </section>

                <section className="make-payment">
                    <div className="content">
                        <div className="form-title"><i className="fal fa-file-invoice-dollar"></i> Order Payment</div>

                        <div className="countdown">
                            <div className={ paymentCountdown < 30 ? paymentCountdown < 10 ? "radial-progress error" : "radial-progress warning" : "radial-progress" }>
                                <svg className="progress" data-progress={ paymentCountdown / 120 * 100 } x="0px" y="0px" viewBox="0 0 80 80">
                                    <path className="track" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
                                    <path className="bg" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
                                    <path className="fill one" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
                                </svg>

                                <div className="text">
                                    <div className="value">{ Math.floor(paymentCountdown / 60) + ":" + (paymentCountdown - Math.floor(paymentCountdown / 60) * 60).toString().padStart(2, "0") }</div>
                                    <div className="title">{ paymentCountdown > 0 ? "Until Order Expires" : "Order Expired" }</div>
                                </div>
                            </div>
                        </div>

                        <div className="payments">
                            {
                                Object.keys(inputs.requiredPayments).map((orderId, index) => (
                                    <div>
                                        <span>#{ orderId }</span>
                                        <div className="payment">
                                            <img src={ supportedCurrencies.filter(c => c.ticker == inputs.requiredPayments?.[orderId]?.quantity?.split(" ")?.[1])?.[0]?.logo } alt="" className="icon" />
                                            <div>{ parseFloat(inputs.requiredPayments?.[orderId]?.quantity?.split(" ")?.[0]).toLocaleString("en-US", { minimumFractionDigits: (inputs.requiredPayments?.[orderId]?.quantity?.split(" ")?.[0]?.split(".")?.[1]?.length) }) } <span>{ inputs.requiredPayments?.[orderId]?.quantity?.split?.(" ")?.[1] }</span></div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <button type="submit" className={ inputs.payDisabled ? "pay disabled" : "pay" } onClick={ handleMakePayment }>{ textStrings["make_payment"][state.language] }</button>
                    </div>
                </section>

                <section className="refund-order">
                    <div className="content">
                        <div className="form-title"><i className="fal fa-envelope-open-dollar"></i> Refund Order</div>

                        <div className="detail">
                            <div className="subtitle">Order Number</div>
                            <div className="value">{ inputs.listing?.orderNumber }</div>
                        </div>

                        <div className="detail">
                            <div className="subtitle">Order Total</div>
                            <div className="value">{ ((parseFloat(inputs.listing?.purchasedVariation.price) * inputs.listing?.purchasedQuantity) + parseFloat(inputs.listing?.purchasedShipping)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                        </div>

                        <form className="refund-order" onSubmit={ handleRefund }>
                            <div className="input-group full">
                                <i className="fal fa-dollar-sign"></i>
                                <label htmlFor="refundOrder-amount">Amount</label>
                                <input type="number" name="refundOrder-amount" value={ inputs?.refundOrder?.amount || "" } max={ (parseFloat(inputs.listing?.purchasedVariation.price) * inputs.listing?.purchasedQuantity) + parseFloat(inputs.listing?.purchasedShipping || 0) || 0 } onChange={ handleChange } required/>
                                <div className="crypto-amount">{ (((inputs.refundOrder?.amount || 0) / ((parseFloat(inputs.listing?.purchasedVariation.price) * inputs.listing?.purchasedQuantity) + parseFloat(inputs.listing?.purchasedShipping)) * inputs.listing?.purchasedCurrency?.split(" ")[0]) || 0).toFixed(inputs.listing?.purchasedCurrency?.split(" ")[0]?.split(".")[1].length) + " " + inputs.listing?.purchasedCurrency?.split(" ")[1] }</div>
                            </div>

                            <button type="submit" className="submit refund-order">{ textStrings["send_refund"][state.language] }</button>
                        </form>
                    </div>
                </section>

                <section className="order-details">
                    <div className="content">
                        <div className="form-title"><i className="fal fa-receipt"></i> Order Details</div>

                        <div className="order-details">
                            <div className="detail">
                                <div className="subtitle">Listing Id</div>
                                <div className="value">{ inputs.listing?.listingId }</div>
                            </div>

                            <div className="detail">
                                <div className="subtitle">Order Date</div>
                                <div className="value">{ new Date(inputs.listing?.createdAt).toLocaleDateString() }</div>
                            </div>

                            <div className="detail">
                                <div className="subtitle">Order Number</div>
                                <div className="value">{ inputs.listing?.orderNumber }</div>
                            </div>

                            <div className="detail">
                                <div className="subtitle">Buyer</div>
                                <div className="value">{ inputs.listing?.buyerId }</div>
                            </div>

                            <div className="detail">
                                <div className="subtitle">Subtotal</div>
                                <div className="value">{ (parseFloat(inputs.listing?.purchasedVariation.price) * inputs.listing?.purchasedQuantity).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                            </div>

                            <div className="detail">
                                <div className="subtitle">Shipping</div>
                                <div className="value">{ (parseFloat(inputs.listing?.purchasedShipping || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                            </div>

                            <div className="detail">
                                <div className="subtitle">Order Total</div>
                                <div className="value">{ ((parseFloat(inputs.listing?.purchasedVariation.price) * inputs.listing?.purchasedQuantity) + parseFloat(inputs.listing?.purchasedShipping || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                            </div>

                            <div className="detail">
                                <div className="subtitle">Variation</div>
                                <div className="value">
                                    {
                                        (!inputs.listing?.purchasedVariation.option0) && (
                                            <span>N/A</span>
                                        )
                                    }
                                    {
                                        (inputs.listing?.purchasedVariation.option0) && (
                                            <span>{ inputs.listing?.purchasedVariation.option0 }</span>
                                        )
                                    }
                                    {
                                        (inputs.listing?.purchasedVariation.option1) && (
                                            <span> / { inputs.listing?.purchasedVariation.option1 }</span>
                                        )
                                    }
                                    {
                                        (inputs.listing?.purchasedVariation.option2) && (
                                            <span> / { inputs.listing?.purchasedVariation.option2 }</span>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="detail address">
                                <div className="subtitle">Delivery Address</div>
                                <p>{ inputs.listing?.deliveryAddress.fullName }</p>
                                <p>{ inputs.listing?.deliveryAddress.addressLine1 }</p>
                                <p>{ inputs.listing?.deliveryAddress.addressLine2 }</p>
                                <p>{ inputs.listing?.deliveryAddress.cityTown }</p>
                                <p>{ inputs.listing?.deliveryAddress.state }</p>
                                <p>{ inputs.listing?.deliveryAddress.zipcode }</p>
                                <p>{ countryCodesDictionary[inputs.listing?.deliveryAddress.country] }</p>
                            </div>

                            {
                                (inputs.listing?.orderNotes) && (
                                    <div className="detail no-hide">
                                        <div className="subtitle">Order Notes</div>
                                        <div className="value">{ inputs.listing?.orderNotes }</div>
                                    </div>
                                )
                            }

                            <button className="print" onClick={ handlePrint }><i className="fal fa-print"></i> Print</button>
                        </div>
                    </div>
                </section>

                <section className="deposit-address">
                    <div className="content">
                        <div className="form-title"><img src={ inputs.currency?.logo } alt={ inputs.currency?.name }></img> Receive { inputs.currency?.name }</div>

                        <div className="deposit-wrapper">
                            <div className="tab-wrapper">
                                {
                                    Object.keys(inputs.currency?.chains).map((chain, index) => (
                                        <button key={ index } className={ "tab " + (inputs.selectedChain == chain ? "active" : "") } onClick={ (e) => toggleTab(e, chain) }>{ chain }</button>
                                    ))
                                }
                            </div>

                            <div className="qr-wrapper">
                                {
                                    (inputs.addresses?.[inputs.selectedChain]) && (
                                        <Image text={ (inputs.currency?.fireblocks) ? inputs.fireblocks?.[inputs.selectedChain]?.split("?")[0] || "0x" : inputs.addresses?.[inputs.selectedChain]?.split("?")[0] || "0x" }></Image>
                                    )
                                }

                                {
                                    (!inputs.addresses?.[inputs.selectedChain]) && (
                                        <div className="empty click" onClick={ () => generateAuth(inputs.currency?.name, inputs.currency?.chains?.[inputs.selectedChain]) }>
                                            <i className="fal fa-wallet no-spin"></i>
                                            <span>Create Address</span>
                                        </div>
                                    )
                                }

                                <div className="tag">
                                    <span>Address</span>
                                    <div>
                                        {
                                            (inputs.addresses?.[inputs.selectedChain]) && (
                                                (inputs.currency?.fireblocks) ? inputs.fireblocks?.[inputs.selectedChain]?.split("?")[0] || "0x" : inputs.addresses?.[inputs.selectedChain]?.split("?")[0] || "0x"
                                            )
                                        }

                                        {
                                            (!inputs.addresses?.[inputs.selectedChain]) && (
                                                ". . ."
                                            )
                                        }
                                    </div>
                                </div>

                                {
                                    (inputs.addresses?.[inputs.selectedChain]?.includes("memo")) && (
                                        <div className="tag">
                                            <span>Memo</span>
                                            <div>{ inputs.addresses?.[inputs.selectedChain]?.split("=")[1] || "" }</div>
                                        </div>
                                    )
                                }

                                {
                                    (inputs.selectedChain == "XPR Network") && (
                                        <p className="notice">Send { inputs.currency?.ticker } to the above address on the { inputs.selectedChain } network to receive { inputs.currency?.ticker } in your XPR Network account</p>
                                    )
                                }

                                {
                                    (inputs.selectedChain != "XPR Network") && (
                                        <p className="notice">Send { inputs.currency?.name } to the above address on the { inputs.selectedChain } network to receive wrapped { inputs.currency?.ticker } in your XPR Network account</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <section className="withdraw-address">
                    <div className="content">
                        <div className="form-title"><img src={ inputs.currency?.logo } alt={ inputs.currency?.name }></img> Send { inputs.currency?.name }</div>

                        <div className="withdraw-wrapper">
                            <div className="tab-wrapper">
                                {
                                    Object.keys(inputs.currency?.chains).map((chain, index) => (
                                        <button key={ index } className={ "tab " + (inputs.selectedChain == chain ? "active" : "") } onClick={ (e) => toggleTab(e, chain) }>{ chain }</button>
                                    ))
                                }
                            </div>

                            <div className="input-wrapper">
                                <form onSubmit={ handleWithdraw }>
                                    <div className="input-group">
                                        <label htmlFor="balance">Balance</label>
                                        <div className="balance">{ parseFloat(inputs.currencyBalance)?.toLocaleString("en-US", { minimumFractionDigits: inputs.currency?.precision }) } { inputs.currency.name } <button onClick={ handleMaxWithdraw }>Max</button></div>
                                    </div>

                                    <div className="input-group">
                                        <label htmlFor="address">{ inputs.selectedChain } Address</label>
                                        <input type="text" name="address" autoComplete="off" value={ inputs.address || "" } onChange={ handleChangeBase } required/>
                                        <span className="click" onClick={ handlePaste }>Paste</span>
                                    </div>

                                    {
                                        (networkMemos[inputs.selectedChain]) && (
                                            <div className="input-group">
                                                <label htmlFor="memo">Memo</label>
                                                <input type="text" name="memo" autoComplete="off" value={ inputs.memo || "" } onChange={ handleChangeBase } required/>
                                                <span className="click" onClick={ handlePaste }>Paste</span>
                                            </div>
                                        )
                                    }

                                    <div className="input-group">
                                        <label htmlFor="amount">Amount</label>
                                        <input type="text" name="amount" autoComplete="off" value={ inputs.amount || "" } onChange={ handleChangeBase } required/>
                                    </div>

                                    {
                                        (inputs.selectedChain == "XPR Network") && (
                                            <div className="input-group">
                                                <label htmlFor="memo">Memo</label>
                                                <input type="text" name="memo" autoComplete="off" value={ inputs.memo || "" } onChange={ handleChangeBase }/>
                                            </div>
                                        )
                                    }

                                    {
                                        (inputs.selectedChain != "XPR Network") && (
                                            <>
                                                <div className="fee">Network Fee: <span>{ inputs.withdrawFee != null ? parseFloat((parseFloat(inputs.withdrawFee)).toFixed(6)) : <i className="fad fa-spinner-third"></i> }</span> { inputs.currency?.name }</div>
                                                <div className={ (inputs.amount + inputs.withdrawFee > inputs.currencyBalance) ? "fee overdrawn" : "fee" }>Total: <span>{ parseFloat((parseFloat(inputs.withdrawFee || 0) + parseFloat(inputs.amount || 0)).toFixed(6)) }</span> { inputs.currency?.name }</div>
                                            </>
                                        )
                                    }

                                    <button type="submit" className="submit sendTx">{ textStrings["send_tx"][state.language] }</button>
                                </form>

                                {
                                    (inputs.selectedChain == "XPR Network") && (
                                        <p className="notice">You will send { inputs.currency?.ticker } to the above address on the { inputs.selectedChain } network</p>
                                    )
                                }

                                {
                                    (inputs.selectedChain != "XPR Network") && (
                                        <p className="notice">You will send { inputs.currency?.name } to the above address on the { inputs.selectedChain } network</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <section className="new-address">
                    <div className="content">
                        <div className="form-title">Add { state?.inputModal?.type?.[0].toUpperCase() + state?.inputModal?.type?.substring(1) } Address</div>

                        <form className="new-address" onSubmit={ handleNewAddress }>
                            <div className="input-group full">
                                <label htmlFor="newAddress-fullName">Full Name</label>
                                <input type="text" name="newAddress-fullName" autoComplete="name" value={ inputs?.newAddress?.fullName || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="newAddress-addressLine1">Address Line 1</label>
                                <input type="text" name="newAddress-addressLine1" autoComplete="address-line1" value={ inputs?.newAddress?.addressLine1 || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="newAddress-addressLine2">Address Line 2 <span className="optional">Optional</span></label>
                                <input type="text" name="newAddress-addressLine2" autoComplete="address-line2" value={ inputs?.newAddress?.addressLine2 || "" } onChange={ handleChange }/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="newAddress-cityTown">City/Town</label>
                                <input type="text" name="newAddress-cityTown" autoComplete="address-level2" value={ inputs?.newAddress?.cityTown || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="newAddress-state">County</label>
                                <input type="text" name="newAddress-state" autoComplete="address-level1" value={ inputs?.newAddress?.state || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="newAddress-zipcode">Postcode</label>
                                <input type="text" name="newAddress-zipcode" autoComplete="postal-code" value={ inputs?.newAddress?.zipcode || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="newAddress-country">Country</label>
                                <i className="fal fa-chevron-down"></i>
                                <select className="country" name="newAddress-country" autoComplete="country" value={ inputs?.newAddress?.country || "" } onChange={ handleChange } required>
                                    <option value="" style={{ display: "none" }}></option>
                                    {
                                        countryCodes.map((country, index) => (
                                            <option key={ index } value={ country.countryCode }>{ country.name }</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <button type="submit" className="submit new-address">{ textStrings["save_changes"][state.language] }</button>
                        </form>

                        <i className="close fal fa-times" onClick={ handleClick }></i>
                    </div>
                </section>

                <section className="edit-address">
                    <div className="content">
                        <div className="form-title">Edit { state?.inputModal?.type?.[0].toUpperCase() + state?.inputModal?.type?.substring(1) } Address</div>

                        <form className="edit-address" onSubmit={ handleEditAddress }>
                            <div className="input-group full">
                                <label htmlFor="editAddress-fullName">Full Name</label>
                                <input type="text" name="editAddress-fullName" autoComplete="name" value={ inputs?.editAddress?.fullName || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="editAddress-addressLine1">Address Line 1</label>
                                <input type="text" name="editAddress-addressLine1" autoComplete="address-line1" value={ inputs?.editAddress?.addressLine1 || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="editAddress-addressLine2">Address Line 2 <span className="optional">Optional</span></label>
                                <input type="text" name="editAddress-addressLine2" autoComplete="address-line2" value={ inputs?.editAddress?.addressLine2 || "" } onChange={ handleChange }/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="editAddress-cityTown">City/Town</label>
                                <input type="text" name="editAddress-cityTown" autoComplete="address-level2" value={ inputs?.editAddress?.cityTown || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="editAddress-state">County</label>
                                <input type="text" name="editAddress-state" autoComplete="address-level1" value={ inputs?.editAddress?.state || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="editAddress-zipcode">Postcode</label>
                                <input type="text" name="editAddress-zipcode" autoComplete="postal-code" value={ inputs?.editAddress?.zipcode || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="input-group">
                                <label htmlFor="editAddress-country">Country</label>
                                <i className="fal fa-chevron-down"></i>
                                <select className="country" name="editAddress-country" autoComplete="country" value={ inputs?.editAddress?.country || "" } onChange={ handleChange } required>
                                    <option value="" style={{ display: "none" }}></option>
                                    {
                                        countryCodes.map((country, index) => (
                                            <option key={ index } value={ country.countryCode }>{ country.name }</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <button type="submit" className="submit edit-address">{ textStrings["save_changes"][state.language] }</button>
                        </form>

                        <i className="close fal fa-times" onClick={ handleClick }></i>
                    </div>
                </section>

                <section className="add-video">
                    <div className="content">
                        <div className="form-title">Add Video</div>

                        <form className="add-video" onSubmit={ handleAddVideo }>
                            <div className="input-group full">
                                <label htmlFor="addVideo-url">YouTube URL</label>
                                <input type="text" name="addVideo-url" autoComplete="name" value={ inputs?.addVideo?.url || "" } onChange={ handleChange } required/>
                            </div>

                            <div className="video-preview">
                                <div className="title">{ inputs?.addVideo?.title }</div>
                            </div>

                            <button type="submit" className="submit add-video">{ textStrings["import_video"][state.language] }</button>
                        </form>

                        <i className="close fal fa-times" onClick={ handleClick }></i>
                    </div>
                </section>

                <section className="add-variation ">
                    <div className="content">
                        <div className="form-title">Edit Variations</div>

                        <form className="add-variation" onSubmit={ handleAddVariation }>
                            <table className="full">
                                <thead>
                                    <tr className="table-header">
                                        {
                                            inputs.options?.map((option, index) => (
                                                <th key={ index }>{ option.name == "custom" ? option.custom : option.name }</th>
                                            ))
                                        }
                                        <th>Price</th>
                                        <th>Stock</th>
                                        { /* <th>SKU</th> */ }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inputs?.variations?.map((x, index) => (
                                            <tr key={ index }>
                                                {
                                                    inputs.options.map((o, i) => (
                                                        <td key={ i }>
                                                            <input type="text" name={ "variation-" + index + "/option-" + i } value={ inputs?.variations?.[index]?.["option" + i] || "" } onChange={ handleChangeVariation } required/>
                                                        </td>
                                                    ))
                                                }
                                                <td>
                                                    <input type="text" name={ "variation-" + index + "/price" } value={ inputs?.variations?.[index]?.price || "" } onChange={ handleChangeVariation } required/>
                                                </td>
                                                <td>
                                                    <input type="text" name={ "variation-" + index + "/stock" } value={ inputs?.variations?.[index]?.stock || "" } onChange={ handleChangeVariation } required/>
                                                </td>
                                                {
                                                    /*
                                                        <td>
                                                            <input type="text" name={ "variation-" + index + "/sku" } value={ inputs?.variations?.[index]?.sku || "" } onChange={ handleChangeVariation }/>
                                                        </td>
                                                    */
                                                }
                                                <div><i className="fal fa-times click" onClick={ () => handleRemoveVariationRow(index) }></i></div>
                                            </tr>
                                        ))
                                    }
                                    <tr className="add-row" onClick={ handleVariationRow }>
                                        <td>
                                            <i className="fal fa-plus"></i> Add Row
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <button type="submit" className="submit add-variation">{ textStrings["save_changes"][state.language] }</button>
                        </form>

                        <i className="close fal fa-times" onClick={ handleClick }></i>
                    </div>
                </section>

                <section className="add-international">
                    <div className="content">
                        <div className="form-title">Edit International Shipping</div>

                        <form className="add-international" onSubmit={ handleAddInternational }>
                            <table className="full">
                                <thead>
                                    <tr className="table-header">
                                        <th>Shipping Zone</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inputs?.internationalShipping?.map((x, index) => (
                                            <tr key={ index }>
                                                <td>
                                                    <select className="country" name={ "international-" + index + "/country_code" } value={ inputs?.internationalShipping?.[index]?.country_code || "" } onChange={ handleChangeInternational } required>
                                                        <option value="" style={{ display: "none" }}></option>
                                                        <option key={ index-100 } style={{ color: "#7f7f7f" }} value="">--- Regions</option>
                                                        <option key={ index-99 } value="USA">United States of America</option>
                                                        <option key={ index-98 } value="UK">United Kingdom</option>
                                                        <option key={ index-97 } value="EUR">Europe</option>
                                                        <option key={ index-96 } value="WZ1">World Zone 1</option>
                                                        <option key={ index-95 } value="WZ2">World Zone 2</option>
                                                        <option key={ index-94 } style={{ color: "#7f7f7f" }} value="">--- Countries</option>
                                                        {
                                                            Object.keys(countryCodesDictionary).map((cc, index) => (
                                                                <option key={ index } value={ cc }>{ countryCodesDictionary[cc] }</option>
                                                            ))
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <input type="text" name={ "international-" + index + "/price" } value={ inputs?.internationalShipping?.[index]?.price || "" } onChange={ handleChangeInternational } required/>
                                                </td>
                                                <i className="fal fa-times click" onClick={ () => handleRemoveInternationalRow(index) }></i>
                                            </tr>
                                        ))
                                    }
                                    <tr className="add-row" onClick={ handleInternationalRow }>
                                        <td>
                                            <i className="fal fa-plus"></i> Add Row
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Link to="/shipping-zones" target="_blank">View Shipping Zones</Link>

                            <button type="submit" className="submit add-variation">{ textStrings["save_changes"][state.language] }</button>
                        </form>

                        <i className="close fal fa-times" onClick={ handleClick }></i>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default InputModal;
