import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, regionCodesDictionary, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, dummyListings } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

function BecomeSeller() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({  });
    const [data, setData] = useState({  });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <section className="become-seller">
            <div className="page-header">Become A Seller</div>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><p>Home</p></Link>
                    <span>></span>
                    <Link to="/shipping-zones"><p>{ textStrings["become_seller"][state.language] }</p></Link>
                </div>

                <div className="intro">
                    <div className="text">
                        <div className="title">Build your online crypto store with Storex</div>
                        <div className="description">It's easy to start accepting cryptocurrencies for your goods and services on Storex. Benefit from lower fees, enhanced security, faster transactions, and exposure to a tech-savvy audience. It's free to create listings and anyone can become a seller.</div>
                        <button>Learn More</button>
                    </div>

                    <div className="image">
                        <img src="/assets/images/become-seller.jpg" alt="Build your online store with Storex" />
                    </div>
                </div>
            </div>

            <div className="container-full alt">
                <div className="how-to">
                    <div className="image">
                        <img src="/assets/images/become-seller-2.jpg" alt="Build your online store with Storex" />
                    </div>

                    <div className="text">
                        <div className="title">In just 4 easy steps you could be earning crypto</div>

                        <div className="description">Selling your goods or services for crypto should be as easy as possible, which is why we have made it possible in just 4 easy steps.</div>

                        <div className="steps">
                            <div className="step">
                                <i className="fal fa-sign-in"></i> 
                                <p>Connect your WebAuth Wallet</p>
                            </div>
                            <div className="step">
                                <i className="fal fa-user"></i> 
                                <p>Fill in your public profile information</p>
                            </div>
                            <div className="step">
                                <i className="fal fa-tags"></i> 
                                <p>Create listings for your goods or services</p>
                            </div>
                            <div className="step">
                                <i className="fal fa-truck"></i> 
                                <p>Deliver your goods or services to buyers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="fees">
                    <div className="text">
                        <div className="title">Industry Low Fees</div>

                        <div className="description">Blockchain technology allows us to offer industry low fees to both buyers and sellers transacting with cryptocurrencies</div>

                        <div className="fee-info">
                            <div className="fee">
                                <div className="number">0%</div>
                                <div className="title">Listing Fee</div>
                                <div className="description">Creating listings is free on Storex</div>
                            </div>

                            <div className="fee">
                                <div className="number">3%</div>
                                <div className="title">Processing Fee</div>
                                <div className="description">Earn more profit with every sale</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-full alt">
                <div className="start-selling">
                    <div className="text">
                        <div className="title">Start Selling Today</div>

                        <div className="description">In as little as 5 minutes your listings could be live on the marketplace</div>

                        <Link to="/account">Create Account</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BecomeSeller;
