import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, maxMessageLength } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

function Message() {
    var { userId } = useParams();
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [selected, setSelected] = useState(0);
    const [inputs, setInputs] = useState({ type: "" });
    const [data, setData] = useState({ chats: [] });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        $("ul").animate({
            scrollTop: $("ul > li").first().outerHeight() * selected
        }, 0);
        
        $("div.imessage").scrollTop($("div.imessage")[0].scrollHeight);
    }, [selected]);

    useEffect(() => {
        if (userId) {
            if (data.chats.filter(chat => chat.recipient == userId).length > 0) {
                data.chats.forEach((chat, index) => {
                    if (chat.recipient == userId) setSelected(index);
                });
            } else {
                setData(values => (
                    { ...values, chats: [...data.chats, { recipient: userId, messages: [] }] }
                ));
            };
        };
    }, [data.chats]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/messages"
            });
            
            return navigate("/", { replace: true });
        };

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    function handleDataFetch() {
        var success = (res) => {
            setData(values => (
                { 
                    ...values, 
                    chats: res.sort((a, b) => {
                        if (a.recipient < b.recipient) return -1;
                        if (a.recipient > b.recipient) return 1;
                        return 0;
                    }) 
                }
            ));

            $("div.imessage").scrollTop($("div.imessage")[0].scrollHeight);
        };

        var error = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error authenticating your request, please try again");
        };

        Account.fetchMessages(state.session.auth.actor).then(res => {
            success(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchMessages(state.session.auth.actor).then(res => {
                        success(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        error(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                error(message);
            };
        });
    };

    function handleKeyDown(event) {
        var key = event.key;

        if (key === "Enter") {
            if ($("input[name='type']").is(":focus")) {
                if (inputs.type.trim()) {
                    handleSend({ target: $("input[name='type'] + i") });
                };
            };
        };
    };

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleSend(e) {
        var recipient = data.chats?.[selected]?.recipient;
        var content = inputs.type;
        var button = e.target;

        if (recipient == state.session.auth.actor) return;

        if (recipient != userId) {
            window.userId = null;
            window.history.replaceState(null, null, "/messages");
        };

        var showSuccess = () => {
            $(button).css("color", "#805fd3");

            $("ul").animate({
                scrollTop: $("ul > li").first().outerHeight() * selected
            }, 0);

            setInputs(values => (
                { ...values, type: "" }
            ));

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).css("color", "#805fd3");
        };

        Account.sendMessage(state.session.auth.actor, recipient, content, button).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err?.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.sendMessage(state.session.auth.actor, recipient, content, button).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err?.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                showError(message);
            };
        });
    };

    return (
        <section className="message">
            <div className="page-header">Messages</div>

            <div className="container">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Link to="/"><p>Home</p></Link>
                        <span>></span>
                        <Link to=""><p>{ textStrings["messages"][state.language] }</p></Link>
                    </div>
                </div>

                <div className="message-wrapper">
                    <div className="sidebar-wrapper">
                        <ul>
                            {
                                (data.chats.length < 1) && (
                                    <li className="empty">You have no conversations</li>
                                )
                            }

                            {
                                data.chats.map((chat, index) => (
                                    <li key={ index } className={ selected == index ? "active" : "" } onClick={ () => setSelected(index) }>
                                        <div className="profile-picture">
                                            <img src="/assets/images/default_avatar.webp" alt="" />
                                        </div>

                                        <div className="username">{ chat.displayName ? chat.displayName + " (" + chat.recipient + ")" : chat.recipient }</div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <div className="chat-wrapper">
                        <div className="imessage">
                            {
                                data.chats?.[selected]?.messages?.map((message, index) => (
                                    <>
                                        <p key={ index } className={ message.sender == state.session.auth.actor ? "from-me" : "from-them" }>{ message.content }</p>
                                        <span className={ message.sender == state.session.auth.actor ? "from-me" : "from-them" }>{ new Date(message.createdAt).toLocaleString() }</span>
                                    </>
                                ))
                            }
                        </div>

                        <div className="type">
                            <input className={ data.chats?.[selected]?.recipient == state.session.auth.actor ? "disabled" : "" } type="text" placeholder="Type message" name="type" value={ inputs.type } onKeyDown={ handleKeyDown } onChange={ handleChange }/>
                            <i className={ data.chats?.[selected]?.recipient == state.session.auth.actor ? "fas fa-arrow-alt-circle-up disabled" : "fas fa-arrow-alt-circle-up" } onClick={ handleSend }></i>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Message;
