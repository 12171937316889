import { React } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

function handleClick(event) {
    const target = $(event.target);

    if (target.hasAnyClass("close error-modal")) {
        if (target.hasClass("fixed")) return;
        
        $("div.error-modal").removeClass("success warning error");
    };
};

function ErrorModal() {
    return (
        <div>
            <div className="error-modal" onClick={ handleClick }>
                <div className="content">
                    <i className="status fal"></i>
                    <div className="text">There was an error processing your request, please try again later</div>
                    <i className="close fal fa-times" onClick={ handleClick }></i>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
