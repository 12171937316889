import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { kycStatus } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function VerifyEmailModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({  });
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (!state.inputModal) return;

        setData(values => (
            { ...values, email: state.inputModal?.data?.email, signatures: state.inputModal?.data?.signatures, signer: state.inputModal?.data?.signer, transaction: state.inputModal?.data?.transaction }
        ));
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;

        setInputs(values => ({
            ...values,
            confirmed: false
        }));
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;
    
        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleEmail(event) {
        event.preventDefault();

        console.log("email", data.email);
    };

    function handleConfirm(event) {
        event.preventDefault();

        $("button.confirm").html("<i class='fad fa-spinner-third'></i> Verifying").attr("disabled", true);

        var handleSuccess = (res) => {
            // check local variables for previous jwt
            var jwt = (data.jwt) ? data.jwt : res.token;

            if (!jwt) return handleError();

            dispatch({
                type: "emailVerified",
                value: true
            });

            setData(values => ({ 
                ...values, 
                jwt: jwt 
            }));

            // check metalx kyc status of user
            Proton.metalKYCStatus(jwt).then(res => {
                var { features, status } = res;

                $("button.confirm").html(textStrings["confirm"]?.[state.language]).attr("disabled", false);

                // if kyc is unsubmitted open start kyc modal
                if (status == kycStatus.UNSUBMITTED) {
                    dispatch({
                        type: "setModal",
                        value: {
                            action: "kyc",
                            data: jwt
                        }
                    });
    
                    return $("div.input-modal").addClass("active").find("section.kyc").addClass("active");
                };

                // if kyc is pending update the local variable
                if (status == kycStatus.PENDING) return setData(values => ({ 
                    ...values, 
                    pending: true 
                }));

                // if kyc is passed update local variable
                if (status == kycStatus.PASSED) return setData(values => ({ 
                    ...values, 
                    kyc: true 
                }));
            }).catch(err => {
                var { error, message } = err.responseJSON || { message: err };
    
                handleError(message, error);
            });
        };

        var handleError = (message, error) => {
            $("button.confirm").html(textStrings["confirm"]?.[state.language]).attr("disabled", false);

            $("div.error-modal").addClass("error").find("div.text").html(message || "There was an error authenticating your request, please try again");
        };

        Proton.metalLogin(data.email, data.signatures, data.signer, data.transaction, inputs.verifyCode).then(res => {
            handleSuccess(res);
        }).catch(err => {
            var { error, message } = err.responseJSON || { message: err };

            handleError(message, error);
        });
    };

    return (
        <section className="verify-email">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-envelope"></i> { textStrings["verify_email"]?.[state.language] }</div>

                <div className="verify-wrapper">
                    {
                        (!data.email) && (
                            <form onSubmit={ handleEmail }>
                                <div className="input-group full">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" name="email" autoComplete="email" value={ inputs.email || "" } onChange={ handleChange } required/>
                                </div>

                                <button type="submit" className="submit confirm">{ textStrings["confirm"]?.[state.language] }</button>
                            </form>
                        )
                    }

                    {
                        (data.email) && (
                            <form onSubmit={ handleConfirm }>
                                <div className="input-group full">
                                    <label htmlFor="verifyCode">Verification Code</label>
                                    <input type="text" name="verifyCode" value={ inputs.verifyCode || "" } onChange={ handleChange } required/>
                                </div>

                                <button type="submit" className="submit confirm">{ textStrings["confirm"]?.[state.language] }</button>
                            </form>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default VerifyEmailModal;