import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, disputeReasonsBuyer, disputeReasonsSeller, maxDescriptionLength, acceptedImageTypes, maxImagesLength, maxImagesSize } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function ViewEvidenceModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (state.inputModal?.action != "view-evidence") return;

        var listing = state.inputModal?.listing;

        console.log(listing?.disputeNotes, listing?.disputeFiles);

        setData(values => (
            { ...values, listing: listing }
        ));
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    return (
        <section className="view-evidence">
            <div className="content">
                <div className="form-title"><i className="fal fa-folders"></i> { textStrings["view_evidence"]?.[state.language] }</div>

                <div className="view-dispute-wrapper">
                    <div className="order-status">
                        <p className="subtitle">Order Status</p>

                        <div className="status-icons">
                            <div className="icon" title="Order fully paid">
                                <span>Paid</span>
                                <i className="fas fa-money-bill-wave active"></i>
                            </div>

                            <div className="icon" title={ (data.listing?.dispatched ? "Marked as shipped" : "Not marked as shipped") }>
                                <span>Shipped</span>
                                <i className={ "fas fa-truck-loading" + (data.listing?.dispatched ? " active" : "") }></i>
                            </div>

                            {
                                (data.listing?.disputed) && (
                                    <div className="icon">
                                        <span>Disputed</span>
                                        <i className="fas fa-gavel alert"></i>
                                    </div>
                                )
                            }
                            
                            {
                                (!data.listing?.disputed) && (
                                    <div className="icon">
                                        <span>Completed</span>
                                        <i className={ "fas fa-box-check" + (data.listing?.completed ? " active" : "") }></i>
                                    </div>
                                )
                            }

                            <div className="icon">
                                <span>Claimed</span>
                                <i className={ "fas fa-wallet" + (data.listing?.claimed ? " active" : "") }></i>
                            </div>
                        </div>
                    </div>

                    <div className="evidence seller">
                        <p className="subtitle">Seller Evidence</p>

                        <div className="image-previews">
                            {
                                (data.listing?.disputeFiles.filter(f => f.uploader == data.listing?.sellerId).length < 1) && (
                                    <p className="empty">No documents uploaded</p>
                                )
                            }

                            {
                                data.listing?.disputeFiles?.filter(f => f.uploader == data.listing?.sellerId).map((image, index) => (
                                    <a href={ image.src } target="_blank">
                                        <div key={ index } className="image-wrapper uploaded">
                                            {
                                                (image.src.includes(".pdf")) && (
                                                    <>
                                                        <i className="fal fa-file-pdf"></i>
                                                        <span>Document { image.id }</span>
                                                    </>
                                                )
                                            }

                                            {
                                                (!image.src.includes(".pdf")) && (
                                                    <img src={ image.src }/>
                                                )
                                            }
                                        </div>
                                    </a>
                                ))
                            }
                        </div>

                        <div className="notes">
                            {
                                (data.listing?.disputeNotes.filter(f => f.uploader == data.listing?.sellerId && f.note != "undefined").length < 1) && (
                                    <p className="empty">No notes provided</p>
                                )
                            }

                            {
                                data.listing?.disputeNotes.filter(f => f.uploader == data.listing?.sellerId && f.note != "undefined").map((note, index) => (
                                    <div className="note">
                                        <p className="id">Note #{ index+1 }:</p>
                                        <p>{ decodeURIComponent(note.note) }</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="evidence buyer">
                        <p className="subtitle">Buyer Evidence</p>

                        <div className="image-previews">
                            {
                                (data.listing?.disputeFiles.filter(f => f.uploader == data.listing?.buyerId).length < 1) && (
                                    <p className="empty">No documents uploaded</p>
                                )
                            }

                            {
                                data.listing?.disputeFiles?.filter(f => f.uploader == data.listing?.buyerId).map((image, index) => (
                                    <a href={ image.src } target="_blank">
                                        <div key={ index } className="image-wrapper uploaded">
                                            {
                                                (image.src.includes(".pdf")) && (
                                                    <>
                                                        <i className="fal fa-file-pdf"></i>
                                                        <span>Document { image.id }</span>
                                                    </>
                                                )
                                            }

                                            {
                                                (!image.src.includes(".pdf")) && (
                                                    <img src={ image.src }/>
                                                )
                                            }
                                        </div>
                                    </a>
                                ))
                            }
                        </div>

                        <div className="notes">
                            {
                                (data.listing?.disputeNotes.filter(f => f.uploader == data.listing?.buyerId && f.note != "undefined").length < 1) && (
                                    <p className="empty">No notes provided</p>
                                )
                            }

                            {
                                data.listing?.disputeNotes.filter(f => f.uploader == data.listing?.buyerId && f.note != "undefined").map((note, index) => (
                                    <div className="note">
                                        <p className="id">Note #{ index+1 }:</p>
                                        <p>{ decodeURIComponent(note.note) }</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ViewEvidenceModal;