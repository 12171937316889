import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

import Sidebar from "components/account/sidebar";

function Dashboard() {
    const navigate = useNavigate();
    const [slidePosition, setPosition] = useState(0);
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ listing: { scrollTop: 0, scrollBottom: 3, option0: [], option1: [], option2: [], buttonClass: "", isWatched: false, sellerKYC: false, deliveryLocation: "", deliveryCost: "", locationsString: "", locationsMore: false, locationsOverflow: false } });
    const [data, setData] = useState({ categories: dummyCategories, listing: dummyWeeklyDeal });

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account"
            });
            
            return navigate("/");
        };
    }, [state.authenticated]);

    useEffect(() => {
        // fetch account data from server
            setTimeout(() => {
                setData(values => (
                    {
                        ...values,
                        categories: dummyCategories,
                        listing: dummyWeeklyDeal
                    }
                ));
            }, 200)

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    function handleLogout() {
        if (!state.link || !state.session) return;

        state.link.removeSession("Storex", state.session.auth).then(res => {
            dispatch({
                type: "setAuth",
                value: null
            });

            dispatch({
                type: "setWallet",
                value: null
            });
        });
    };

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><p>Home</p></Link>
                    <span>></span>
                    <Link to="/account"><p>{ textStrings["my_account"][state.language] }</p></Link>
                </div>

                <div className="account-wrapper">
                    <Sidebar/>

                    <div className="tile-wrapper">
                        <div className="account-overview">
                            <div className="important"><i className="fal fa-exclamation-triangle"></i> Your account has been suspended, please contact support to resolve the issue</div>

                            { (state.account.fullName != "") ? <p>Welcome back <span>{ state.account.fullName }</span></p> : <p>Welcome to your account dashboard</p> }

                            <div className="notifications">You have <Link to="/notifications"><span>{ state.account?.notifications?.reduce((a, b) => a = a + (!b.markedRead), 0) }</span></Link> new notification{ (state.account?.notifications?.reduce((a, b) => a = a + (!b.markedRead), 0) > 1) ? "s" : "" }</div>
                        </div>

                        <div className="tiles">
                            <Link to="/account/settings">
                                <div className="tile">
                                    <i className="fal fa-user-cog"></i>
                                    <span>Settings</span>
                                    <p>{ state.w3aConnected ? "Manage your account details, identity verification, private keys, and sessions" : "Manage your account details and sessions" }</p>
                                </div>
                            </Link>

                            <Link to="/account/wallet">
                                <div className="tile">
                                    <i className="fal fa-wallet"></i>
                                    <span>Wallet</span>
                                    <p>{ state.w3aConnected ? "Manage your Storex wallet, crypto assets, and NFTs" : "Manage your external WebAuth wallet and token balances" }</p>
                                </div>
                            </Link>

                            <Link to="/account/addresses">
                                <div className="tile">
                                    <i className="fal fa-map-marker-alt"></i>
                                    <span>Addresses</span>
                                    <p>Update your registered address, billing address for invoices, and delivery address for goods</p>
                                </div>
                            </Link>

                            <Link to="/account/orders">
                                <div className="tile">
                                    <i className="fal fa-bags-shopping"></i>
                                    <span>Order History</span>
                                    <p>Manage your current orders and view your purchase history</p>
                                </div>
                            </Link>

                            <Link to="/account/listings">
                                <div className="tile">
                                    <i className="fal fa-tags"></i>
                                    <span>Active Listings</span>
                                    <p>Manage your active listings</p>
                                </div>
                            </Link>

                            <Link to="/account/sales">
                                <div className="tile">
                                    <i className="fal fa-box-usd"></i>
                                    <span>Sales</span>
                                    <p>Manage your sold listings</p>
                                </div>
                            </Link>

                            <Link to={ "/profile/" + state.session.auth.actor }>
                                <div className="tile">
                                    <i className="fal fa-store"></i>
                                    <span>Storefront</span>
                                    <p>Manage and customize your storefront</p>
                                </div>
                            </Link>

                            <Link to="/account/staking">
                                <div className="tile">
                                    <i className="fal fa-piggy-bank"></i>
                                    <span>Staking</span>
                                    <p>Manage your staking balances and enroll in fee reductions</p>
                                </div>
                            </Link>

                            <Link to="/account/arbitration">
                                <div className="tile">
                                    <i className="fal fa-gavel"></i>
                                    <span>Arbitration</span>
                                    <p>Manage your disputes and participate as a community arbitrator</p>
                                </div>
                            </Link>

                            <div className="tile soon">
                                <i className="fal fa-box-ballot"></i>
                                <span>Governance</span>
                                <p>View governance proposals and participate as a councillor</p>
                                <div className="soon">Coming Soon</div>
                            </div>

                            <Link to="/account/vouchers ">
                                <div className="tile">
                                    <i className="fal fa-gift-card"></i>
                                    <span>Vouchers</span>
                                    <p>Buy and manage gift vouchers and active promotions</p>
                                </div>
                            </Link>

                            <div className="tile soon">
                                <i className="fal fa-star"></i>
                                <span>Premium</span>
                                <p>Manage your Premium status and view your benefits</p>
                                <div className="soon">Coming Soon</div>
                            </div>

                            <div className="tile soon">
                                <i className="fal fa-ad"></i>
                                <span>Ads</span>
                                <p>Manage and review your ongoing ad campaigns</p>
                                <div className="soon">Coming Soon</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;
