import { React, useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { apiEndpoint, defaultVouchers } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function RedeemVoucherModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (state.inputModal?.action != "redeem") return;

        setInputs(values => (
            { ...values, voucherCode: state.inputModal?.data }
        ));
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handlePaste(event) {
        event.preventDefault();

        const target = event.target;
        const name = $(target).prev().attr("name");

        navigator.clipboard.readText().then(paste => {
            setInputs(values => (
                { ...values, [name]: paste }
            ));
        });
    };

    function handleRedeem(event) {
        event.preventDefault();

        var buttonHtml = $("button.redeem").html();

        $("button.redeem").html("<i class='fad fa-spinner-third'></i> Redeeming").attr("disabled", true);

        var handleSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("You have successfully redeemed the voucher</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            $("button.redeem").html(buttonHtml).attr("disabled", false);

            $("div.input-modal, section").removeClass("active");
        };

        var handleError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error processing your request, please try again");

            $("button.redeem").html(buttonHtml).attr("disabled", false);
        };

        Proton.redeemVoucher(state, inputs.voucherCode).then(tx => {
            handleSuccess(tx.processed.id);
        }).catch(err => {
            handleError(err.error?.details?.[0]?.message || err);
        });
    };

    return (
        <section className="redeem-voucher">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-gift-card"></i> { textStrings["redeem_voucher"]?.[state.language] }</div>

                <div className="voucher-wrapper">
                    <form onSubmit={ handleRedeem }>
                        <div className="input-group">
                            <label htmlFor="voucherCode">Voucher Code</label>
                            <input type="text" name="voucherCode" autoComplete="none" maxLength="17" value={ inputs.voucherCode || "" } onChange={ handleChange } required/>
                            <span className="click paste" onClick={ handlePaste }>Paste</span>
                        </div>

                        <button type="submit" className="submit redeem">Redeem</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default RedeemVoucherModal;