import { React, useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { apiDevEndpoint, defaultVouchers } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function BuyVoucherModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({ ticker: "", voucherId: 0, quoteId: 0, quoteAmount: 0, ticker: "", amount: 0 });
    const [state, dispatch] = useStateValue();

    const paypal = useRef();

    useEffect(() => {
        if (!state.closeInputModal) return;

        setData(values => ({
            ...values,
            voucherId: 0,
            quoteId: 0,
            quoteAmount: 0,
            ticker: "",
            amount: 0,
            success: false
        }));

        $("#paypal-buttons").empty();
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.inputModal?.action != "voucher") return;

        // fetch voucher quote from server
        fetch(apiDevEndpoint + "/v1/voucher/quote?voucherId=" + state.inputModal.data, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.ok) return res.json();
        }).then(({ id, amount }) => {
            setData(values => ({
                ...values,
                voucherId: state.inputModal.data,
                quoteId: id,
                quoteAmount: amount,
                ticker: defaultVouchers[state.inputModal.data]?.ticker,
                amount: defaultVouchers[state.inputModal.data]?.price,
                success: false
            }));

            renderPayPal(id);
        }).catch(e => {
            $("div.error-modal").addClass("error").find("div.text").html(e.message || "There was an error processing your payment, please try again");
        });

        function renderPayPal(quoteId) {
            window.paypal.Buttons({
                createOrder: () => {
                    return fetch(apiDevEndpoint + "/v1/voucher/order", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            user: state.session.auth.actor,
                            quoteId: quoteId
                        })
                    }).then(res => {
                        if (res.ok) return res.json();
    
                        return res.json().then(json => Promise.reject(json));
                    }).then(({ id }) => {
                        return id;
                    }).catch(e => {
                        return Promise.reject(e);
                    });
                },
                onApprove: async(data, actions) => {
                    const order = await (actions.order.capture());
    
                    setData(values => ({
                        ...values,
                        success: order
                    }));
                },
                onError: (err) => {
                    $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error processing your payment, please try again");
                }
            }).render(paypal.current);
        };
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    return (
        <section className="buy-voucher">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-gift-card"></i> { textStrings["purchase_voucher"]?.[state.language] }</div>

                <div className="voucher-wrapper">
                    {
                        (data.success) && (
                            <>
                                <i className="fal fa-check-circle"></i>

                                <p>Thank you for your order</p>

                                <p>Order ID <span>{ data.success?.id }</span></p>

                                <p className="small">Once your order has been processed the voucher code will be delivered to the email address associated with your PayPal account</p>
                            </>
                        )
                    }

                    {
                        (!data.success) && (
                            <>
                                <div className="purchase-title">{ data.ticker } Gift Voucher</div>

                                <div className="purchase-price">${ data.amount?.toFixed(2) }</div>

                                <div className="purchase-amount">{ parseFloat(data.quoteAmount)?.toFixed(4) } { data.ticker }</div>

                                <div id="paypal-buttons" ref={paypal}></div>

                                <div className="info">By clicking the PayPal button you agree you have read and accepted our <Link to="/user-agreement" target="_blank">User Agreement</Link> and <Link to="/user-agreement" target="_blank">Privacy Notice</Link> regarding the processing of your data.</div>
                            </>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default BuyVoucherModal;