import { React, useState, useEffect, useMemo, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import * as CryptoJS from 'crypto-js';

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyListings, maxActiveListings } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";

import Sidebar from "components/account/sidebar";

function Listings() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ selectedActivePage: 0, selectedSalesPage: 0 });
    const [data, setData] = useState({ categories: dummyCategories, stats: { sold30d: 0, count30d: 0, soldAll: 0, countAll: 0 }, rawListings: [], rawSales: [], sales: [] });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        const max = -219.99078369140625;

        $(".progress").each((index, value) => {
            var percent = (value.getAttribute("data-progress") > 100) ? 100 : value.getAttribute("data-progress");

            value.querySelector(".fill").setAttribute("style", "stroke-dashoffset: " + ((100 - percent) / 100) * max);
        });
    }, [data]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/selling"
            });
            
            return navigate("/");
        };

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    useEffect(() => {
        if (state.fetchSellingHistory) return handleDataFetch();
    }, [state.fetchSellingHistory]);

    function handleDataFetch() {
        var successSelling = (res) => {
            setData(values => (
                { ...values, rawListings: res.reverse().filter(l => l.active), listings: res.filter(l => l.active).slice(inputs.selectedActivePage * 10, (inputs.selectedActivePage * 10) + 10) }
            ));
        };

        var successSales = (res) => {
            setData(values => (
                { ...values, stats: { ...values.stats, count30d: res.length, sold30d: res.reduce((a, b) => a = a + parseFloat(b.price), 0), countAll: res.length, soldAll: res.reduce((a, b) => a = a + parseFloat(b.price), 0) }, rawSales: res.reverse(), sales: res.slice(inputs.selectedSalesPage * 10, (inputs.selectedSalesPage * 10) + 10) }
            ));
        };

        var error = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error authenticating your request, please try again");

            $("section.create-listing button.submit").html(textStrings["submit_listing"][state.language]);
        };

        Account.fetchSellerListings(state.session.auth.actor).then(res => {
            successSelling(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchSellerListings(state.session.auth.actor).then(res => {
                        successSelling(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        error(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                error(message);
            };
        });

        Account.fetchSalesListings(state.session.auth.actor).then(res => {
            successSales(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchSalesListings(state.session.auth.actor).then(res => {
                        successSales(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        error(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                error(message);
            };
        });
    };

    function handleRating(rating) {
        var classObject = {};
        var remainder = (rating % 1).toFixed(1);

        for (var i = 0; i < Math.floor(rating); i++) {
            classObject[i] = "fas fa-star fill";
        };

        if (remainder > 0 && remainder < 0.3) {
            classObject[Math.floor(rating)] = "fas fa-star";
        } else if (remainder >= 0.3 && remainder < 0.8) {
            classObject[Math.floor(rating)] = "fad fa-star-half";
        } else if (remainder >= 0.8) {
            classObject[Math.floor(rating)] = "fas fa-star";
        };

        for (var i = 0; i < 5; i++) {
            if (!classObject[i]) classObject[i] = "fas fa-star";
        };

        return (
            <div>
                <i className={ classObject[0] }></i>
                <i className={ classObject[1] }></i>
                <i className={ classObject[2] }></i>
                <i className={ classObject[3] }></i>
                <i className={ classObject[4] }></i>
            </div>
        );
    };

    function endListing(listingId, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Ending");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Listing has been successfully ended");

            $(button).html(textStrings["end_listing"][state.language]);

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error authenticating your request, please try again");

            $(button).html(textStrings["end_listing"][state.language]);
        };

        Proton.endListing(state, listingId).then(tx => {
            if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

            var txId = tx.processed?.id || "";

            Listing.endListing(state.session.auth.actor, listingId, txId).then(res => {
                showSuccess(res);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.endListing(state.session.auth.actor, listingId, txId).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };
    
                            showError(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
                        navigate("/");
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err);
        });
    };

    function handleOrderDetails(listing) {
        $("div.input-modal").addClass("active").find("section.order-details").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "order-details",
                listing: listing
            }
        });
    };

    function handleRefund(listing) {
        $("div.input-modal").addClass("active").find("section.refund-order").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "refund-order",
                listing: listing
            }
        });
    };

    function handleDispatch(orderNumber, dispatched, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Updating");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Order dispatch status has been updated");

            $(button).html(textStrings[!dispatched ? "mark_dispatch" : "unmark_dispatch"][state.language]);

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html(textStrings[dispatched ? "mark_dispatch" : "unmark_dispatch"][state.language]);
        };

        Listing.markDispatch(state.session.auth.actor, orderNumber, dispatched).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Listing.markDispatch(state.session.auth.actor, orderNumber, dispatched).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                showError(message);
            };
        });
    };

    function handleClaimFunds(orderNumber, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Claiming");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Order payment has been successfully claimed");

            $(button).html(textStrings["claim_funds"][state.language]);

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html(textStrings["claim_funds"][state.language]);
        };

        Proton.claimFunds(state.session, orderNumber).then(tx => {
            if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

            var txId = tx.processed?.id || "";

            Listing.claimFunds(state.session.auth.actor, orderNumber, txId).then(res => {
                showSuccess(res);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.claimFunds(state.session.auth.actor, orderNumber, txId).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };
    
                            showError(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
                        navigate("/");
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleActivePage(index) {
        setInputs(values => (
            { ...values, selectedActivePage: index }
        ));

        setData(values => (
            { ...values, listings: data.rawListings.slice(index * 10, (index + 1) * 10) }
        ));

        $("body, html").animate({ scrollTop: $(".active-listings").offset().top - 24 }, 0);
    };

    function handleSalesPage(index) {
        setInputs(values => (
            { ...values, selectedSalesPage: index }
        ));

        setData(values => (
            { ...values, sales: data.rawSales.slice(index * 10, (index + 1) * 10) }
        ));

        $("body, html").animate({ scrollTop: $(".selling-history").offset().top - 24 }, 0);
    };

    function editListing(listing) {
        navigate("/edit-listing");

        dispatch({
            type: "setEditListing",
            value: listing
        });
    };

    function sellSimilar(listing) {
        navigate("/create-listing");

        dispatch({
            type: "setCreateListing",
            value: listing
        });
    };

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><p>Home</p></Link>
                    <span>></span>
                    <Link to="/account"><p>{ textStrings["my_account"][state.language] }</p></Link>
                    <span>></span>
                    <Link to="/account/selling"><p>{ textStrings["account_selling"][state.language] }</p></Link>
                </div>

                <div className="account-wrapper">
                    <Sidebar></Sidebar>

                    <div className="content-wrapper">
                        <section className="active-listings">
                            <div className="content-title"><i className="fal fa-tags"></i> { textStrings["account_active_listings"][state.language] }</div>

                            <div className="active-wrapper">
                                <ul>
                                    {
                                        (data.listings == null) && (
                                            <div className="loading">
                                                <i className="fad fa-spinner-third"></i>

                                                <span>Fetching listings</span>
                                            </div>
                                        )
                                    }

                                    {
                                        (data.listings?.filter(l => l.active).length < 1) && (
                                            <>
                                                <div className="empty">You don't have any active listings</div>
                                                <span className="empty"><Link to="/create-listing">Create a listing</Link> for free</span>
                                            </>
                                        )
                                    }

                                    {
                                        data.listings?.filter(l => l.active)?.map((listing, index) => (
                                            <li key={ index }>
                                                <div className="mobile-wrapper">
                                                    <Link to={ "/listing/" + listing._id }>
                                                        <div className="thumbnail-wrapper">
                                                            <img src={ listing.thumbnail } alt={ listing.title } />
                                                        </div>
                                                    </Link>

                                                    <div className="info">
                                                        <Link to={ data.categories[listing.category]?.["url"] + data.categories[listing.category]["subcategories"][listing.subcategory]?.["url"] }><div className="subcategory">{ textStrings[listing.subcategory?.replace("-", "_")]?.[state.language] }</div></Link>
                                                        <Link to={ "/listing/" + listing._id }><div className="title">{ listing.title }</div></Link>
                                                        <div className="condition">{ listing.condition }</div>
                                                        <div className="reviews" title={ listing.feedback?.reduce((a, b) => a + b.rating, 0) / listing.feedback?.length || 0 }>{ handleRating(listing.feedback?.reduce((a, b) => a + b.rating, 0) / listing.feedback?.length) } <span>{ "(" + (listing.feedback?.length || 0) + " ratings)" }</span></div>
                                                        <div className="price">{ parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                                        {
                                                            (listing.options.length > 0) && (
                                                                <div className="options">Customization Options</div>
                                                            )
                                                        }
                                                        <div className="stat-wrapper n600">
                                                            <div className="stat" title="Status"><i className={ "fas" + (listing.broadcast ? " fa-wifi" : " fa-wifi-slash") }></i> { listing.broadcast ? "Live" : "Not Live" }</div>
                                                            <div className="stat" title="Escrow"><i className={ "fas" + (listing.escrow ? " fa-shield-check" : " fa-shield amber") }></i> { listing.escrow ? "Escrow" : "No Escrow" }</div>
                                                            <div className="stat" title="Stock"><i className="fas fa-inventory"></i> { listing.variations.reduce((a, b) => a + parseFloat(b.stock), 0) }</div>
                                                            <div className="stat" title="Sales"><i className="fas fa-tags"></i> { listing.stats?.sales }</div>
                                                            <div className="stat" title="Impressions"><i className="fas fa-eye"></i> { listing.stats?.impressions }</div>
                                                            <div className="stat" title="Clicks"><i className="fas fa-mouse-pointer"></i> { listing.stats?.clicks }</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mobile-stat-wrapper f600">
                                                    <div className="stat" title="Status"><i className={ "fas" + (listing.broadcast ? " fa-wifi" : " fa-wifi-slash") }></i> { listing.broadcast ? "Live" : "Not Live" }</div>
                                                    <div className="stat" title="Escrow"><i className={ "fas" + (listing.escrow ? " fa-shield-check" : " fa-shield amber") }></i> { listing.escrow ? "Escrow" : "No Escrow" }</div>
                                                    <div className="stat" title="Stock"><i className="fas fa-inventory"></i> { listing.variations.reduce((a, b) => a + parseFloat(b.stock), 0) }</div>
                                                    <div className="stat" title="Sales"><i className="fas fa-tags"></i> { listing.stats?.sales }</div>
                                                    <div className="stat" title="Impressions"><i className="fas fa-eye"></i> { listing.stats?.impressions }</div>
                                                    <div className="stat" title="Clicks"><i className="fas fa-mouse-pointer"></i> { listing.stats?.clicks }</div>
                                                </div>

                                                <div className="actions">
                                                    { /* <button type="submit" onClick={ () => editListing(listing) }>Edit Listing</button> */ }
                                                    <button type="submit" onClick={ e => endListing(listing.listingId, e.target) }>End Listing</button>
                                                    <button type="submit" onClick={ () => sellSimilar(listing) }>Sell Similar</button>
                                                </div>
                                            </li>
                                        ))
                                    }

                                    <div className="pages">
                                        {
                                            (data.rawListings.filter(l => l.active)?.length > 60 && inputs.selectedActivePage > 3) && (
                                                <i onClick={ () => handleActivePage(0) } className="fal fa-chevron-double-left"></i>
                                            )
                                        }

                                        {
                                            (data.rawListings.filter(l => l.active)?.length > 60) && (
                                                [...Array(Math.ceil(data.rawListings.filter(l => l.active)?.length / 10))].map((i, index) => (
                                                    (index - inputs.selectedActivePage <= 3 && index - inputs.selectedActivePage >= -3) ? <button className={ inputs.selectedActivePage == index ? "active" : "" } onClick={ () => handleActivePage(index) }>{ index + 1 }</button> : ""
                                                ))
                                            )
                                        }

                                        {
                                            (data.rawListings.filter(l => l.active)?.length > 60 && inputs.selectedActivePage < Math.ceil(data.rawListings.filter(l => l.active)?.length / 10)-4) && (
                                                <i onClick={ () => handleActivePage(Math.ceil(data.rawListings.filter(l => l.active)?.length / 10)-1) } className="fal fa-chevron-double-right"></i>
                                            )
                                        }

                                        {
                                            (data.rawListings.filter(l => l.active)?.length < 60) && (
                                                [...Array(Math.ceil(data.rawListings.filter(l => l.active)?.length / 10))].map((i, index) => (
                                                    <button className={ inputs.selectedActivePage == index ? "active" : "" } onClick={ () => handleActivePage(index) }>{ index + 1 }</button>
                                                ))
                                            )
                                        }
                                    </div>
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Listings;
