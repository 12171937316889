import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import { useStateValue } from "state";
import { defaultAccount, currencyDictionary, languageDictionary, dummyCategories } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

import MobileModal from "components/header/mobile";
import CartModal from "components/header/cart";
import WishlistModal from "components/header/wishlist";

const clientId = "BG1jyVVq_Ff0Qh8yo6esMOY69i5VyGId-V8CwWIkfnTE1HgcN86xz9rjB23SfsjNzEHvV-AfGNpLJ_lFMBS-5dA";

const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x1",
    rpcTarget: "https://mainnet.infura.io/v3/b955490948ba4ad2a3ef078a8a527a19",
};

function Header() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({ category: "", search: "", wishlist: [] });
    const [menuStates, setMenuState] = useState({ cart: false, wishlist: false });
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        const f = async () => {
            try {
                const web3auth = new Web3Auth({
                    clientId,
                    chainConfig: chainConfig,
                    uiConfig: {
                        //appName: "Paymex",
                        appName: "Storex",
                        //appLogo: "https://storex.io/assets/images/paymex_120.png",
                        appLogo: "https://storex.io/assets/images/strx_storex_256.png",
                        theme: "light",
                        loginMethodsOrder: ["google", "twitter", "facebook", "discord", "apple", "github", "reddit", "linkedin", "twitch"],
                        defaultLanguage: "en",
                        loginGridCol: 3,
                        primaryButton: "emailLogin"
                    },
                    web3AuthNetwork: "mainnet"
                });

                const openloginAdapter = new OpenloginAdapter({
                    loginSettings: {
                        mfaLevel: "mandatory",
                    },
                    adapterSettings: {
                        uxMode: "redirect",
                        whiteLabel: {
                            name: "Storex",
                            logoLight: "https://storex.io/assets/images/strx_storex_256.png",
                            logoDark: "https://storex.io/assets/images/strx_storex_256.png",
                            defaultLanguage: "en",
                            dark: false,
                            theme: {
                                primary: "#037BEB"
                            }
                        },
                        mfaSettings: {
                            passwordFactor: {
                                enable: true,
                                priority: 1,
                                mandatory: true,
                            },
                            deviceShareFactor: {
                                enable: false
                            },
                            socialBackupFactor: {
                                enable: false
                            },
                            backUpShareFactor: {
                                enable: false,
                            },
                        },
                    }
                });

                web3auth.configureAdapter(openloginAdapter);

                dispatch({
                    type: "setW3A",
                    value: web3auth
                });

                await web3auth.initModal({
                    modalConfig: {
                        [WALLET_ADAPTERS.OPENLOGIN]: {
                            label: "openlogin",
                            loginMethods: {
                                sms_passwordless: {
                                    name: "sms_passwordless",
                                    showOnModal: false
                                }
                            }
                        },
                        [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
                            label: "wallet_connect",
                            showOnModal: false
                        },
                        [WALLET_ADAPTERS.METAMASK]: {
                            label: "metamask",
                            showOnModal: false
                        }
                    }
                });

                if (web3auth.connected) {
                    dispatch({
                        type: "setW3AProvider",
                        value: {
                            provider: web3auth.provider,
                            connected: true,
                            pubKey: await Paymex.publicKey(web3auth.provider),
                            privKey: await Paymex.privateKey(web3auth.provider),
                            user: await web3auth.getUserInfo()
                        }
                    });
                } else {
                    dispatch({
                        type: "setW3AProvider",
                        value: {
                            provider: web3auth.provider,
                            connected: false
                        }
                    });

                    Proton.restore().then(({session, link}) => {
                        if (!session || !link) {
                            return dispatch({
                                type: "setAuth",
                                value: false
                            });
                        };

                        window.session = session;
                    
                        dispatch({
                            type: "setWallet",
                            value: { session: session, link: link }
                        });
                    
                        Proton.isKycVerified(session.auth.actor).then(verified => {
                            dispatch({
                                type: "setKyc",
                                value: { kyc: verified }
                            });
                        });

                        var hasRefreshToken = Cookies.get(session.auth.actor + "_has_rt", { domain: ".storex.io", path: "/" });
                        
                        if (hasRefreshToken === "" || hasRefreshToken === undefined || hasRefreshToken === "undefined") {
                            $("div.input-modal").addClass("active").find("section.verify-wallet").addClass("active");

                            return dispatch({
                                type: "setAuth",
                                value: false
                            });
                        };
                    
                        Account.requestToken(session.auth.actor).then(res => {
                            dispatch({
                                type: "setAuth",
                                value: { id: session.auth.actor }
                            });
                        }).catch(err => {
                            navigate("/");

                            $("div.input-modal").addClass("active").find("section.verify-wallet").addClass("active");
                    
                            return dispatch({
                                type: "setAuth",
                                value: false
                            });
                        });
                    }).catch(error => {
                        return dispatch({
                            type: "setAuth",
                            value: false
                        });
                    });
                };
            } catch(error) {
                console.error(error);
            };
        };

        f();
    }, []);

    useEffect(() => {
        const f = async () => {
            if (!state.w3aConnected) return;

            Paymex.authenticateWallet(state, dispatch).catch(({ responseJSON, status, statusText }) => {
                if (status == 404) {
                    $("div.input-modal").addClass("active").find("section.create-account").addClass("active");

                    dispatch({
                        type: "setModal",
                        value: {
                            action: "create-account"
                        }
                    });

                    return;
                };

                $("div.error-modal").addClass("error").find("div.text").html(responseJSON);
            });
        };

        f();
    }, [state.w3aConnected]);

    useEffect(() => {
        document.addEventListener("keyDown", handleKeyDown);

        $("body").click(function(event) {
            var target = $(event.target);
        });

        return () => {
            document.removeEventListener("keyDown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (state.promptLogin) {
            handleConnect(state.promptLogin);

            dispatch({
                type: "promptLogin",
                value: null
            });
        };
    }, [state.promptLogin]);

    useEffect(() => {
        if (state.promptRedirect) {
            navigate(state.promptRedirect);

            dispatch({
                type: "promptRedirect",
                value: null
            });
        };
    }, [state.promptRedirect]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) return dispatch({
            type: "setAccount",
            value: {
                account: false
            }
        });

        if (state.authenticated) {
            if (state.promptLogin) {
                navigate(state.promptLogin);

                dispatch({
                    type: "promptLogin",
                    value: null
                });
            };

            handleAccountDataFetch();
        };
    }, [state.authenticated]);

    function changePreference(event, actionType) {
        var value = event.target.textContent;

        var isImg = $(event.target).is("img");

        if (isImg) {
            value = $(event.target).parent().text();
        };

        dispatch({
            type: actionType,
            value: value.toUpperCase()
        });

        // send new account preferences to server
    };

    function setData(res) {
        dispatch({
            type: "setAccount",
            value: {
                account: {
                    id: res._id || defaultAccount.id,
                    type: res.type || "",
                    username: res.username || "",
                    fullName: res.fullName || defaultAccount.fullName,
                    email: res.email || defaultAccount.email,
                    phone: res.phone || defaultAccount.phone,
                    country: res.country || defaultAccount.country,
                    referral: res.referral || defaultAccount.referral,
                    registeredAddress: res.registeredAddress || defaultAccount.registeredAddress,
                    billingAddress: res.billingAddress || defaultAccount.billingAddress,
                    deliveryAddress: res.deliveryAddress || defaultAccount.deliveryAddress,
                    notifications: res.notifications || defaultAccount.notifications
                }
            }
        });

        dispatch({
            type: "setCart",
            value: res.cart
        });

        dispatch({
            type: "setWishlist",
            value: res.wishlist
        });
    };

    function handleAccountDataFetch() {
        Account.fetchAccountDetails(state.session.auth.actor).then(res => {
            setData(res);
        }).catch(err => {
            var { message } = err.responseJSON;

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchAccountDetails(state.session.auth.actor).then(res => {
                        setData(res);
                    });
                }).catch(err => {
                    navigate("/");

                    $("div.input-modal").addClass("active").find("section.verify-wallet").addClass("active");
            
                    return dispatch({
                        type: "setAuth",
                        value: false
                    });
                });
            };
        });
    };

    function handleAccountClick(event, redirect) {
        if (state.w3aConnected) {
            if (!state.authenticated) {
                event.preventDefault();
    
                return Paymex.authenticateWallet(state, dispatch);
            };
        };

        if (!state.authenticated) {
            event.preventDefault();

            return handleLogin(null, redirect);
        };

        navigate(redirect);
    };

    function handleConnect(redirect) {
        return Paymex.authenticateWallet(state, dispatch, redirect);
    };

    function handleLogin(session, redirect) {
        const walletSession = session ? (session.auth ? session : state.session) : state.session;

        if (Cookies.get(walletSession.auth.actor + "_has_rt", { domain: ".storex.io", path: "/" })) {
            Account.requestToken(walletSession.auth.actor).then(res => {
                dispatch({
                    type: "setAuth",
                    value: { id: state.session.auth.actor }
                });
            }).catch(err => {
                navigate("/");

                $("div.input-modal").addClass("active").find("section.verify-wallet").addClass("active");
        
                return dispatch({
                    type: "setAuth",
                    value: false
                });
            });
        } else {
            Account.verifyWallet(walletSession.auth.actor).then(tokens => {
                Proton.verify(walletSession, tokens.public).then(tx => {
                    Account.requestLogin(walletSession.auth.actor, tokens.public, tokens.private, (tx.serializedTransaction || tx.resolved.serializedTransaction), tx.signatures, tx.signer, tx.resolvedTransaction).then(res => {
                        dispatch({
                            type: "setAuth",
                            value: { id: walletSession.auth.actor }
                        });

                        if (redirect) navigate(redirect);
                    });
                });
            });
        };
    };

    function handleLogout() {
        if (state.w3aConnected) return Paymex.disconnectWallet(state, dispatch);

        if (!state.link || !state.session) return;

        state.link.removeSession("Storex", state.session.auth).then(res => {
            dispatch({
                type: "setAuth",
                value: null
            });

            dispatch({
                type: "setWallet",
                value: null
            });

            setMenuState(values => (
                { ...values, "account": false }
            ));
        });
    };

    function handleKeyDown(event) {
        var key = event.key;

        if (key === "Enter") {
            if ($("input[name='search']").is(":focus")) {
                if (inputs.search) {
                    handleSearch();
                };
            };
        };
    };

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleCategoryEnter() {
        $(".category-dropdown").addClass("hover");
    };

    function handleCategoryLeave() {
        $(".category-dropdown").removeClass("hover tog-hover");
    };

    function handleCategoryClick() {
        $(".category-dropdown").removeClass("hover tog-hover");
    };

    function handleCategoryToggle() {
        if ($(".category-dropdown").hasClass("hover")) {
            $(".category-dropdown").removeClass("hover");
        } else {
            $(".category-dropdown").addClass("hover")
        };

        if ($(".category-dropdown").hasClass("tog-hover")) {
            $(".category-dropdown").removeClass("tog-hover")
        } else {
            $(".category-dropdown").addClass("tog-hover")
        };
    };

    function handleDailyDeals() {
        var weeklyDeal = $("div.weekly-deal");

        if (weeklyDeal.length < 1) {
            dispatch({
                type: "viewDeals",
                value: true
            });

            navigate("/");
        } else {
            window.scrollTo({ top: weeklyDeal.offset().top - 12, left: 0, behavior: "smooth" });
        };
    };

    function handleSearch() {
        console.log(inputs.search);
        var encodedTerms = encodeURI(inputs.search || "");

        navigate("/search/" + encodedTerms);
    };

    return (
        <>
            {
                (state.authenticated) && (
                    <div className="account-mobile">
                        <div className="container">
                            <Link to="/">
                                <div className={ window.location.pathname == "/" ? "action active" : "action" }>
                                    <i className="fas fa-home"></i>
                                    <p>Home</p>
                                </div>
                            </Link>

                            <Link to="/cart">
                                <div className={ window.location.pathname == "/cart" ? "action active" : "action" }>
                                    <i className="fas fa-shopping-cart"></i>
                                    <p>Cart</p>
                                </div>
                            </Link>

                            <Link to="/messages">
                                <div className={ window.location.pathname.includes("/messages") ? "action active" : "action" }>
                                    <i className="fas fa-envelope"></i>
                                    <p>Messages</p>
                                </div>
                            </Link>

                            <Link to="/account">
                                <div className={ window.location.pathname.includes("/account") ? "action active" : "action" }>
                                    <i className="fas fa-user"></i>
                                    <p>Account</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                )
            }

            <header className="header">
                <div className="header-top">
                    <div className="container">
                        <div className="header-top-left n1280">
                            <p className="welcome">{ textStrings?.["header_welcome"]?.[state.language] }</p>
                        </div>

                        <div className="header-top-right n630">
                            <div className="dropdown n840">
                                <div className="click">{ state.currency || "USD" }</div>
                                <div className="dropdown-box">
                                    { currencyDictionary.map((currency, index) => <div className="click" key={ index } onClick={ (e) => changePreference(e, "setCurrency") }>{ currency.code }</div>) }
                                </div>
                            </div>

                            <div className="dropdown n840">
                                <div className="click"><img className={ "flag " + (state.language.toLowerCase()) }></img> { state.language }</div>
                                <div className="dropdown-box">
                                    { languageDictionary.map((language, index) => <div className="click" key={ index } onClick={ (e) => changePreference(e, "setLanguage") }><img className={ "flag " + language.code.toLowerCase() }></img>{ language.code }</div>) }
                                </div>
                            </div>

                            <span className="divider n840"></span>

                            {
                                (state.authenticated && (state.w3aConnected || state.connected)) && (
                                    <div>
                                        <div className="menu">
                                            <Link to="/notifications" className="click" onClick={ (e) => handleAccountClick(e, "/notifications") }><i className={ (state.account?.notifications?.reduce((a, b) => a = a + (!b.markedRead), 0) > 0) ? "fal fa-bell-on" : "fal fa-bell" }></i>{ textStrings?.["header_notifications"]?.[state.language] } { (state.account?.notifications?.reduce((a, b) => a = a + (!b.markedRead), 0) > 0) && (<>({ state.account?.notifications?.reduce((a, b) => a = a + (!b.markedRead), 0) })</>) }</Link>
                                        </div>

                                        <div className="menu">
                                            <Link to="/create-listing" className="click" onClick={ (e) => handleAccountClick(e, "/create-listing") }><i className="fal fa-tag"></i>{ textStrings?.["header_sell"]?.[state.language] }</Link>
                                        </div>

                                        <div className="menu">
                                            <Link to="/messages" className="click" onClick={ (e) => handleAccountClick(e, "/messages") }><i className="fal fa-envelope"></i>{ textStrings?.["header_messages"]?.[state.language] }</Link>
                                        </div>

                                        <div className="menu">
                                            <Link to="/account" className="click" onClick={ (e) => handleAccountClick(e, "/account") }><i className="fal fa-user"></i>{ textStrings?.["header_account"]?.[state.language] }</Link>
                                        </div>

                                        <div className="menu">
                                            <div className="click" onClick={ handleLogout }><i className="fal fa-sign-out"></i> { textStrings?.["header_sign_out"]?.[state.language] }</div>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                ((!state.authenticated && (state.w3aConnected || state.connected))) && (
                                    <>
                                        <div className="menu">
                                            <i className="fad fa-spinner-third"/>
                                        </div>

                                        <div className="menu">
                                            <div className="click" onClick={ handleLogout }><i className="fal fa-sign-out"></i> { textStrings?.["header_sign_out"]?.[state.language] }</div>
                                        </div>
                                    </>
                                )
                            }

                            {
                                (!state.connected && !state.w3aConnected && !state.authenticated) && (
                                    <div className="menu">
                                        <div className="click" onClick={ handleConnect }><i className="fal fa-sign-in"></i> { textStrings?.["header_sign_in"]?.[state.language] }</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="header-middle">
                    <div className="container">
                        <Link to="/" className="logo"><img src="/assets/images/storex_logo.svg" alt="Storex logo"/></Link>

                        <div className="search n420">
                            <div className="select-wrapper">
                                <select className="category n600" name="category" value={ inputs.category || "" } onChange={ handleChange } required>
                                    <option value="All Categories">{ textStrings?.["header_all_categories"]?.[state.language] }</option>
                                    {/* { Object.keys(dummyCategories).filter(category => category != "").map((category, index) => <option key={ index } value={ category }>{ textStrings?.[category]?.[state.language] }</option>) } */}
                                </select>
                            </div>
                            <input type="text" name="search" autoComplete="off" value={ inputs.search || "" } onKeyDown={ handleKeyDown } onChange={ handleChange } placeholder={ textStrings?.["header_search"]?.[state.language] }/>
                            <button onClick={ handleSearch }><i className="fal fa-search"></i></button>
                        </div>

                        <div className="header-middle-right">
                            <div className="deals n830" onClick={ handleDailyDeals }>
                                <div className="click">
                                    <i className="fal fa-tags"></i>

                                    <div className="text-wrapper">
                                        <span>{ textStrings?.["header_weekly"]?.[state.language] }</span>
                                        <p>{ textStrings?.["header_deals"]?.[state.language] }</p>
                                    </div>
                                </div>
                            </div>

                            <span className="divider n830"></span>

                            <div className="label-vertical n630">
                                <WishlistModal/>
                            </div>

                            <div className="label-vertical n630">
                                <CartModal/>
                            </div>

                            <div className="label-vertical mobile b630">
                                <MobileModal web3auth={ state.w3a }/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-bottom">
                    <div className="container">
                        <div className="category-dropdown" onMouseLeave={ handleCategoryLeave }>
                            <div className="click dropdown" onClick={ handleCategoryToggle } onMouseEnter={ handleCategoryEnter }>
                                <i className="fal fa-list"></i>
                                <span>{ textStrings?.["header_browse"]?.[state.language] }</span>
                            </div>

                            <div className="category-wrapper" onMouseLeave={ handleCategoryLeave }>
                                <ul className="category-menu">
                                    { Object.keys(dummyCategories).filter(category => category != "").map((category, index) => <Link key={ index } to={ dummyCategories[category]["url"] } onClick={ handleCategoryClick }><li value={ category } className="click"><i className={ "fal " + (dummyCategories[category]["icon"]) }></i> <span>{ textStrings?.[category]?.[state.language] }</span></li></Link>) }
                                </ul>
                            </div>
                        </div>

                        <div className="top-categories n600">
                            <Link to={ dummyCategories["category_phones_smart_watches"]["url"] + dummyCategories["category_phones_smart_watches"]["subcategories"]["sub_mobile_phones"]["url"] }>
                                <div className="click">
                                    <i className={ "fal " + (dummyCategories["category_phones_smart_watches"]["icon"]) }></i>
                                    <span>{ textStrings?.["sub_mobile_phones"]?.[state.language] }</span>
                                </div>
                            </Link>

                            <Link to={ dummyCategories["category_computers_hardware"]["url"] + dummyCategories["category_computers_hardware"]["subcategories"]["sub_desktop_computers"]["url"] }>
                                <div className="click">
                                    <i className={ "fal " + (dummyCategories["category_computers_hardware"]["icon"]) }></i>
                                    <span>{ textStrings?.["sub_desktop_computers"]?.[state.language] }</span>
                                </div>
                            </Link>

                            <Link to={ dummyCategories["category_health_beauty"]["url"] + dummyCategories["category_health_beauty"]["subcategories"]["sub_vitamins_supplements"]["url"] }>
                                <div className="click">
                                    <i className={ "fal " + (dummyCategories["category_health_beauty"]["icon"]) }></i>
                                    <span>{ textStrings?.["sub_vitamins_supplements"]?.[state.language] }</span>
                                </div>
                            </Link>

                            <Link to={ dummyCategories["category_fashion"]["url"] }>
                                <div className="click">
                                    <i className={ "fal " + (dummyCategories["category_fashion"]["icon"]) }></i>
                                    <span>{ textStrings?.["category_fashion"]?.[state.language] }</span>
                                </div>
                            </Link>

                            <Link className="n768" to={ dummyCategories["category_jewelry_watches"]["url"] }>
                                <div className="click">
                                    <i className={ "fal " + (dummyCategories["category_jewelry_watches"]["icon"]) }></i>
                                    <span>{ textStrings?.["category_jewelry_watches"]?.[state.language] }</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;