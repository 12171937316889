import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, disputeReasonsBuyer, disputeReasonsSeller, maxDescriptionLength, acceptedImageTypes, maxImagesLength, maxImagesSize } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function VoteDisputeModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (state.inputModal?.action != "vote-dispute") return;

        var listing = state.inputModal?.listing;

        setData(values => (
            { ...values, listing: listing }
        ));
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleVote(party, button) {
        if (!party) return;

        var buttonHtml = $(button).html();

        $(button).html("<i class='fad fa-spinner-third'></i> Voting");

        var showSuccess = (txId) => {
            dispatch({
                type: "fetchDisputes",
                value: true
            });

            $("div.error-modal").addClass("success").find("div.text").html("Your vote has been successfully cast</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            $(button).html(buttonHtml);

            $("div.input-modal, section").removeClass("active");
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html(buttonHtml);
        };

        Proton.voteDispute(state, data.listing?.disputeId, party).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    return (
        <section className="vote-dispute">
            <div className="content">
                <div className="form-title"><i className="fal fa-vote-yea"></i> { textStrings["vote_dispute"]?.[state.language] }</div>

                <div className="vote-dispute-wrapper">
                    <div className="dispute-number">
                        <p className="subtitle">Dispute Number</p>
                        <div>{ data.listing?.disputeId }</div>
                    </div>

                    <div className="proposal seller">
                        <p className="subtitle">Seller Proposal</p>

                        {
                            data.listing?.proposals.filter(p => p.proposer == data.listing?.sellerId).map(proposal => (
                                <>
                                    <div className="share">
                                        <p>Seller Receives:</p>
                                        <div>{ parseFloat(proposal.seller_share.quantity.split(" ")[0] / data.listing?.purchasedCurrency.split(" ")[0] * data.listing?.price.split(" ")[0] ).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } ({ proposal.seller_share.quantity })</div>
                                    </div>

                                    <div className="share">
                                    <p>Buyer Receives:</p>
                                    <div>{ parseFloat(proposal.buyer_share.quantity.split(" ")[0] / data.listing?.purchasedCurrency.split(" ")[0] * data.listing?.price.split(" ")[0] ).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } ({ proposal.buyer_share.quantity })</div>
                                </div>
                                </>
                            ))
                        }
                    </div>

                    <div className="proposal buyer">
                        <p className="subtitle">Buyer Proposal</p>

                        {
                            data.listing?.proposals.filter(p => p.proposer == data.listing?.buyerId).map(proposal => (
                                <>
                                    <div className="share">
                                        <p>Buyer Receives:</p>
                                        <div>{ parseFloat(proposal.buyer_share.quantity.split(" ")[0] / data.listing?.purchasedCurrency.split(" ")[0] * data.listing?.price.split(" ")[0] ).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } ({ proposal.buyer_share.quantity })</div>
                                    </div>

                                    <div className="share">
                                    <p>Seller Receives:</p>
                                    <div>{ parseFloat(proposal.seller_share.quantity.split(" ")[0] / data.listing?.purchasedCurrency.split(" ")[0] * data.listing?.price.split(" ")[0] ).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } ({ proposal.seller_share.quantity })</div>
                                </div>
                                </>
                            ))
                        }
                    </div>

                    <div className="notice">By clicking <b>{ textStrings["vote"]?.[state.language] }</b> you certify that you have viewed all available evidence and your vote has not been directly influenced by any of the parties involved and you understand you may subject your account to penalties in the event that your vote is found to be manipulated or influenced by any of the parties involved in the dispute.</div>

                    <div className="buttons">
                        <button className="submit" onClick={ (e) => handleVote(data.listing?.sellerId, e.target) }>Vote Seller Proposal</button>
                        <button className="submit" onClick={ (e) => handleVote(data.listing?.buyerId, e.target) }>Vote Buyer Proposal</button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VoteDisputeModal;