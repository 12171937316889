import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQRCode } from 'next-qrcode';
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, kycStatus } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function KYCModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    const { Canvas } = useQRCode();

    useEffect(() => {
        if (state.inputModal?.action != "kyc") return;

        var jwt = state.inputModal?.data;

        setData(values => (
            { ...values, jwt: jwt }
        ));

        window.kycInterval = setInterval(() => {
            console.log("checking kyc status");

            // check kyc status
            Proton.metalKYCStatus(jwt).then(res => {
                var { features, status } = res;

                if (status != kycStatus.UNSUBMITTED) {
                    dispatch({
                        type: "kycSubmitted",
                        value: true
                    });

                    dispatch({
                        type: "closeInputModal",
                        value: Date.now()
                    });
        
                    $("div.input-modal, section").removeClass("active");
                };
            }).catch(err => {
                var { error, message } = err.responseJSON || { message: err };
    
                console.log(message, error);
            });
        }, 10000);
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;

        clearInterval(window.kycInterval);
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        setInputs(values => ({ 
            ...values, 
            [name]: value 
        }));
    };

    function handleConfirm(event) {
        event.preventDefault();

        $("button.confirm").html("<i class='fad fa-spinner-third'></i> Submitting").attr("disabled", true);

        var handleSuccess = (res) => {
            setData(values => ({ 
                ...values, 
                url: res.url
            }));

            $("button.confirm").html(textStrings["submit"]?.[state.language]).attr("disabled", false);
        };

        var handleError = (message, error) => {
            $("div.error-modal").addClass("error").find("div.text").html(message || "There was an error authenticating your request, please try again");

            $("button.confirm").html(textStrings["submit"]?.[state.language]).attr("disabled", false);
        };

        Proton.metalKYC(data.jwt, inputs.addressLine1, inputs.addressLine2, inputs.cityTown, inputs.stateProvince, inputs.postalCode, inputs.country).then(res => {
            handleSuccess(res);
        }).catch(err => {
            var { error, message } = err.responseJSON || { message: err };

            handleError(message, error);
        });
    };

    return (
        <section className="kyc">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-id-card"></i> { textStrings["account_identity_verification"]?.[state.language] }</div>

                <div className="kyc-wrapper">
                    {
                        (!data.url) && (
                            <form onSubmit={ handleConfirm }>
                                <div className="input-group">
                                    <label htmlFor="addressLine1">Address Line 1</label>
                                    <input type="text" name="addressLine1" autoComplete="address-line1" value={ inputs.addressLine1 || "" } onChange={ handleChange } required/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="addressLine2">Address Line 2 <span className="optional">Optional</span></label>
                                    <input type="text" name="addressLine2" autoComplete="address-line2" value={ inputs.addressLine2 || "" } onChange={ handleChange }/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="cityTown">City/Town</label>
                                    <input type="text" name="cityTown" autoComplete="address-level2" value={ inputs.cityTown || "" } onChange={ handleChange } required/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="stateProvince">State/Province</label>
                                    <input type="text" name="stateProvince" autoComplete="address-level1" value={ inputs.stateProvince || "" } onChange={ handleChange } required/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="postalCode">Postal Code</label>
                                    <input type="text" name="postalCode" autoComplete="postal-code" value={ inputs.postalCode || "" } onChange={ handleChange } required/>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="country">Country</label>
                                    <i className="fal fa-chevron-down"></i>
                                    <select className="country" name="country" value={ inputs.country || "" } onChange={ handleChange } required>
                                        <option value="" style={{ display: "none" }}></option>

                                        {
                                            countryCodes.map((country, index) => (
                                                <option key={ index } value={ country.countryCode }>{ country.name }</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <button type="submit" className="submit confirm">{ textStrings["submit"][state.language] }</button>
                            </form>
                        )
                    }

                    {
                        (data.url) && (
                            <>
                                <div className="qr-wrapper">
                                    <Canvas
                                        text={ data.url }
                                        options={{
                                            errorCorrectionLevel: "M",
                                            margin: 3,
                                            scale: 4,
                                            color: {
                                                dark: "#000000",
                                                light: "#FFFFFF",
                                            },
                                        }}
                                    />
                                </div>

                                <a href={ data.url } target="_blank" className="mobile-link">Open in new tab</a>

                                <div className="subtitle">Next Steps</div>

                                <ul className="instructions">
                                    <li>- Scan the QR code with a mobile device camera</li>
                                    <li>- Upload a picture of your identity documents</li>
                                    <li>- Take a selfie with your mobile device camera</li>
                                </ul>
                            </>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default KYCModal;