import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { dummyCategories } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

function Sidebar() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({ newsletter: "" });
    const [data, setData] = useState({ categories: {} });
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        $(".sidebar-wrapper").children("[href='" + window.location.pathname + "']").addClass("active");
    }, []);

    function handleLogout() {
        if (!state.link || !state.session) return;

        state.link.removeSession("Storex", state.session.auth).then(res => {
            dispatch({
                type: "setAuth",
                value: null
            });

            dispatch({
                type: "setWallet",
                value: null
            });
        });
    };

    return (
        <div className="sidebar-wrapper n600">
            <Link to="/account">Dashboard</Link>
            <Link to="/account/settings">Settings</Link>
            <Link to="/account/wallet">Wallet</Link>
            <Link to="/account/addresses">Addresses</Link>
            <Link to="/account/orders">Order History</Link>
            <Link to="/account/listings">Active Listings</Link>
            <Link to="/account/sales">Sales</Link>
            <Link to={ "/profile/" + state.session.auth.actor }>Storefront</Link>
            <Link to="/account/staking">Staking</Link>
            <Link to="/account/arbitration">Arbitration</Link>
            <Link to="/account/vouchers">Vouchers</Link>
            <Link to="/" onClick={ handleLogout }>Sign Out</Link>
        </div>
    );
};

export default Sidebar;
