import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQRCode } from 'next-qrcode';
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, disputeReasonsBuyer, disputeReasonsSeller, maxDescriptionLength, acceptedImageTypes, maxImagesLength, maxImagesSize } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function DisputeModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (state.inputModal?.action != "create-dispute") return;

        var { listing, button } = state.inputModal?.data;

        setData(values => (
            { ...values, listing: listing, button: button }
        ));
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;

        $(data.button).html(textStrings["dispute"]?.[state.language]).attr("disabled", false);
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    useEffect(() => {
        $("textarea").each(function() {
            this.setAttribute("style", "height:" + this.scrollHeight + "px; overflow-y: hidden");
        }).on("input", function() {
            this.style.height = 0;
            this.style.height = this.scrollHeight + "px";
        });
    }, [inputs.description]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (name == "amount") value = value.replace(/\D\./g, "");

        if (name == "description") {
            value = encodeURIComponent(value);
        };

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleChangeRefund(event) {
        const [addressType, name] = event.target.name.split("-");
        var value = event.target.value;

        if (name == "amount") {
            value = value.replace(/[^0-9.]/g, "");
        };
    
        setInputs(values => (
            { ...values, [addressType]: { ...values[addressType], [name]: value } }
        ));
    };

    function handleImageChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const files = event.target.files;

        if (files?.length + inputs?.images?.length > maxImagesLength) {
            // add the files that aren't over the limit to the inputs list

            $("label[for='images'], span.images-left").addClass("limit");

            setTimeout(() => {
                $("label[for='images'], span.images-left").removeClass("limit");
            }, 1000);

            return event.preventDefault();
        };

        var validSize = Object.keys(files).every(file => files[file].size <= maxImagesSize);

        if (!validSize) {
            alert("You cannot upload files larger than " + Math.floor(maxImagesSize / 1000000) + "MB");
            // have the max size text flash red

            return event.preventDefault();
        };

        var validFormat = Object.keys(files).forEach(file => console.log(files[file]));

        var validFormat = Object.keys(files).every(file => acceptedImageTypes.includes(files[file].type));

        if (!validFormat) {
            alert("You can only upload PDF, JPEG, JPG, PNG, WEBP");

            return event.preventDefault();
        };

        var photoArr = inputs.images || [];

        var thumbnailImg = photoArr.length < 1 ? files[0] : inputs.thumbnail;

        Object.keys(files).forEach(file => photoArr.push(files[file]));

        setInputs(values => (
            { ...values, [name]: photoArr, thumbnail: thumbnailImg }
        ));
    };

    function handleRemoveImage(index) {
        var imagesCopy = inputs.images;

        imagesCopy.splice(index, 1);

        setInputs(values => (
            { ...values, images: imagesCopy, thumbnail: imagesCopy[0] }
        ));
    };

    function handleConfirm(event) {
        event.preventDefault();

        $("button.confirm").html("<i class='fad fa-spinner-third'></i> Submitting").attr("disabled", true);

        var showSuccess = (res) => {
            $("div.input-modal, section").removeClass("active");

            setInputs({});

            $("button.confirm").html(textStrings["submit"]?.[state.language]).attr("disabled", false);

            navigate("/account/arbitration");
        };

        var showError = (message, error) => {
            $("div.error-modal").addClass("error").find("div.text").html(message || "There was an error authenticating your request, please try again");

            $("button.confirm").html(textStrings["submit"]?.[state.language]).attr("disabled", false);
        };

        var refundString = ((inputs.refundOrder?.amount || 0) / ((parseFloat(data.listing?.purchasedVariation.price) * data.listing?.purchasedQuantity) + parseFloat(data.listing?.purchasedShipping || 0)) * data.listing?.purchasedCurrency?.split(" ")[0]).toFixed(data.listing?.purchasedCurrency?.split(" ")[0]?.split(".")[1].length) + " " + data.listing?.purchasedCurrency?.split(" ")[1];
        var refundAsset = data.listing?.purchasedCurrency?.split(" ")[1];
        var refundContract = data.listing?.currencies.filter(c => c.name == refundAsset)[0]?.contract;
        
        Proton.startDispute(state, data.listing?.orderNumber, (data.listing?.sellerId == state.session.auth.actor) ? true : false, { "quantity": refundString, "contract": refundContract }, inputs.reason).then(tx => {
            Listing.startDispute(state.session.auth.actor, data.listing?.orderNumber, (tx.serializedTransaction || tx.resolved.serializedTransaction), tx.signatures, tx.signer || {"actor":state.session.auth.actor,"permission":"active"}, tx.resolvedTransaction || {}, inputs?.images, inputs?.description).then(res => {
                showSuccess(res);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                if (message?.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.startDispute(state.session.auth.actor, data.listing?.orderNumber, (tx.serializedTransaction || tx.resolved.serializedTransaction), tx.signatures, tx.signer || {"actor":state.session.auth.actor,"permission":"active"}, tx.resolvedTransaction || {}, inputs?.images, inputs?.description).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };
    
                            showError(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
                        navigate("/");
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    return (
        <section className="create-dispute">
            <div className="content">
                <div className="form-title"><i className="fal fa-gavel"></i> { textStrings["create_dispute"]?.[state.language] }</div>

                <div className="create-dispute-wrapper">
                    <form onSubmit={ handleConfirm }>
                        <div className="dispute-summary">
                            <div>
                                <span>Order Number</span>
                                <div>{ data.listing?.orderNumber }</div>
                            </div>

                            <div>
                                <span>Order Date</span>
                                <div>{ new Date(data.listing?.createdAt).toLocaleDateString() }</div>
                            </div>

                            <div>
                                <span>Counterparty</span>
                                <div>{ data.listing?.sellerId }</div>
                            </div>

                            <div>
                                <span>Order Total</span>
                                <div>{ ((parseFloat(data.listing?.purchasedVariation.price) * data.listing?.purchasedQuantity) + parseFloat(data.listing?.purchasedShipping || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                            </div>
                        </div>

                        <div className="input-group">
                            <label htmlFor="orderTotal">Dispute Reason</label>
                            <i className="fal fa-chevron-down"></i>
                            <select className="reason" name="reason" value={ inputs.reason || "" } onChange={ handleChange } required>
                                <option value="" style={{ display: "none" }}></option>

                                {
                                    Object.keys(data.listing?.sellerId == state.session.auth.actor ? disputeReasonsSeller : disputeReasonsBuyer).map((id, index) => (
                                        <option key={ index } value={ id }>{ (data.listing?.sellerId == state.session.auth.actor ? disputeReasonsSeller : disputeReasonsBuyer)[id] }</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="input-group">
                            <i className="fal fa-dollar-sign"></i>
                            <label htmlFor="refundOrder-amount">Expected Refund</label>
                            <input type="number" name="refundOrder-amount" value={ inputs?.refundOrder?.amount || "" } max={ (parseFloat(data.listing?.purchasedVariation.price) * data.listing?.purchasedQuantity) + parseFloat(data.listing?.purchasedShipping || 0) || 0 } onChange={ handleChangeRefund } required/>
                            <div className="crypto-amount">{ (((inputs.refundOrder?.amount || 0) / ((parseFloat(data.listing?.purchasedVariation.price) * data.listing?.purchasedQuantity) + parseFloat(data.listing?.purchasedShipping)) * data.listing?.purchasedCurrency?.split(" ")[0]) || 0).toFixed(data.listing?.purchasedCurrency?.split(" ")[0]?.split(".")[1].length) + " " + data.listing?.purchasedCurrency?.split(" ")[1] }</div>
                        </div>

                        <div className="input-group images">
                                <label>Evidence</label>

                                <div className="image-buttons">
                                    <label htmlFor="images"><i className="fal fa-images"></i> Upload Evidence</label>
                                </div>

                                <input type="file" name="images" id="images" onChange={ handleImageChange } accept=".pdf,.jpg,.jpeg,.png,.webp" multiple/>

                                <div className={ "image-previews" + (inputs.images?.length > 0 ? "" : " empty") }>
                                    {
                                        inputs.images?.map((image, index) => (
                                            <div key={ index } className="image-wrapper">
                                                {
                                                    (image.type == "application/pdf") && (
                                                        <>
                                                            <i className="fal fa-file-pdf"></i>
                                                            <span>{ image.name }</span>
                                                        </>
                                                    )
                                                }

                                                {
                                                    (image.type != "application/pdf") && (
                                                        <img src={ URL.createObjectURL(image) }/>
                                                    )
                                                }

                                                <i className="fal fa-times click" onClick={ () => handleRemoveImage(index) }></i>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        <div className="input-group">
                            <label htmlFor="description">Additional Notes</label>

                            <textarea type="text" name="description" autoComplete="none" maxLength={ maxDescriptionLength } value={ decodeURIComponent(inputs.description || "") } onChange={ handleChange }/>

                            <span className="chars-left">{ (inputs.description?.length || 0) + "/" + maxDescriptionLength + " characters" }</span>
                        </div>

                        <div className="notice">By clicking <b>{ textStrings["submit"][state.language] }</b> you certify that the above information is true and accurate to the best of your knowledge and you understand you may subject your account to penalties in the event that the above information is found to be falsified.</div>

                        <button type="submit" className="submit confirm">{ textStrings["submit"][state.language] }</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default DisputeModal;