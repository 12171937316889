import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, regionCodesDictionary, countryCodesDictionary, supportedCurrencies, networkMemos } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function PrivateKeyModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (!state.inputModal) return;
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;

        setInputs(values => ({
            ...values,
            confirmed: false
        }));
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleConfirm(event) {
        event.preventDefault();

        setInputs(values => ({
            ...values,
            confirmed: true
        }));
    };

    return (
        <section className="private-key">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-key"></i> { textStrings["export_pk"]?.[state.language] }</div>

                <div className="export-wrapper">
                    <form onSubmit={ handleConfirm }>
                        <div className="notice">Sharing your private key with anyone will compromise the security of your account and provide them full control and access to your account and assets on XPR Network.</div>

                        <div className="notice"><span>Storex and XPR Network employees will never ask you for your private key.</span></div>

                        <div className="notice">By clicking the <b>Confirm</b> button you accept the risk of exporting your private key and agree not to hold Storex or XPR Network liable for the potential loss of any assets.</div>

                        {
                            (inputs.confirmed) && (
                                <div className="pk">{ inputs.confirmed ? state.w3aPrivateKey : "" }</div>
                            )
                        }

                        <button type="submit" className="submit confirm">{ textStrings["confirm"][state.language] }</button>
                    </form>
                </div>
            
            </div>
        </section>
    );
};

export default PrivateKeyModal;