import { React, useState, useEffect, useMemo, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import * as CryptoJS from 'crypto-js';

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyListings, maxActiveListings } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";

import Sidebar from "components/account/sidebar";

function Sales() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ selectedActivePage: 0, selectedSalesPage: 0 });
    const [data, setData] = useState({ categories: dummyCategories, stats: { sold30d: 0, count30d: 0, soldAll: 0, countAll: 0 }, rawListings: [], listings: [], rawSales: [] });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        const max = -219.99078369140625;

        $(".progress").each((index, value) => {
            var percent = (value.getAttribute("data-progress") > 100) ? 100 : value.getAttribute("data-progress");

            value.querySelector(".fill").setAttribute("style", "stroke-dashoffset: " + ((100 - percent) / 100) * max);
        });
    }, [data]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/selling"
            });
            
            return navigate("/");
        };

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    useEffect(() => {
        if (state.fetchSellingHistory) return handleDataFetch();
    }, [state.fetchSellingHistory]);

    function handleDataFetch() {
        var successSelling = (res) => {
            setData(values => (
                { ...values, rawListings: res.reverse().filter(l => l.active), listings: res.filter(l => l.active).slice(inputs.selectedActivePage * 10, (inputs.selectedActivePage * 10) + 10) }
            ));
        };

        var successSales = (res) => {
            setData(values => (
                { ...values, stats: { ...values.stats, count30d: res.length, sold30d: res.reduce((a, b) => a = a + parseFloat(b.price), 0), countAll: res.length, soldAll: res.reduce((a, b) => a = a + parseFloat(b.price), 0) }, rawSales: res.reverse(), sales: res.slice(inputs.selectedSalesPage * 10, (inputs.selectedSalesPage * 10) + 10) }
            ));
        };

        var error = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error authenticating your request, please try again");
        };

        Account.fetchSellerListings(state.session.auth.actor).then(res => {
            successSelling(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchSellerListings(state.session.auth.actor).then(res => {
                        successSelling(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        error(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                error(message);
            };
        });

        Account.fetchSalesListings(state.session.auth.actor).then(res => {
            successSales(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchSalesListings(state.session.auth.actor).then(res => {
                        successSales(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        error(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                error(message);
            };
        });
    };

    function handleRating(rating) {
        var classObject = {};
        var remainder = (rating % 1).toFixed(1);

        for (var i = 0; i < Math.floor(rating); i++) {
            classObject[i] = "fas fa-star fill";
        };

        if (remainder > 0 && remainder < 0.3) {
            classObject[Math.floor(rating)] = "fas fa-star";
        } else if (remainder >= 0.3 && remainder < 0.8) {
            classObject[Math.floor(rating)] = "fad fa-star-half";
        } else if (remainder >= 0.8) {
            classObject[Math.floor(rating)] = "fas fa-star";
        };

        for (var i = 0; i < 5; i++) {
            if (!classObject[i]) classObject[i] = "fas fa-star";
        };

        return (
            <div>
                <i className={ classObject[0] }></i>
                <i className={ classObject[1] }></i>
                <i className={ classObject[2] }></i>
                <i className={ classObject[3] }></i>
                <i className={ classObject[4] }></i>
            </div>
        );
    };

    function endListing(listingId, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Ending");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Listing has been successfully ended");

            $(button).html(textStrings["end_listing"][state.language]);

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error authenticating your request, please try again");

            $(button).html(textStrings["end_listing"][state.language]);
        };

        Proton.endListing(state.session, listingId).then(tx => {
            if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

            var txId = tx.processed?.id || "";

            Listing.endListing(state.session.auth.actor, listingId, txId).then(res => {
                showSuccess(res);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.endListing(state.session.auth.actor, listingId, txId).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };
    
                            showError(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
                        navigate("/");
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err);
        });
    };

    function handleOrderDetails(listing) {
        $("div.input-modal").addClass("active").find("section.order-details").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "order-details",
                listing: listing
            }
        });
    };

    function handleRefund(listing) {
        $("div.input-modal").addClass("active").find("section.refund-order").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "refund-order",
                listing: listing
            }
        });
    };

    function handleDispatch(orderNumber, dispatched, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Updating");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Order dispatch status has been updated");

            $(button).html(textStrings[!dispatched ? "mark_dispatch" : "unmark_dispatch"][state.language]);

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html(textStrings[dispatched ? "mark_dispatch" : "unmark_dispatch"][state.language]);
        };

        Listing.markDispatch(state.session.auth.actor, orderNumber, dispatched).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Listing.markDispatch(state.session.auth.actor, orderNumber, dispatched).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                showError(message);
            };
        });
    };

    function handleClaimFunds(orderNumber, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Claiming");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Order payment has been successfully claimed");

            $(button).html(textStrings["claim_funds"][state.language]);

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html(textStrings["claim_funds"][state.language]);
        };

        Proton.claimFunds(state, orderNumber).then(tx => {
            if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

            var txId = tx.processed?.id || "";

            Listing.claimFunds(state.session.auth.actor, orderNumber, txId).then(res => {
                showSuccess(res);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.claimFunds(state.session.auth.actor, orderNumber, txId).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };
    
                            showError(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
                        navigate("/");
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleClaimAll(event) {
        $(event.target).html("<i class='fad fa-spinner-third'></i> Claiming");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Order payment has been successfully claimed");

            $(event.target).html("Claim All Funds");

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(event.target).html("Claim All Funds");
        };

        // Proton.claimFunds(state, orderNumber).then(tx => {
        //     if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

        //     var txId = tx.processed?.id || "";

        //     Listing.claimFunds(state.session.auth.actor, orderNumber, txId).then(res => {
        //         showSuccess(res);
        //     }).catch(err => {
        //         var { message } = err.responseJSON || { message: err };
    
        //         if (message.indexOf("Missing Cookie") != -1) {
        //             Account.requestToken(state.session.auth.actor).then(res => {
        //                 Listing.claimFunds(state.session.auth.actor, orderNumber, txId).then(res => {
        //                     showSuccess(res);
        //                 }).catch(err => {
        //                     var { message } = err.responseJSON || { message: err };
    
        //                     showError(message);
        //                 });
        //             }).catch(err => {
        //                 $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
        //                 navigate("/");
        //             });
        //         } else {
        //             showError(message);
        //         };
        //     });
        // }).catch(err => {
        //     showError(err.error?.details?.[0]?.message || err);
        // });
    };

    function handleActivePage(index) {
        setInputs(values => (
            { ...values, selectedActivePage: index }
        ));

        setData(values => (
            { ...values, listings: data.rawListings.slice(index * 10, (index + 1) * 10) }
        ));

        $("body, html").animate({ scrollTop: $(".active-listings").offset().top - 24 }, 0);
    };

    function handleSalesPage(index) {
        setInputs(values => (
            { ...values, selectedSalesPage: index }
        ));

        setData(values => (
            { ...values, sales: data.rawSales.slice(index * 10, (index + 1) * 10) }
        ));

        $("body, html").animate({ scrollTop: $(".selling-history").offset().top - 24 }, 0);
    };

    function sellSimilar(listing) {
        navigate("/create-listing");

        dispatch({
            type: "setCreateListing",
            value: listing
        });
    };

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><p>Home</p></Link>
                    <span>></span>
                    <Link to="/account"><p>{ textStrings["my_account"][state.language] }</p></Link>
                    <span>></span>
                    <Link to="/account/selling"><p>{ textStrings["account_selling"][state.language] }</p></Link>
                </div>

                <div className="account-wrapper">
                    <Sidebar></Sidebar>

                    <div className="content-wrapper">
                        <section className="summary">
                            <div className="content-title"><i className="fal fa-chart-bar"></i> { textStrings["account_summary"][state.language] }</div>

                            <div className="overview-wrapper">
                                <span>
                                    <div className="subtitle">Revenue (30d)</div>
                                    <p>{ parseFloat(data.stats?.sold30d).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</p>
                                </span>

                                <span>
                                    <div className="subtitle">Revenue (All)</div>
                                    <p>{ parseFloat(data.stats?.soldAll).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</p>
                                </span>

                                <span>
                                    <div className="subtitle">Sales (Total)</div>
                                    <p>{ data.stats.countAll }</p>
                                </span>
                            </div>
                        </section>

                        <section className="selling-history" style={{display: "block"}}>
                            <div className="content-title"><i className="fal fa-box-usd"></i> { textStrings["account_selling_history"][state.language] }</div>

                            {
                                /*
                                <div className="mass-buttons">
                                    <button className="claim-all" onClick={ handleClaimAll }>Claim All Funds</button>
                                </div>
                                */
                            }

                            <div className="active-wrapper">
                                <ul>
                                    {
                                        (data.sales == null) && (
                                            <div className="loading">
                                                <i className="fad fa-spinner-third"></i>

                                                <span>Fetching sales</span>
                                            </div>
                                        )
                                    }

                                    {
                                        (data.sales?.length < 1) && (
                                            <>
                                                <div key={ 1 } className="empty">You haven't sold any listings in the past 30 days</div>
                                                <span key={ 2 } className="empty"><Link to="/account/ads">Promote a listing</Link> to boost impressions</span>
                                            </>
                                        )
                                    }

                                    {
                                        data.sales?.map((listing, index) => (
                                            <Fragment key={ index }>
                                                <div className="sale-overview">
                                                    <div className="stat">
                                                        <div className="subtitle">Order Date</div>
                                                        <div className="value">{ new Date(listing.createdAt).toLocaleDateString() }, { new Date(listing.createdAt).toLocaleTimeString().slice(0, 5) }</div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Order Number</div>
                                                        <div className="value">{ listing.orderNumber }</div>
                                                    </div>
                                                    <div className="stat n900">
                                                        <div className="subtitle">Transaction ID</div>
                                                        <div className="value"><a href={ "https://explorer.xprnetwork.org/transaction/" + listing.txId } target="_blank">{ listing.txId }</a></div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Buyer</div>
                                                        <div className="value"><Link to={ "/profile/" + listing.buyerId }>{ listing.buyerId }</Link> <Link to={ "/messages/" + listing.buyerId }><i className="fal fa-envelope message"></i></Link></div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Order Total</div>
                                                        <div className="value">
                                                            { ((parseFloat(listing.purchasedVariation.price) * listing.purchasedQuantity) + parseFloat(listing.purchasedShipping || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }
                                                            {
                                                                (listing.price != listing.purchasedVariation.price) && (
                                                                    <i className="fal fa-redo" title={ "$" + (listing.price.split(" ")[0] - listing.purchasedVariation.price.split(" ")[0]).toFixed(2) + " has been refunded" }></i>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <li>
                                                    <Link to={ "/listing/" + CryptoJS.MD5(listing.listingId).toString() }>
                                                        <div className="thumbnail-wrapper">
                                                            <img src={ listing.thumbnail } alt={ listing.title } />
                                                        </div>
                                                    </Link>

                                                    <div className="info">
                                                        <Link to={ data.categories[listing.category]["url"] + data.categories[listing.category]["subcategories"][listing.subcategory]["url"] }><div className="subcategory">{ textStrings[listing.subcategory.replace("-", "_")][state.language] }</div></Link>
                                                        <Link to={ "/listing/" + CryptoJS.MD5(listing.listingId).toString() }><div className="title">{ listing.title }</div></Link>
                                                        <div className="condition">{ listing.condition }</div>
                                                        {/*<div className="reviews" title={ listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing.feedback?.length || 0 }>{ handleRating(listing?.feedback?.reduce((a, b) => a + b.rating, 0) / listing.feedback?.length) } <span>{ "(" + (listing.feedback?.length || 0) + " ratings)" }</span></div>*/}
                                                        <div className="price">{ parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } <span>({ listing.purchasedCurrency })</span></div>
                                                        <div className="options">
                                                            {
                                                                (listing.purchasedVariation.option0) && (
                                                                    <span>{ listing.purchasedVariation.option0 }</span>
                                                                )
                                                            }
                                                            {
                                                                (listing.purchasedVariation.option1) && (
                                                                    <span> / { listing.purchasedVariation.option1 }</span>
                                                                )
                                                            }
                                                            {
                                                                (listing.purchasedVariation.option2) && (
                                                                    <span> / { listing.purchasedVariation.option2 }</span>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="status-icons">
                                                            <div className="icon">
                                                                <span>Paid</span>
                                                                <i className="fas fa-money-bill-wave active"></i>
                                                            </div>

                                                            <div className="icon">
                                                                <span>Shipped</span>
                                                                <i className={ "fas fa-truck-loading" + (listing.dispatched ? " active" : "") }></i>
                                                            </div>

                                                            {
                                                                (listing.disputed) && (
                                                                    <div className="icon">
                                                                        <span>Disputed</span>
                                                                        <i className="fas fa-gavel alert"></i>
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                            {
                                                                (!listing.disputed) && (
                                                                    <div className="icon">
                                                                        <span>Received</span>
                                                                        <i className={ "fas fa-box-check" + (listing.completed ? " active" : "") }></i>
                                                                    </div>
                                                                )
                                                            }

                                                            <div className="icon">
                                                                <span>Claimed</span>
                                                                <i className={ "fas fa-wallet" + (listing.claimed ? " active" : "") }></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="actions">
                                                        <button type="submit" onClick={ e => handleOrderDetails(listing) }>View Order Details</button>
                                                        {
                                                            (!listing.disputed && !listing.completed && !listing.claimed) && (
                                                                <button type="submit" onClick={ e => handleDispatch(listing.orderNumber, !listing.dispatched, e.target) }>{ listing.dispatched ? textStrings["unmark_dispatch"][state.language] : textStrings["mark_dispatch"][state.language] }</button>
                                                            )
                                                        }
                                                        {
                                                            (!listing.disputed) && (
                                                                <button type="submit" onClick={ e => handleRefund(listing) }>Refund</button>
                                                            )
                                                        }
                                                        {
                                                            (!listing.disputed && !listing.claimed) && (
                                                                <button type="submit" onClick={ e => handleClaimFunds(listing.orderNumber, e.target) }>Claim Funds</button>
                                                            )
                                                        }
                                                        {
                                                            (listing.disputed) && (
                                                                <Link to="/account/arbitration"><button type="submit">View Dispute</button></Link>
                                                            )
                                                        }
                                                    </div>
                                                </li>
                                            </Fragment>
                                        ))
                                    }

                                    <div className="pages">
                                        {
                                            (data.rawSales.length > 60 && inputs.selectedSalesPage > 3) && (
                                                <i onClick={ () => handleSalesPage(0) } className="fal fa-chevron-double-left"></i>
                                            )
                                        }

                                        {
                                            (data.rawSales.length > 60) && (
                                                [...Array(Math.ceil(data.rawSales.length / 10))].map((i, index) => (
                                                    (index - inputs.selectedSalesPage <= 3 && index - inputs.selectedSalesPage >= -3) ? <button className={ inputs.selectedSalesPage == index ? "active" : "" } onClick={ () => handleSalesPage(index) }>{ index + 1 }</button> : ""
                                                ))
                                            )
                                        }

                                        {
                                            (data.rawSales.length > 60 && inputs.selectedSalesPage < Math.ceil(data.rawSales.length / 10)-4) && (
                                                <i onClick={ () => handleSalesPage(Math.ceil(data.rawSales.length / 10)-1) } className="fal fa-chevron-double-right"></i>
                                            )
                                        }

                                        {
                                            (data.rawSales.length < 60) && (
                                                [...Array(Math.ceil(data.rawSales.length / 10))].map((i, index) => (
                                                    <button className={ inputs.selectedSalesPage == index ? "active" : "" } onClick={ () => handleSalesPage(index) }>{ index + 1 }</button>
                                                ))
                                            )
                                        }
                                    </div>
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Sales;
