import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import { useQRCode } from "next-qrcode";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, regionCodesDictionary, countryCodesDictionary, supportedCurrencies, networkMemos } from "constants";
import { isObjectEmpty } from "functions"
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";

function TradeModal() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ pools: [], inputCurrency: {}, outputCurrency: supportedCurrencies[0], inputQuantity: 0, outputQuantity: 0, priceImpact: 0, inputRoute: "STRX > XUSDC", outputLoading: false });
    const [data, setData] = useState({ balances: { STRX: 0, XPR: 0, BTC: 0, ETH: 0, DOGE: 0, METAL: 0, LOAN: 0, USDC: 0, USDT: 0, XMD: 0, SNIPS: 0, FOOBAR: 0 }, prices: { STRX: 0, XPR: 0, BTC: 0, ETH: 0, DOGE: 0, METAL: 0, LOAN: 0, USDC: 0, USDT: 0, XMD: 0, SNIPS: 0, FOOBAR: 0 } });

    useEffect(() => {
        if (!state.inputModal) return;

        if (state.inputModal.action == "trade") {
            setInputs(values => (
                { ...values, inputCurrency: state.inputModal.data.currency, outputCurrency: (state.inputModal.data.currency == supportedCurrencies[0]) ? supportedCurrencies[7] : supportedCurrencies[0], inputQuantity: 0, outputQuantity: 0, selectInput: false, selectOutput: false }
            ));

            setData(values => (
                { ...values, balances: state.inputModal.data.balances, prices: state.inputModal.data.prices }
            ));

            Proton.fetchPools().then(res1 => {
                setInputs(values => (
                    { ...values, pools: res1.rows }
                ));
            });

            Proton.isKycVerified(state.session.auth.actor).then(res => {
                setData(values => (
                    { ...values, kyc: res }
                ));
            });
        };
    }, [state.inputModal]);

    useEffect(() => {
        if (isObjectEmpty(inputs.inputCurrency) || isObjectEmpty(inputs.outputCurrency)) return;

        calculateTrade(inputs.inputCurrency, inputs.outputCurrency, inputs.inputQuantity);
    }, [inputs.inputCurrency, inputs.outputCurrency, inputs.inputQuantity]);

    useEffect(() => {
        if (state.fetchWalletBalances) {
            setTimeout(() => {
                return handleDataFetch();
            }, 3000);
        };
    }, [state.fetchWalletBalances]);

    function handleDataFetch() {
        var balances = {};
        var prices = {};
        var oracles = {};

        Proton.fetchOracles().then(res => {
            var oracleArr = res?.rows || [];

            oracleArr.forEach((feed, index) => {
                var feedIndex = feed?.feed_index || 0;
                var feedPrice = feed?.aggregate?.d_double || 0;

                oracles[feedIndex] = feedPrice;

                if (index == oracleArr.length-1) {
                    supportedCurrencies.forEach((currency, index) => {
                        Proton.fetchBalance(state.session.auth.actor, currency).then(res => {
                            const [balanceStr] = res;
                            const [balance, ticker] = (balanceStr || "").split(" ");
            
                            balances[currency.ticker] = balance || "0";

                            prices[currency.ticker] = oracles[currency.feedIndex];

                            if (index == supportedCurrencies.length-1) {
                                Proton.fetchStorexOracles().then(res => {
                                    var oracleArr = res?.rows || [];
                        
                                    oracleArr.forEach((feed, index) => {
                                        const {sym, quantity} = feed;
                                        const [, ticker] = sym.split(",");
                                        const [amount] = quantity.split(" ");
                        
                                        prices[ticker] = amount;

                                        if (index == oracleArr.length-1) {
                                            setData(values => (
                                                { ...values, balances: balances, prices: prices }
                                            ));
                                        };
                                    });
                                }).catch(err => {
                                    console.log(err);
                        
                                    setTimeout(() => {
                                        handleDataFetch();
                                    }, 3000);
                                });
                            };
                        }).catch(err => {
                            console.log(err);
                
                            setTimeout(() => {
                                handleDataFetch();
                            }, 3000);
                        });
                    });
                };
            });
        }).catch(err => {
            console.log(err);

            setTimeout(() => {
                handleDataFetch();
            }, 3000);
        });
    };

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (name.includes("Quantity")) {
            var [, decimals] = value.split(".");

            if (decimals?.length > inputs.inputCurrency.precision) return;

            value = value.replace(/[^0-9.]/g, "");
        };

        setInputs(values => (
            { ...values, [name]: value }
        ));

        $(".form-alert").removeClass("active");
    };

    async function calculateTrade(inputCurrency, outputCurrency, inputQuantity) {
        if (inputQuantity == 0) {
           return setInputs(values => (
                { ...values, outputQuantity: 0 }
            )); 
        };

        var outputCalcTime = Date.now();

        setInputs(values => (
            { ...values, outputLoading: true }
        ));

        var bestMinimumReceived = 0;
        var bestMemo = "";
        var bestRoute = [];

        if (outputCurrency.trade.includes("alcor")) {
            // if outputCurrency is only on alcor don't check metalx routes
            console.log("checking alcor");

            try {
                const response = await fetch("https://proton.alcor.exchange/api/v2/swapRouter/getRoute?" + new URLSearchParams({
                    trade_type: "EXACT_INPUT",
                    input: inputCurrency.ticker.toLowerCase() + "-" + inputCurrency.contract.toLowerCase(),
                    output: outputCurrency.ticker.toLowerCase() + "-" + outputCurrency.contract.toLowerCase(),
                    amount: inputQuantity,
                    receiver: state.session.auth.actor
                }));
        
                const { memo, minReceived, route } = await response.json();

                if (minReceived >= bestMinimumReceived) {
                    bestMinimumReceived = minReceived;
                    bestMemo = memo;
                    bestRoute = route;
                };
            } catch(error) {
                console.error(error);
            };
        };
        
        if (outputCurrency.trade.includes("metalx")) {
            // if outputCurrency is on metalx check multi-dex route
            console.log("checking metalx");

            var viableRoutes = [];

            function routeFound(route, liq) {
                var routes = route.split(",");
        
                var inputPool = routes[0];
                var outputPool = routes[routes.length-1];
        
                var inputPoolInfo = poolArr.filter(p => {
                    return p.sym == inputPool;
                })[0];
        
                var outputPoolInfo = poolArr.filter(p => {
                    return p.sym == outputPool;
                })[0];
        
                var inputLiq = (inputPoolInfo.tokenA == inputCurrency.ticker) ? inputPoolInfo.tokenALiq : inputPoolInfo.tokenBLiq;
                var outputLiq = (outputPoolInfo.tokenA == outputCurrency.ticker) ? outputPoolInfo.tokenALiq : outputPoolInfo.tokenBLiq;
        
                viableRoutes.push({ route: route, inputLiq: parseFloat(inputLiq), outputLiq: parseFloat(outputLiq) });
            };

            // recreate pool array in user friendly format
            const poolArr = inputs.pools.map(p => {
                var [, sym] = p.lt_symbol.split(",");
                var [tokenALiq, tokenA] = p.pool1.quantity.split(" ");
                var [tokenBLiq, tokenB] = p.pool2.quantity.split(" ");

                return { sym: sym, tokenA: tokenA, tokenALiq: tokenALiq, tokenB: tokenB, tokenBLiq: tokenBLiq };
            });

            // iterate pool array and filter by output token
            var outputPools = poolArr.filter(p => p.tokenA == outputCurrency.ticker || p.tokenB == outputCurrency.ticker);

            // sort output pools by output liquidity (descending)
            outputPools.sort((a, b) => {
                var poolALiq = (a.tokenA == outputCurrency.ticker) ? a.tokenALiq : a.tokenBLiq;
                var poolBLiq = (b.tokenA == outputCurrency.ticker) ? b.tokenALiq : b.tokenBLiq;
        
                return parseFloat(poolBLiq) - parseFloat(poolALiq);
            });

            console.log(outputPools);

            var route = "";

            // iterate output pools and check which pools have a connection to input token
            outputPools.forEach(op => {
                var baseToken = (op.tokenA == outputCurrency.ticker) ? op.tokenB : op.tokenA;

                var depth = 0;

                route = op.sym;

                // check if output pool is direct with input token
                if (baseToken == inputCurrency.ticker) {
                    var liq = (op.tokenA == inputCurrency.ticker) ? op.tokenALiq : op.tokenBLiq;

                    routeFound(route, liq);
                } else {
                    nextDepth(baseToken, route);
                };

                function nextDepth(baseToken, currentRoute, prevPool) {
                    if (depth >= 5) return;

                    depth++;

                    // find all pools with baseToken
                    var baseTokenPools = poolArr.filter(p => {
                        return (p.tokenA == baseToken || p.tokenB == baseToken);
                    });

                    // order baseToken pools by most liquidity (descending)
                    baseTokenPools.sort((a, b) => {
                        var poolALiq = (a.tokenA == baseToken) ? a.tokenALiq : a.tokenBLiq;
                        var poolBLiq = (b.tokenA == baseToken) ? b.tokenALiq : b.tokenBLiq;

                        return parseFloat(poolBLiq) - parseFloat(poolALiq);
                    });

                    var matchingPools = baseTokenPools.filter(p => {
                        var newToken = (p.tokenA == baseToken) ? p.tokenB : p.tokenA;

                        return newToken == inputCurrency.ticker;
                    });

                    matchingPools.sort((a, b) => {
                        var poolALiq = (a.tokenA == inputCurrency.ticker) ? a.tokenALiq : a.tokenBLiq;
                        var poolBLiq = (b.tokenA == inputCurrency.ticker) ? b.tokenALiq : b.tokenBLiq;

                        return parseFloat(poolBLiq) - parseFloat(poolALiq);
                    });

                    matchingPools.forEach(mp => {
                        var baseRoute = currentRoute;

                        if (baseRoute.indexOf(mp.sym) != -1) return;

                        baseRoute = mp.sym + "," + baseRoute;

                        var liq = (mp.tokenA == inputCurrency.ticker) ? mp.tokenALiq : mp.tokenBLiq;

                        routeFound(baseRoute, liq);
                    });

                    baseTokenPools.forEach(bp => {
                        var nextBaseToken = (bp.tokenA == baseToken) ? bp.tokenB : bp.tokenA;

                        if (currentRoute.indexOf(bp.sym) == -1) return nextDepth(nextBaseToken, bp.sym + "," + currentRoute, bp);
                    });
                };
            });

            viableRoutes.sort((a, b) => {
                // work out dollar value of inputLiq + outputLiq
                // sort pools based on cumulative dollar value
                return (b.inputLiq + b.outputLiq) - (a.inputLiq + a.outputLiq);
            });
        
            viableRoutes.forEach(r => {
                var [startPool] = r.route.split(",");
        
                if (r.route.indexOf(",") == -1) {
                    viableRoutes = viableRoutes.filter(r2 => {
                        return (r2.route.indexOf(startPool) != 0) || r2.route == r.route;
                    });
                };
            });

            console.log(viableRoutes?.[0]);
        };

        // calculate price impact
        var totalPriceImpact = (1 - ((data.prices?.[outputCurrency.ticker] * bestMinimumReceived) / (data.prices?.[inputCurrency.ticker] * inputQuantity))) * 100;

        if (window.outputCalcTime > outputCalcTime) return;

        // fetch pool(s) info from best route
        var lastInput = inputCurrency.ticker.toLowerCase() + "-" + inputCurrency.contract.toLowerCase();
        var routeString = inputCurrency.ticker;
        var processed = {};

        bestRoute.forEach(async poolId => {
            try {
                const response = await fetch("https://proton.alcor.exchange/api/v2/swap/pools/" + poolId);

                const { tokenA, tokenB } = await response.json();

                processed[poolId] = [tokenA, tokenB];

                if (Object.keys(processed).length == bestRoute.length) {
                    bestRoute.forEach(poolId => {
                        let outputToken = (lastInput == processed[poolId]?.[0].id) ? processed[poolId]?.[1] : processed[poolId]?.[0];

                        lastInput = outputToken.id;
                        routeString += " > " + outputToken.symbol;
                    });

                    window.outputCalcTime = outputCalcTime;

                    setInputs(values => (
                        { ...values, outputQuantity: bestMinimumReceived || 0, priceImpact: (totalPriceImpact || 0).toFixed(2), inputMemo: bestMemo, inputRoute: routeString, outputLoading: false }
                    ));
                };
            } catch(error) {
                console.error(error);
            };
        });
    };

    function switchCurrencies() {
        setInputs(values => (
            { ...values, inputCurrency: inputs.outputCurrency, outputCurrency: inputs.inputCurrency }
        ));
    };

    function toggleSelectCurrency(type) {
        if (type == "input") {
            setInputs(values => (
                { ...values, selectInput: !inputs.selectInput }
            ));
        } else {
            setInputs(values => (
                { ...values, selectOutput: !inputs.selectOutput }
            ));
        };
    };

    function changeCurrency(type, currency) {
        if (type == "input") {
            setInputs(values => (
                { ...values, inputCurrency: currency }
            ));
        } else {
            setInputs(values => (
                { ...values, outputCurrency: currency }
            ));
        };
    };

    function maxInput() {
        setInputs(values => (
            { ...values, inputQuantity: data.balances?.[inputs.inputCurrency?.ticker] }
        ));
    };

    function handleTrade(event) {
        event.preventDefault();

        var button = $("button.trade");
        var buttonHtml = button.html();

        button.html("<i class='fad fa-spinner-third'></i> Converting").attr("disabled", true);

        var handleSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your trade has been successfully processed</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            dispatch({
                type: "fetchWalletBalances",
                value: true
            });

            button.html(buttonHtml).attr("disabled", false);
        };

        var handleError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error processing your request, please try again");

            button.html(buttonHtml).attr("disabled", false);
        };

        Proton.sendTokens(state, inputs.inputCurrency, "XPR Network", "swap.alcor", inputs.inputQuantity, inputs.inputMemo).then(tx => {
            handleSuccess(tx.processed.id);
        }).catch(e => {
            handleError(e.error?.details?.[0]?.message || e);
        });
    };

    return (
        <section className="trade">
            <div className="content no-overflow" onClick={ () => (inputs.selectInput || inputs.selectOutput) ? setInputs(values => ({ ...values, selectInput: false, selectOutput: false })) : "" }>
                <div className="form-title"><img src={ inputs.inputCurrency?.logo } alt={ inputs.inputCurrency?.name }></img> Convert { inputs.inputCurrency?.name }</div>

                <div className="swap-wrapper">
                    <div className="token base">
                        <div className="input-wrapper">
                            <span>Send <span className={ (inputs.inputQuantity * data.prices[inputs.inputCurrency?.ticker] > 0 ? "value active" : "value") }>~{ (inputs.inputQuantity * data.prices[inputs.inputCurrency?.ticker]).toLocaleString("en-US", {style: "currency", currency: "USD"}) }</span></span>
                            <input type="text" name="inputQuantity" autoComplete="off" placeholder={ (0).toFixed(inputs.inputCurrency?.precision) } value={ inputs.inputQuantity } onChange={ handleChange } required/>
                        </div>

                        <div className="token-wrapper">
                            <span className="click" onClick={ maxInput }>Balance: { data.balances?.[inputs.inputCurrency?.ticker] }</span>
                            <div className="currency click" onClick={ () => setInputs(values => ({ ...values, selectInput: !inputs.selectInput })) }>
                                <img src={ inputs.inputCurrency?.logo } alt={ inputs.inputCurrency?.name } />
                                <div className="ticker">{ inputs.inputCurrency?.ticker }</div>
                                <i className="fas fa-chevron-down"></i>

                                <div className={ inputs.selectInput ? "menu active" : "menu" }>
                                    {
                                        supportedCurrencies.filter(c => {
                                            return c.ticker != inputs.outputCurrency?.ticker;
                                        }).map((c, i) => {
                                            if (c.trade) return (
                                                <div key={ i } className={ (c.ticker == inputs.inputCurrency?.ticker) ? "token click active" : "token click" } onClick={ () => changeCurrency("input", c) }>
                                                    <img src={ c.logo } alt="" />
                                                    <div>{ c.ticker }</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="token quote">
                        <div className="input-wrapper">
                            <span>Receive <span className={ (inputs.outputQuantity * data.prices[inputs.outputCurrency?.ticker] > 0 ? "value active" : "value") }>~{ (inputs.outputQuantity * data.prices[inputs.outputCurrency?.ticker]).toLocaleString("en-US", {style: "currency", currency: "USD"}) }</span></span>
                            {
                                (inputs.outputLoading) && (
                                    <i className="fad fa-spinner-third"/>
                                )
                            }

                            {       
                                (!inputs.outputLoading) && (
                                    <input type="text" name="inputQuantity" autoComplete="none" placeholder={ (0).toFixed(inputs.outputCurrency?.precision) } value={ (inputs.outputQuantity / 0.993)?.toFixed(inputs.outputCurrency?.precision) } readOnly/>
                                )
                            }
                        </div>

                        <div className="token-wrapper">
                            <span>Balance: { data.balances?.[inputs.outputCurrency?.ticker] }</span>
                            <div className="currency click" onClick={ () => toggleSelectCurrency("output") }>
                                <img src={ inputs.outputCurrency?.logo } alt={ inputs.outputCurrency?.name } />
                                <div className="ticker">{ inputs.outputCurrency?.ticker }</div>
                                <i className="fas fa-chevron-down"></i>

                                <div className={ inputs.selectOutput ? "menu active" : "menu" }>
                                    {
                                        supportedCurrencies.filter(c => {
                                            return c.ticker != inputs.inputCurrency?.ticker;
                                        }).map((c, i) => {
                                            if (c.trade) return (
                                                <div key={ i } className={ (c.ticker == inputs.outputCurrency?.ticker) ? "token click active" : "token click" } onClick={ () => changeCurrency("output", c) }>
                                                    <img src={ c.logo } alt="" />
                                                    <div>{ c.ticker }</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <i className="fas fa-repeat click" onClick={ switchCurrencies }></i>
                </div>
                
                <button type="submit" className="trade" onClick={ handleTrade } disabled={ inputs.outputLoading }>Convert</button>

                <div className="info">
                    <div>Minimum Received <span>{ (inputs.outputQuantity || 0).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: inputs.outputCurrency?.precision, maximumFractionDigits: inputs.outputCurrency?.precision}) } { inputs.outputCurrency?.ticker }</span></div>
                    <div>Price Impact <span className={ (inputs.priceImpact < 5) ? "green" : (inputs.priceImpact < 10) ? "amber" : "red"}>{ (inputs.priceImpact || 0) > 100 ? 99.99 : (inputs.priceImpact || 0) < 0 ? 0.00.toFixed(2) : parseFloat(inputs.priceImpact)?.toFixed(2) }%</span></div>
                    <div>Route <span>{ inputs.inputRoute }</span></div>
                </div>
            </div>
        </section>
    );
};

export default TradeModal;