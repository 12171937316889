import { React } from "react";
import { BrowserRouter } from "react-router-dom";
import $ from "jquery";

import { StateProvider } from "state";
import { defaultSession, defaultAccount } from "constants";

import Header from "components/header/header";
import Main from "components/main";
import Footer from "components/footer/footer";
import InputModal from "components/modal/input-modal";
import ErrorModal from "components/modal/error-modal";

function App() {
    Number.prototype.toFixedStatic = function(p) {
        return Math.floor(this * Math.pow(10, p)) / Math.pow(10, p);
    };

    $.fn.hasAnyClass = function() {
        for (var i = 0; i < arguments.length; i++) {
            var classes = arguments[i].split(" ");

            for (var j = 0; j < classes.length; j++) {
                if (this.hasClass(classes[j])) {
                    return true;
                };
            };
        };

        return false;
    };

    const state = {
        account: defaultAccount,
        accountId: null,
        authenticated: null,
        cart: [],
        connected: null,
        currency: "USD",
        inputInternational: null,
        inputModal: null,
        inputVariations: null,
        kyc: false,
        language: "EN",
        link: null,
        order: {},
        session: defaultSession,
        w3a: null,
        w3aConnected: null,
        w3aPrivateKey: null,
        w3aProvider: null,
        w3aPublicKey: null,
        w3aUser: null,
        wishlist: [],
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "setW3A":
                return {
                    ...state,
                    w3a: action.value
                }

            case "setW3AProvider":
                return {
                    ...state,
                    w3aProvider: action.value ? action.value.provider : null,
                    w3aConnected: action.value ? action.value.connected : null,
                    w3aPublicKey: action.value ? action.value.pubKey : null,
                    w3aPrivateKey: action.value ? action.value.privKey : null,
                    w3aUser: action.value ? action.value.user : null
                }

            case "setAuth":
                return {
                    ...state,
                    authenticated: action.value ? true : false,
                    accountId: action.value ? action.value.id : null
                };

            case "setWallet":
                return {
                    ...state,
                    connected: action.value ? true : false,
                    session: action.value ? action.value.session : defaultSession,
                    link: action.value ? action.value.link : null,
                    kyc: action.value ? state.kyc : false,
                    account: action.value ? state.account : defaultAccount,
                    cart: action.value ? state.cart : [],
                    wishlist: action.valie ? state.wishlist : []
                };

            case "setKyc":
                return {
                    ...state,
                    kyc: action.value ? action.value.kyc : false
                };

            case "setAccount":
                return {
                    ...state,
                    account: action.value ? action.value.account : defaultAccount
                };

            case "setNotifications":
                return {
                    ...state,
                    account: {
                        ...state.account,
                        notifications: action.value
                    }
                }

            case "setCurrency":
                return {
                    ...state,
                    currency: action.value ? action.value : null
                };

            case "setLanguage":
                return {
                    ...state,
                    language: action.value ? action.value : null
                };

            case "setCart":
                return {
                    ...state,
                    cart: action.value || []
                }

            case "setWishlist":
                return {
                    ...state,
                    wishlist: action.value || []
                }

            case "setOrder":
                return {
                    ...state,
                    order: action.value || {}
                }

            case "setModal":
                return {
                    ...state,
                    inputModal: action.value
                }

            case "setVariations":
                return {
                    ...state,
                    inputVariations: action.value
                }

            case "setInternational":
                return {
                    ...state,
                    inputInternational: action.value
                }

            case "setCreateListing":
                return {
                    ...state,
                    createListing: action.value
                }

            case "setEditListing":
                return {
                    ...state,
                    editListing: action.value
                }

            case "setSelectedBillingAddress":
                return {
                    ...state,
                    selectedBillingAddress: action.value
                }

            case "setSelectedShippingAddress":
                return {
                    ...state,
                    selectedShippingAddress: action.value
                }

            case "fetchSellingHistory":
                return {
                    ...state,
                    fetchSellingHistory: action.value
                }

            case "fetchWalletBalances":
                return {
                    ...state,
                    fetchWalletBalances: action.value
                }

            case "fetchDisputes":
                return {
                    ...state,
                    fetchDisputes: action.value
                }

            case "viewDeals":
                return {
                    ...state,
                    viewDeals: action.value
                }

            case "closeMobileMenu":
                return {
                    ...state,
                    closeMobileMenu: action.value
                }

            case "promptLogin":
                return {
                    ...state,
                    promptLogin: action.value
                }

            case "promptRedirect":
                return {
                    ...state,
                    promptRedirect: action.value
                }

            case "closeInputModal":
                return {
                    ...state,
                    closeInputModal: action.value
                }

            case "emailVerified":
                return {
                    ...state,
                    emailVerified: action.value
                }

            case "kycSubmitted":
                return {
                    ...state,
                    kycSubmitted: action.value
                }

            default:
                return state;
        };
    };

    return (
        <BrowserRouter>
            <StateProvider state={ state } reducer={ reducer }>
                <Header/>
                <Main/>
                <Footer/>
                <InputModal/>
                <ErrorModal/>
            </StateProvider>
        </BrowserRouter>
    );
};

export default App;