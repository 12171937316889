import { React, useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { apiEndpoint, maxReviewCommentsLength } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function LeaveReviewModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (state.inputModal?.action != "leave-review") return;

        var { listing, button } = state.inputModal?.data;

        setData(values => (
            { ...values, listing: listing, button: button }
        ));
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;

        $(data.button).html(textStrings["leave_review"]?.[state.language]).attr("disabled", false);

        setInputs({});
        setData({});
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleRatingChange(event) {
        event.preventDefault();

        $(".rating-wrapper i.fal.fa-star").removeClass("active");

        $(event.target).addClass("active").prevAll().addClass("active");
    };

    function handleSubmit(event) {
        event.preventDefault();

        var button = $("button.review");
        var buttonHtml = button.html();

        button.html("<i class='fad fa-spinner-third'></i> Submitting").attr("disabled", true);

        var handleSuccess = () => {
            $("div.input-modal, section").removeClass("active");

            $("div.error-modal").addClass("success").find("div.text").html("Your review has been successfully submitted");

            button.html(buttonHtml).attr("disabled", false);
        };

        var handleError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error processing your request, please try again");

            button.html(buttonHtml).attr("disabled", false);
        };

        var reviewRating = $("i.fal.fa-star.active").length;

        Listing.leaveReview(state.session.auth.actor, data.listing?.orderNumber, reviewRating, inputs.reviewComments).then(r => {
            handleSuccess();
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            handleError(message);
        });
    };

    return (
        <section className="leave-review">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-star"></i> { textStrings["leave_review"]?.[state.language] }</div>

                <div className="leave-review-wrapper">
                    <form onSubmit={ handleSubmit }>
                        <div className="input-group">
                            <label htmlFor="rating">Rating</label>
                            <div className="rating-wrapper">
                                <i className="fal fa-star active" onClick={ handleRatingChange }></i>
                                <i className="fal fa-star active" onClick={ handleRatingChange }></i>
                                <i className="fal fa-star active" onClick={ handleRatingChange }></i>
                                <i className="fal fa-star active" onClick={ handleRatingChange }></i>
                                <i className="fal fa-star active" onClick={ handleRatingChange }></i>
                            </div>
                        </div>

                        <div className="input-group">
                            <label htmlFor="reviewComments">Comments</label>
                            <input type="text" name="reviewComments" autoComplete="none" maxLength="100" value={ inputs.reviewComments || "" } onChange={ handleChange } required/>
                            <span className="chars-left">{ (inputs.reviewComments?.length || 0) + "/" + maxReviewCommentsLength + " characters" }</span>
                        </div>

                        <button type="submit" className="submit review">Leave Review</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default LeaveReviewModal;