import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, regionCodesDictionary, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, dummyListings } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

function ShippingZones() {
    const navigate = useNavigate();
    const [slidePosition, setPosition] = useState(0);
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({  });
    const [data, setData] = useState({  });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    function handleToggle(name) {
        setInputs(values => (
            { ...values, [name]: !inputs[name] }
        ));
    };

    return (
        <section className="shipping-zones">
            <div className="page-header">Shipping Zones</div>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><p>Home</p></Link>
                    <span>></span>
                    <Link to="/shipping-zones"><p>{ textStrings["shipping_zones"][state.language] }</p></Link>
                </div>

                <img src="/assets/images/shipping_zones.png" alt="" />

                <div className={ "accordion" + (inputs.usa ? " active" : "") } onClick={ () => handleToggle("usa") }>
                    <button style={{ borderLeft: "5px solid #c5c6c6" }}>United States of America</button>
                    <div className="panel">
                        <p>
                            {
                                regionCodesDictionary["USA"].sort().map((cc, index) => (
                                    countryCodesDictionary[cc] + (index == regionCodesDictionary["USA"].length-1 ? "" : ", ")
                                ))
                            }
                        </p>
                    </div>
                </div>

                <div className={ "accordion" + (inputs.uk ? " active" : "") } onClick={ () => handleToggle("uk") }>
                    <button style={{ borderLeft: "5px solid #555756" }}>United Kingdom</button>
                    <div className="panel">
                        <p>
                            {
                                regionCodesDictionary["UK"].sort().map((cc, index) => (
                                    countryCodesDictionary[cc] + (index == regionCodesDictionary["UK"].length-1 ? "" : ", ")
                                ))
                            }
                        </p>
                    </div>
                </div>

                <div className={ "accordion" + (inputs.eur ? " active" : "") } onClick={ () => handleToggle("eur") }>
                    <button style={{ borderLeft: "5px solid #0094d0" }}>Europe</button>
                    <div className="panel">
                        <p>
                            {
                                regionCodesDictionary["EUR"].sort().map((cc, index) => (
                                    countryCodesDictionary[cc] + (index == regionCodesDictionary["EUR"].length-1 ? "" : ", ")
                                ))
                            }
                        </p>
                    </div>
                </div>

                <div className={ "accordion" + (inputs.wz1 ? " active" : "") } onClick={ () => handleToggle("wz1") }>
                    <button style={{ borderLeft: "5px solid #d40c18" }}>World Zone 1</button>
                    <div className="panel">
                        <p>
                            {
                                regionCodesDictionary["WZ1"].sort().map((cc, index) => (
                                    countryCodesDictionary[cc] + (index == regionCodesDictionary["WZ1"].length-1 ? "" : ", ")
                                ))
                            }
                        </p>
                    </div>
                </div>

                <div className={ "accordion" + (inputs.wz2 ? " active" : "") } onClick={ () => handleToggle("wz2") }>
                    <button style={{ borderLeft: "5px solid #867c77" }}>World Zone 2</button>
                    <div className="panel">
                        <p>
                            {
                                regionCodesDictionary["WZ2"].map((cc, index) => (
                                    countryCodesDictionary[cc] + (index == regionCodesDictionary["WZ2"].length-1 ? "" : ", ")
                                ))
                            }
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ShippingZones;
