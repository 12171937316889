import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $, { support } from "jquery";

import { useStateValue } from "state";
import { stakingTiers, supportedCurrencies } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

import Sidebar from "components/account/sidebar";
import { isObjectEmpty } from "functions";

function Staking() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ addStake: 0, aprMultiplier: 1, mergeStakes: [], mergeUnstakes: [] });
    const [data, setData] = useState({ oldStake: 0, currentStake: null, stakes: [], feeDiscountEnrol: false, unstakes: [], unstakedBalance: null, totalStakes: 0, totalRewards: 0, rewardsSecond: 0, unstakeTime: 86400 });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        var rewardTimer = setInterval(() => {
            setData(values => (
                { ...values, rewardsSecond: values.rewardsSecond }
            ));
        }, 1000);

        // var dataTimer = setInterval(() => {
        //     handleDataFetch();
        // }, 10000);

        return () => {
            clearInterval(rewardTimer);
            //clearInterval(dataTimer);
        };
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/staking"
            });
            
            return navigate("/");
        };

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    function handleDataFetch() {
        // fetch stake balance from V1 contract
        Proton.fetchV1Stake(state.session).then(res => {
            var [staked] = res.rows?.[0]?.stake_amount?.quantity?.split(" ");

            setData(values => (
                { ...values, oldStake: parseFloat(staked) }
            ));
        }).catch(err => {
            console.log(err);
        });

        // fetch config from V2 contract
        Proton.fetchV2StakeConf().then(res => {
            var config = res.rows?.[0];
            var [totalStakes] = config?.stakes?.split(" ");
            var [totalRewards] = config?.rewards?.split(" ");
            var [rewardsSecond] = config?.rewards_sec?.split(" ");
            var stakePlans = config?.stake_plans;
            var unstakeTime = config?.unstake_period_secs;

            setData(values => (
                { ...values, totalStakes: parseFloat(totalStakes), totalRewards: parseFloat(totalRewards), rewardsSecond: parseFloat(rewardsSecond), unstakeTime: unstakeTime }
            ));
        }).catch(err => {
            console.log(err);
        });

        // fetch stakes from V2 contract
        Proton.fetchV2Stakes(state.session).then(res => {
            var stakes = res.rows;
            var currentStake = stakes.reduce((a, b) => a + parseFloat(b.quantity.split(" ")[0]), 0);
            var currentTier = {};

            Object.keys(stakingTiers).reverse().some(tier => {
                if (currentStake >= parseFloat(tier)) {
                    currentTier = stakingTiers[tier];

                    return true;
                };
            });

            if (isObjectEmpty(currentTier)) currentTier = stakingTiers[0];

            setData(values => (
                { ...values, currentStake: currentStake, stakes: stakes, currentTier: currentTier, feeDiscountEnrol: stakes?.[0]?.fee_discount_enrol }
            ));
        }).catch(err => {
            console.log(err);
        });

        // fetch unstakes from V2 contract
        Proton.fetchV2Unstakes(state.session).then(res => {
            var unstakes = res.rows;

            setData(values => (
                { ...values, unstakes: unstakes }
            ));
        }).catch(err => {
            console.log(err);
        });

        // fetch unstaked balance
        Proton.fetchBalance(state.session.auth.actor, supportedCurrencies[0]).then(res => {
            var [balance] = (res.length > 0) ? res?.[0]?.split(" ") : [0];

            setData(values => (
                { ...values, unstakedBalance: parseFloat(balance || 0) }
            ));
        }).catch(err => {
            console.log(err);
        });
    };

    function handleMigrate(event) {
        event.preventDefault();

        $("button.migrate").html("<i class='fad fa-spinner-third'></i> Migrating").attr("disabled", true);

        var showSuccess = (txId) => {
            setData(values => (
                { ...values, oldStake: 0 }
            ));

            $("div.error-modal").addClass("success").find("div.text").html("Your stake has been successfully migrated</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            $("button.migrate").html(textStrings?.["migrate"]?.[state.language]).attr("disabled", false);
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(JSON.stringify(err) || "There was an error sending your request, please contact support");

            $("button.migrate").html(textStrings?.["migrate"]?.[state.language]).attr("disabled", false);
        };

        Proton.migrateStake(state.session, data.oldStake).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleChange(event, type) {
        const name = event.target?.name;
        var value = event.target?.value;

        if (name == "aprMultiplier") {
            var [aprMultiplier, lockPeriod] = value.split("-");
            
            setInputs(values => (
                { ...values, aprMultiplier: aprMultiplier, lockPeriod: lockPeriod }
            ));

            return;
        };

        if ($(event.target).attr("type") == "checkbox") {
            var tmpArray = [];

            $("[name='" + $(event.target).attr("name") + "']:checked").each((i, e) => {
                if ($(e).parent().parent().css("display") == "none" || e.disabled) return;

                var [, stakeId] = e.id?.split("-");

                if (stakeId == null) return;

                tmpArray.push(stakeId);
            });

            console.log(tmpArray);

            value = tmpArray;
        };

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleSliderInput(event) {
        var value = event.target.value;

        if (!value) value = 0;
        if (value < 0) value = 0;
        if (value > data.unstakedBalance) value = parseFloat(data.unstakedBalance)?.toFixed(4);

        setInputs(values => (
            { ...values, addStake: value }
        ));
    };

    function handleSlider(event) {
        setInputs(values => (
            { ...values, addStake: event.target.value }
        ));
    };

    function handleShortcut(percent) {
        setInputs(values => (
            { ...values, addStake: parseFloat((data.unstakedBalance * percent / 100)?.toFixed(4)) }
        ));
    };

    function handleAddStake(event) {
        event.preventDefault();

        var stakeAmount = parseFloat(inputs.addStake);
        
        if (stakeAmount <= 0) return;

        $("button.add").html("<i class='fad fa-spinner-third'></i> Sending Transaction").attr("disabled", true);

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            setTimeout(() => {
                return handleDataFetch();
            }, 3000);

            $("button.add").html(textStrings["account_add_stake"][state.language]).attr("disabled", false);
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error sending your request, please contact support");

            $("button.add").html(textStrings["account_add_stake"][state.language]).attr("disabled", false);
        };

        console.log(stakeAmount, inputs.lockPeriod);

        Proton.addStake(state, stakeAmount, inputs.lockPeriod).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            console.log(err);
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleUnstake(event, id) {
        event.preventDefault();

        if ($(event.target).hasClass("mass-action")) {
            id = inputs.mergeStakes;
        };

        $(event.target).html("<i class='fad fa-spinner-third'></i> Unstaking").attr("disabled", true);

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            setTimeout(() => {
                return handleDataFetch();
            }, 3000);

            $(event.target).html(textStrings["account_unstake"][state.language]).attr("disabled", false);

            setInputs(values => (
                { ...values, mergeStakes: [] }
            ));

            setData(values => (
                { ...values, stakes: data.stakes.filter(s => s.id != id) }
            ));
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(JSON.stringify(err) || "There was an error sending your request, please contact support");

            $(event.target).html(textStrings["account_unstake"][state.language]).attr("disabled", false);
        };

        Proton.unStake(state, id).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleClaim(event) {
        event.preventDefault();

        $(event.target).html("<i class='fad fa-spinner-third'></i> Claiming").attr("disabled", true);

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            setTimeout(() => {
                return handleDataFetch();
            }, 3000);

            $(event.target).html(textStrings["account_claim_rewards"][state.language]).attr("disabled", false);

            setData(values => (
                { ...values, stakes: data.stakes.map(s => { s.last_calc = Date.now() / 1000; s.unclaimed_rewards = "0.0000 STRX"; return s }) }
            ));
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error sending your request, please contact support");

            $(event.target).html(textStrings["account_claim_rewards"][state.language]).attr("disabled", false);
        };

        Proton.claimRewards(state).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleDiscountEnrol(event) {
        event.preventDefault();

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            setTimeout(() => {
                return handleDataFetch();
            }, 3000);

            setData(values => (
                { ...values, feeDiscountEnrol: !data.feeDiscountEnrol }
            ));
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error sending your request, please contact support");
        };

        Proton.enrolDiscount(state, !data.feeDiscountEnrol).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleRestake(event, id) {
        event.preventDefault();

        if ($(event.target).hasClass("mass-action")) {
            id = inputs.mergeUnstakes;
        };

        $(event.target).html("<i class='fad fa-spinner-third'></i> Staking").attr("disabled", true);

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            setTimeout(() => {
                return handleDataFetch();
            }, 3000);

            $(event.target).html(textStrings["account_restake"][state.language]).attr("disabled", false);

            setInputs(values => (
                { ...values, mergeUnstakes: [] }
            ));

            setData(values => (
                { ...values, unstakes: data.unstakes.filter(s => s.id != id) }
            ));
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error sending your request, please contact support");

            $(event.target).html(textStrings["account_restake"][state.language]).attr("disabled", false);
        };

        Proton.reStake(state, id).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleWithdraw(event, id) {
        event.preventDefault();

        if ($(event.target).hasClass("mass-action")) {
            id = inputs.mergeUnstakes;
        };

        $(event.target).html("<i class='fad fa-spinner-third'></i> Withdrawing").attr("disabled", true);

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            setTimeout(() => {
                return handleDataFetch();
            }, 3000);

            $(event.target).html(textStrings["account_withdraw"][state.language]).attr("disabled", false);

            setInputs(values => (
                { ...values, mergeUnstakes: [] }
            ));

            setData(values => (
                { ...values, unstakes: data.unstakes.filter(s => s.id != id) }
            ));
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error sending your request, please contact support");

            $(event.target).html(textStrings["account_withdraw"][state.language]).attr("disabled", false);
        };

        Proton.withdrawStake(state, id).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleMerge(event) {
        event.preventDefault();

        $(event.target).html("<i class='fad fa-spinner-third'></i> Merging stakes").attr("disabled", true);

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            $("input[name='mergeStakes']").prop("checked", false);

            handleChange(event);

            setInputs(values => (
                { ...values, mergeStakes: [] }
            ));

            setTimeout(() => {
                return handleDataFetch();
            }, 3000);

            $(event.target).html(textStrings["merge_stakes"]?.[state.language]).attr("disabled", false);
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error sending your request, please contact support");

            $(event.target).html(textStrings["merge_stakes"]?.[state.language]).attr("disabled", false);
        };

        Proton.mergeStakes(state, inputs.mergeStakes).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleCountdown(secondsLeft) {
        if (secondsLeft <= 0) return (
            <><i className="fal fa-unlock"></i> Unstaked</>
        );

        var days = Math.floor(secondsLeft / (60 * 60 * 24));
        var hours = Math.floor((secondsLeft % (60 * 60 * 24)) / (60 * 60));
        var minutes = Math.floor((secondsLeft % (60 * 60)) / (60));
        var seconds = Math.floor((secondsLeft % (60)));

        var plural = "";

        if (days > 0) {
            if (days > 1) plural = "s";

            return (
                <><i className="fal fa-clock"></i> { days + " day" + plural }</>
            );
        } else if (hours > 0) {
            if (hours > 1) plural = "s";

            return (
                <><i className="fal fa-clock"></i> { hours + " hour" + plural }</>
            );
        } else if (minutes > 0) {
            if (minutes > 1) plural = "s";

            return (
                <><i className="fal fa-clock"></i> { minutes + " minute" + plural }</>
            );
        } else if (seconds > 0) {
            if (seconds > 1) plural = "s";

            return (
                <><i className="fal fa-clock"></i> { seconds + " second" + plural }</>
            );
        };
    };

    function toggleCheckbox(event, type) {
        $("input[name='" + type + "']:not(:disabled)").prop("checked", event.target?.checked);

        handleChange(event, type);
    };

    function toggleStakeCollapse(e, i, type) {
        if (e.target.type) return;

        setInputs(values => (
            { ...values, [type + i]: !inputs?.[type + i] }
        ));
    };

    function handleTier(tier) {

    };

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><p>Home</p></Link>
                    <span>></span>
                    <Link to="/account"><p>{ textStrings?.["my_account"]?.[state.language] }</p></Link>
                    <span>></span>
                    <Link to="/account/staking"><p>{ textStrings?.["account_staking"]?.[state.language] }</p></Link>
                </div>

                <div className="account-wrapper">
                    <Sidebar></Sidebar>

                    <div className="content-wrapper">
                        <section className="staking">
                            {
                                (data.oldStake > 0) && (
                                    <div className="migration-notice">
                                        <div>
                                            <div className="title">Migrate Stake</div>
                                            <span>We have detected <p>{ data.oldStake.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 4, maximumFractionDigits: 4}).slice(1) }</p> <img src={ supportedCurrencies[0].logo } alt={ supportedCurrencies[0].ticker }/> in the deprecated V1 staking contract. Would you like to migrate?</span>
                                        </div>

                                        <button type="submit" className="migrate" onClick={ handleMigrate }>Migrate</button>
                                    </div>
                                )
                            }

                            <div className="content-title"><i className="fal fa-chart-bar"></i> { textStrings?.["account_summary"]?.[state.language] }</div>

                            <div className="summary">
                                <span>
                                    <div className="subtitle">Variable APR</div>
                                    {
                                        (data.rewardsSecond && data.totalStakes) ? <p>{ ((((data.rewardsSecond * 86400 * 365) / data.totalStakes) * 100) || 0)?.toFixed(2) }%</p> : <i className="fad fa-spinner-third"/>
                                    }
                                </span>

                                <span>
                                    <div className="subtitle">Global Staked</div>
                                    {
                                        (data.totalStakes) ? <p>{ data.totalStakes.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 4, maximumFractionDigits: 4}).slice(1) } <img src={ supportedCurrencies[0].logo } alt={ supportedCurrencies[0].ticker }/></p> : <i className="fad fa-spinner-third"/>
                                    }
                                </span>

                                <span>
                                    <div className="subtitle">Your Stake</div>
                                    {
                                        (data.currentStake != null) ? <p>{ data.currentStake.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 4, maximumFractionDigits: 4}).slice(1) } <img src={ supportedCurrencies[0].logo } alt={ supportedCurrencies[0].ticker }/></p> : <i className="fad fa-spinner-third"/>
                                    }
                                </span>

                                <span>
                                    <div className="subtitle">Reward Tier</div>
                                    {
                                        (!isObjectEmpty(data.currentTier)) ? <p>{ (data.currentTier.tier > 0) && (data.currentTier.emoji) } { data.currentTier.name } <div className="soon">Coming Soon</div></p> : <i className="fad fa-spinner-third"/>
                                    }
                                </span>
                            </div>

                            <div className="content-title"><i className="fal fa-tachometer-alt"></i> { textStrings?.["account_add_stake"]?.[state.language] }</div>

                            <div className="add">
                                <div className="slider-wrapper">
                                    <div className="inputs">
                                        <div className="unstaked">
                                            <span>Unstaked Balance</span>
                                            <div>
                                                {
                                                    (data.unstakedBalance != null) ? <input type="text" value={ data.unstakedBalance.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 4, maximumFractionDigits: 4}).slice(1) } readOnly/> : <i className="fad fa-spinner-third"/>
                                                }
                                            </div>
                                        </div>

                                        <div className="staked">
                                            <span>Add Stake</span>
                                            <input type="number" min="0" max={ parseFloat(data.unstakedBalance)?.toFixed(4) } step="0.0001" value={ parseFloat(inputs.addStake)?.toFixed(4) } onChange={ handleSliderInput }/>
                                        </div>

                                        <div className="shortcuts n820">
                                            <button onClick={ () => handleShortcut(25) }>25%</button>
                                            <button onClick={ () => handleShortcut(50) }>50%</button>
                                            <button onClick={ () => handleShortcut(75) }>75%</button>
                                            <button onClick={ () => handleShortcut(100) }>100%</button>
                                        </div>
                                    </div>

                                    <div className="slider">
                                        <input type="range" min="0" max={ parseFloat((data.unstakedBalance || 0)?.toFixed(4)) } step="0.0001" value={ inputs.addStake } onChange={ handleSlider }/>
                                    </div>
                                </div>

                                <div className="duration-wrapper">
                                    <div className="duration">
                                        <span>Lock Duration</span>
                                        <div className="n830">
                                            <input type="radio" name="aprMultiplier" id="duration-1" value="1-0" onChange={ handleChange } defaultChecked />
                                            <label htmlFor="duration-1">None</label>
                                        </div>

                                        <div className="mobile b830">
                                            <select name="aprMultiplier" onChange={ handleChange }>
                                                <option value="1-0">None</option>
                                            </select>
                                            <i className="fal fa-chevron-down"></i>
                                        </div>
                                    </div>

                                    <div className="multiplier">
                                        <span>APR Multiplier</span>
                                        <p>{ parseFloat(inputs.aprMultiplier)?.toFixed(1) }x</p>
                                    </div>

                                    <div className="total-apr">
                                        <span>Projected APR</span>
                                        {
                                            (data.rewardsSecond && data.totalStakes) ? <p>{ ((((data.rewardsSecond * 86400 * 365) / data.totalStakes) * 100 * inputs.aprMultiplier) || 0)?.toFixed(2) }%</p> : <i className="fad fa-spinner-third"/>
                                        }
                                    </div>
                                </div>

                                <button type="submit" className="add" onClick={ handleAddStake }>{ textStrings?.["account_add_stake"]?.[state.language] }</button>
                            </div>

                            <div className="content-title"><i className="fal fa-money-check"></i> { textStrings?.["account_stakes"]?.[state.language] }</div>

                            <div className="stakes">
                                <table>
                                    <thead>
                                        <tr className="table-header n600">
                                            <input type="checkbox" name="mergeStakes" onChange={ (e) => toggleCheckbox(e, "mergeStakes") }/>
                                            <td className="long n600">Amount</td>
                                            <td className="n990">Start Date</td>
                                            <td className="short n860">Multiplier</td>
                                            <td className="short n600">APR</td>
                                            <td className="n600">Unlock Date</td>
                                            <td className="medium n768">Estimated Rewards</td>
                                        </tr>
                                    </thead>

                                    <tbody className="n600">
                                        {       
                                            (data.stakes?.filter(s => parseFloat(s.quantity.split(" ")[0]))?.length < 1) && (
                                                <tr className="empty">
                                                    <td>No stakes found</td>
                                                </tr>
                                            )
                                        }

                                        {
                                            data.stakes.filter(s => parseFloat(s.quantity.split(" ")[0])).sort((a, b) => {
                                                return a.created - b.created
                                            }).map((s, i) => (
                                                <tr key={ i }>
                                                    <input type="checkbox" id={ "stake-" + s.id } name="mergeStakes" onChange={ handleChange } disabled={ (s.multiplier_end - (Date.now() / 1000) > 0) ? true : false }/>
                                                    <td className="long bold">{ parseFloat(s.quantity.split(" ")[0]).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 4}).slice(1) } STRX</td>
                                                    <td className="n990">{ new Date(s.created * 1000).toLocaleDateString() }</td>
                                                    <td className="short n860">{ parseFloat(s.multiplier_end - (Date.now() / 1000) > 0 ? s.multiplier : 1)?.toFixed(1) }x</td>
                                                    <td className="short">{ ((((data.rewardsSecond * 86400 * 365) / data.totalStakes) * 100 * (s.multiplier_end - (Date.now() / 1000) > 0 ? s.multiplier : 1)) || 0)?.toFixed(2) }%</td>
                                                    { (s.multiplier_end - (Date.now() / 1000) > 0) ? <td className="amber"><i className="fal fa-lock"></i> { new Date(s.multiplier_end * 1000).toLocaleDateString() }</td> : <td className="green"><i className="fal fa-unlock"></i> Unlocked</td> }
                                                    <td className="bold n768">{ (parseFloat(s.unclaimed_rewards) + ((Date.now() / 1000 - s.last_calc) * parseFloat(data.rewardsSecond) * (parseFloat(s.quantity) / parseFloat(data.totalStakes)) * (s.multiplier_end - (Date.now() / 1000) > 0 ? s.multiplier : 1))).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 4, maximumFractionDigits: 4}).slice(1) } <img src={ supportedCurrencies[0].logo } alt={ supportedCurrencies[0].ticker }/></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                    <tbody className="b600">
                                        {       
                                            (data.stakes?.filter(s => parseFloat(s.quantity.split(" ")[0]))?.length < 1) && (
                                                <tr className="empty">
                                                    <td>No stakes found</td>
                                                </tr>
                                            )
                                        }

                                        {
                                            data.stakes.filter(s => parseFloat(s.quantity.split(" ")[0])).sort((a, b) => {
                                                return a.created - b.created
                                            }).map((s, i) => (
                                                <>
                                                    <tr className="click" key={ i } onClick={ (e) => toggleStakeCollapse(e, i, "stake") }>
                                                        <input type="checkbox" id={ "stake-" + s.id } name="mergeStakes" onChange={ handleChange } disabled={ (s.multiplier_end - (Date.now() / 1000) > 0) ? true : false }/>
                                                        <td className="long bold">{ parseFloat(s.quantity.split(" ")[0]).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 4}).slice(1) } STRX</td>
                                                        <td className="justify-center short">{ ((((data.rewardsSecond * 86400 * 365) / data.totalStakes) * 100 * (s.multiplier_end - (Date.now() / 1000) > 0 ? s.multiplier : 1)) || 0)?.toFixed(2) }%</td>
                                                        { (s.multiplier_end - (Date.now() / 1000) > 0) ? <td className="justify-end amber n420"><i className="fal fa-lock"></i></td> : <td className="justify-end green n420"><i className="fal fa-unlock"></i></td> }
                                                        <i className={ (inputs?.["stake" + i]) ? "fal fa-chevron-up" : "fal fa-chevron-down" }></i>
                                                    </tr>
                                                    <tr className={ (inputs?.["stake" + i]) ? "expand" : "collapse" }>
                                                        <div className="expand-gr">
                                                            <div className="subtitle">Start Date</div>
                                                            <td className="bold">{ new Date(s.created * 1000).toLocaleDateString() }</td>
                                                        </div>

                                                        <div className="expand-gr n500">
                                                            <div className="subtitle">APR Multiplier</div>
                                                            <td className="bold">{ parseFloat(s.multiplier_end - (Date.now() / 1000) > 0 ? s.multiplier : 1)?.toFixed(1) }x</td>
                                                        </div>

                                                        <div className="expand-gr">
                                                            <div className="subtitle">Unlock Date</div>
                                                            { (s.multiplier_end - (Date.now() / 1000) > 0) ? <td className="amber"><i className="fal fa-lock"></i> { new Date(s.multiplier_end * 1000).toLocaleDateString() }</td> : <td className="green"><i className="fal fa-unlock"></i> Unlocked</td> }
                                                        </div>

                                                        <div className="expand-gr">
                                                            <div className="subtitle">Estimated Rewards</div>
                                                            <td className="bold">{ (parseFloat(s.unclaimed_rewards) + ((Date.now() / 1000 - s.last_calc) * parseFloat(data.rewardsSecond) * (parseFloat(s.quantity) / parseFloat(data.totalStakes)) * (s.multiplier_end - (Date.now() / 1000) > 0 ? s.multiplier : 1))).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 4, maximumFractionDigits: 4}).slice(1) } <img src={ supportedCurrencies[0].logo } alt={ supportedCurrencies[0].ticker }/></td>
                                                        </div>
                                                    </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>

                                <button className="merge mass-action" onClick={ handleMerge } disabled={ (inputs.mergeStakes?.length < 2) ? true : false }>{ textStrings["merge_stakes"]?.[state.language] }</button>
                                <button className="unstake mass-action" onClick={ handleUnstake } disabled={ (inputs.mergeStakes?.length < 1) ? true : false }>{ textStrings["account_unstake"]?.[state.language] }</button>
                            </div>

                            <div className="content-title"><i className="fal fa-piggy-bank"></i> { textStrings?.["account_rewards"]?.[state.language] }</div>

                            <div className="rewards">
                                <div className="split">
                                    <div>
                                        <div>
                                            <div className="title">Estimated Total Rewards</div>
                                            <div className="subtitle">{ data.stakes.reduce((a, s) => a + (parseFloat(s.unclaimed_rewards) + ((Date.now() / 1000 - s.last_calc) * parseFloat(data.rewardsSecond) * (parseFloat(s.quantity) / parseFloat(data.totalStakes)) * (s.multiplier_end - (Date.now() / 1000) > 0 ? s.multiplier : 1))), 0).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 4, maximumFractionDigits: 4}).slice(1) } <img src={ supportedCurrencies[0].logo } alt={ supportedCurrencies[0].ticker }/></div>
                                        </div>

                                        <button className="claim n340" onClick={ handleClaim }>Claim Rewards</button>
                                        <button className="claim b340" onClick={ handleClaim }>Claim</button>
                                    </div>

                                    <div className="divider n890"></div>

                                    <div>
                                        <div>
                                            <div className="title">Seller Fee Discount</div>
                                            <div className="subtitle">Use STRX for 25% discount</div>
                                        </div>

                                        <label className="switch">
                                            <input type="checkbox" checked={ data.feeDiscountEnrol } onChange={ handleDiscountEnrol }/>
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </div>

                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <td>Date</td>
                                            <td className="long">Order Number</td>
                                            <td className="long">Transaction ID</td>
                                            <td className="medium">Original Fee</td>
                                            <td className="medium">Reduced Fee</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className="empty">
                                            <td>No fee history</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="content-title"><i className="fal fa-history"></i> { textStrings?.["account_unstakes"]?.[state.language] }</div>

                            <div className="unstakes">
                                <table>
                                    <thead>
                                        <tr className="table-header n600">
                                            <input type="checkbox" name="mergeUnstakes" onChange={ (e) => toggleCheckbox(e, "mergeUnstakes") }/>
                                            <td className="long">Amount</td>
                                            <td className="long">Start Date</td>
                                            <td className="long">Time Remaining</td>
                                        </tr>
                                    </thead>

                                    <tbody className="n600">
                                        {       
                                            (data.unstakes?.filter(s => parseFloat(s.quantity.split(" ")[0]))?.length < 1) && (
                                                <tr className="empty">
                                                    <td>No unstakes found</td>
                                                </tr>
                                            )
                                        }

                                        {
                                            data.unstakes.filter(s => parseFloat(s.quantity.split(" ")[0])).sort((a, b) => {
                                                return a.started - b.started
                                            }).map((s, i) => (
                                                <tr key={ i }>
                                                    <input type="checkbox" id={ "unstake-" + s.id } name="mergeUnstakes" onChange={ handleChange }/>
                                                    <td className="long bold">{ parseFloat(s.quantity.split(" ")[0]).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 4}).slice(1) } STRX</td>
                                                    <td className="long">{ new Date(s.started * 1000).toLocaleDateString() }</td>
                                                    { (s.started + data.unstakeTime >= Date.now() / 1000) ? <td className="long amber">{ handleCountdown((s.started + data.unstakeTime) - Date.now() / 1000) }</td> : <td className="long green"><i className="fal fa-unlock"></i> Unstaked</td> }
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                    <tbody className="b600">
                                        {       
                                            (data.unstakes?.filter(s => parseFloat(s.quantity.split(" ")[0]))?.length < 1) && (
                                                <tr className="empty">
                                                    <td>No stakes found</td>
                                                </tr>
                                            )
                                        }

                                        {
                                            data.unstakes.filter(s => parseFloat(s.quantity.split(" ")[0])).sort((a, b) => {
                                                return a.created - b.created
                                            }).map((s, i) => (
                                                <>
                                                    <tr className="click" key={ i } onClick={ (e) => toggleStakeCollapse(e, i, "unstake") }>
                                                        <input type="checkbox" id={ "unstake-" + s.id } name="mergeUnstakes" onChange={ handleChange }/>
                                                        <td className="long bold">{ parseFloat(s.quantity.split(" ")[0]).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 4}).slice(1) } STRX</td>
                                                        { (s.started + data.unstakeTime >= Date.now() / 1000) ? <td className="long amber">{ handleCountdown((s.started + data.unstakeTime) - Date.now() / 1000) }</td> : <td className="long green"><i className="fal fa-unlock"></i> Unstaked</td> }
                                                        <i className={ (inputs?.["unstake" + i]) ? "fal fa-chevron-up" : "fal fa-chevron-down" }></i>
                                                    </tr>
                                                    <tr className={ (inputs?.["unstake" + i]) ? "expand" : "collapse" }>
                                                        <div className="expand-gr">
                                                            <div className="subtitle">Start Date</div>
                                                            <td className="bold">{ new Date(s.started * 1000).toLocaleDateString() }</td>
                                                        </div>

                                                        <div className="expand-gr">
                                                            <div className="subtitle">Time Remaining</div>
                                                            { (s.started + data.unstakeTime >= Date.now() / 1000) ? <td className="amber">{ handleCountdown((s.started + data.unstakeTime) - Date.now() / 1000) }</td> : <td className="green"><i className="fal fa-unlock"></i> Unstaked</td> }
                                                        </div>
                                                    </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>

                                <button className="restake mass-action" onClick={ handleRestake } disabled={ (inputs.mergeUnstakes?.length < 1) ? true : false }>{ textStrings["account_restake"]?.[state.language] }</button>
                                <button className="withdraw mass-action" onClick={ handleWithdraw } disabled={ (inputs.mergeUnstakes?.length < 1) ? true : false }>{ textStrings["account_withdraw"]?.[state.language] }</button>
                                { /*
                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <td className="long">Start Date</td>
                                            <td className="long">Time Remaining</td>
                                            <td className="long">Amount</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {       
                                            (data.unstakes?.filter(s => parseFloat(s.quantity.split(" ")[0]))?.length < 1) && (
                                                <tr className="empty">
                                                    <td>No unstakes found</td>
                                                </tr>
                                            )
                                        }

                                        {
                                            data.unstakes.filter(s => parseFloat(s.quantity.split(" ")[0])).sort((a, b) => {
                                                return a.started - b.started
                                            }).map((s, i) => (
                                                <tr key={ i }>
                                                    <td className="long">{ new Date(s.started * 1000).toLocaleDateString() }</td>
                                                    { (s.started + data.unstakeTime >= Date.now() / 1000) ? <td className="long amber">{ handleCountdown((s.started + data.unstakeTime) - Date.now() / 1000) }</td> : <td className="long green"><i className="fal fa-unlock"></i> Unstaked</td> }
                                                    <td className="long bold">{ parseFloat(s.quantity.split(" ")[0]).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 4}).slice(1) } STRX</td>
                                                    <td className="fill">
                                                        <button onClick={ (event) => handleRestake(event, s.id) }>Restake</button>
                                                        <button disabled={ (s.started + data.unstakeTime <= Date.now() / 1000) ? false : true } onClick={ (event) => handleWithdraw(event, s.id) }>Withdraw</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                    */}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Staking;
