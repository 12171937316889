import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, regionCodesDictionary, countryCodesDictionary, supportedCurrencies, networkMemos } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

function VerifyWallet() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (!state.inputModal) return;
    }, [state.inputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (name == "username") {
            value = value.replace(/[^a-z1-5]/g, "");

            const lengthCheck = (value.length >= 4 && value.length <= 12);
            const characterCheck = (value.length > 0 && !value.match(/[^a-z1-5]/g));
            const caseCheck = (value.length > 0 && !value.match(/[A-Z]/g));

            if (lengthCheck && characterCheck && caseCheck) {
                const timestamp = Date.now();

                Paymex.accountExists(value).then(res => {
                    if (inputs.timestamp > timestamp) return;

                    setInputs(values => (
                        { ...values, timestamp: Date.now(), uniqueCheck: !res }
                    ));
                });
            };

            setInputs(values => (
                { ...values, lengthCheck: lengthCheck, characterCheck: characterCheck, caseCheck: caseCheck }
            ));
        };

        setInputs(values => (
            { ...values, [name]: value }
        ));

        $(".form-alert").removeClass("active");
    };

    function handleSubmit(event) {
        event.preventDefault();

        $("button.verify-wallet").html("<i class='fad fa-spinner-third'></i> Authenticating").attr("disabled", true);

        Account.verifyWallet(state.session.auth.actor).then(tokens => {
            Proton.verify(state.session, tokens.public).then(tx => {
                Account.requestLogin(state.session.auth.actor, tokens.public, tokens.private, (tx.serializedTransaction || tx.resolved.serializedTransaction), tx.signatures, tx.signer, tx.resolvedTransaction).then(res => {
                    $("div.input-modal, section").removeClass("active");

                    $("button.verify-wallet").html(textStrings["authenticate"][state.language]).attr("disabled", false);

                    dispatch({
                        type: "setAuth",
                        value: { id: state.session.auth.actor }
                    });
                }).catch(error => {
                    console.log(error);
    
                    $("button.verify-wallet").html(textStrings["authenticate"][state.language]).attr("disabled", false);
                });
            }).catch(error => {
                console.log(error);

                $("button.verify-wallet").html(textStrings["authenticate"][state.language]).attr("disabled", false);
            });
        }).catch(error => {
            console.log(error);

            $("button.verify-wallet").html(textStrings["authenticate"][state.language]).attr("disabled", false);
        });
    };

    function handleLogout() {
        if (state.w3aConnected) return Paymex.disconnectWallet(state, dispatch);

        if (!state.link || !state.session) return;

        state.link.removeSession("Storex", state.session.auth).then(res => {
            dispatch({
                type: "setAuth",
                value: null
            });

            dispatch({
                type: "setWallet",
                value: null
            });
        });
    };

    return (
        <section className="verify-wallet">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-key"></i> Authenticate Wallet  <span className="click sign-out" onClick={ handleLogout }>Sign Out</span></div>

                <div className="create-wrapper">
                    <form className="refund-order" onSubmit={ handleSubmit }>
                        <div className="roadmap">
                            <span><i className="fal fa-check-circle"></i> Wallet Connected</span>
                            <i className="fal fa-long-arrow-down"></i>
                            <span><i className="fal fa-check-circle"></i> Account Exists</span>
                            <i className="fal fa-long-arrow-down"></i>
                            <span><i className="fal fa-circle"></i> Wallet Authenticated</span>
                        </div>

                        <button type="submit" className="submit verify-wallet">{ textStrings["authenticate"][state.language] }</button>
                    </form>
                </div>
            
            </div>
        </section>
    );
};

export default VerifyWallet;