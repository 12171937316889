import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, currencyDictionary, languageDictionary, dummyCategories } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

function CartModal({ mobile }) {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({ category: "", search: "", cart: [] });
    const [menuStates, setMenuState] = useState({ cart: false, cartMobile: false });
    const [state, dispatch] = useStateValue();

    function handleRedirect(mobile) {
        if (mobile) {
            setMenuState(values => (
                { ...values, cartMobile: false }
            ));

            dispatch({
                type: "closeMobileMenu",
                value: true
            });
        } else {
            setMenuState(values => (
                { ...values, cart: false }
            ));
        };
    };

    function handleCart(event, mobile) {
        const target = event.target;

        var results = $(".cart-wrapper").find(target).length;

        if (target == $(".cart-wrapper")) {
            setMenuState(values => (
                { ...values, cartMobile: !menuStates["cartMobile"] }
            ));

            return;
        };

        if (results > 0) return;

        setMenuState(values => (
            { ...values, cart: !menuStates["cart"] }
        ));
    };
    
    function handleCartQty(addItem, itemId, variation, quantity) {
        if (!addItem) {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res
                });

                //$("div.error-modal").addClass("success").find("div.text").html("Removed listing from shopping cart");
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error removing listing from shopping cart");
            };

            Cart.removeListing(state.session.auth.actor, itemId, variation, quantity || 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message?.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.removeListing(state.session.auth.actor, itemId, variation, quantity || 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        } else {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res.cart
                });

                if (res.exceedStock) {
                    //$("div.error-modal").addClass("warning").find("div.text").html("Added listing to shopping cart with remaining stock");
                } else {
                    //$("div.error-modal").addClass("success").find("div.text").html("Added listing to shopping cart");
                };
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error adding listing to shopping cart");
            };

            Cart.addListing(state.session.auth.actor, itemId, variation, quantity || 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message?.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.addListing(state.session.auth.actor, itemId, variation, quantity || 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        };
    };

    return (
        <div>
            <div className="click" onClick={ (e) => handleCart(e, mobile) }>
                <div className="icon-wrapper" onClick={ () => (mobile ? setMenuState(values => ( { ...values, cartMobile: !menuStates["cartMobile"] } )) : setMenuState(values => ( { ...values, cart: !menuStates["cart"] } ))) }>
                    <i className="fal fa-shopping-cart"></i>
                    <span className={ "count" + (state.cart?.reduce((a, b) => a = a + parseFloat(b.quantity), 0) < 1 ? " hidden" : "") }>{ state.cart?.reduce((a, b) => a = a + parseFloat(b.quantity), 0) }</span>
                </div>

                <p onClick={ () => (mobile ? setMenuState(values => ( { ...values, cartMobile: !menuStates["cartMobile"] } )) : setMenuState(values => ( { ...values, cart: !menuStates["cart"] } ))) }>{ textStrings["header_cart"][state.language] }</p>
            </div>

            <div className={ "cart-wrapper" + (mobile ? menuStates["cartMobile"] ? " active" : "" : menuStates["cart"] ? " active" : "") } onClick={ (e) => handleCart(e, mobile) }>
                <div className="cart-overlay">
                    <div className="cart-header">
                        <span>{ textStrings["header_cart_title"][state.language] }</span>
                        <button onClick={ () => handleRedirect(mobile) }>{ textStrings["header_cart_close"][state.language] } <i className="fal fa-long-arrow-right"></i></button>
                    </div>

                    <div className="listings">
                        {
                            (state.cart?.length > 0) && (
                                state.cart?.map((item, index) => {
                                    return (
                                        <div key={ index } className="listing">
                                            <Link to={ "/listing/" + item._id } onClick={ () => handleRedirect(mobile) }><div className="thumbnail"><img src={ item.thumbnail } alt={ item.title }/></div></Link>
                                            <div className="details">
                                                <Link to={ "/listing/" + item._id } onClick={ () => handleRedirect(mobile) }><div className="title">{ item.title }</div></Link>

                                                <div className="price-wrapper">
                                                    {
                                                        (item.quantity > 0) && (
                                                            <div className="price">{ parseFloat(item.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                                        )
                                                    }

                                                    {
                                                        (item.quantity < 1) && (
                                                            <div className="price out-stock">Out of Stock</div>
                                                        )
                                                    }
                                                    <div className="quantity"><i className="fal fa-minus-circle" onClick={ () => handleCartQty(false, item._id, item.variation.id) }></i> { item.quantity } <i className="fal fa-plus-circle" onClick={ () => handleCartQty(true, item._id, item.variation.id) }></i></div>
                                                </div>

                                                {
                                                    (item.variation.option0) && (
                                                        <div className="variation">
                                                            <p>{ item.variation.option0 }</p>

                                                            {
                                                                (item.variation.option1) && (
                                                                    <>
                                                                        <p>/</p>
                                                                        <p>{ item.variation.option1 }</p>
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                (item.variation.option2) && (
                                                                    <>
                                                                        <p>/</p>
                                                                        <p>{ item.variation.option2 }</p>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }

                                                <div className="stock">{ item.variation?.stock > 0 && item.active ? <div className="in-stock"><i className="fas fa-check"></i><span>In Stock</span></div> : <div className="out-stock"><i className="fas fa-times"></i><span>Out of Stock</span></div> }</div>
                                            </div>
                                            <div className="remove"><i className="fal fa-times-circle" onClick={ () => handleCartQty(false, item._id, item.variation.id, item.quantity) }></i></div>
                                        </div>
                                    )
                                })
                            )
                        }

                        {
                            (state.cart?.length < 1) && (
                                <div className="empty">Your shopping cart is empty</div>
                            )
                        }
                    </div>

                    {
                        (state.cart?.length > 0) && (
                            <div>
                                <div className="cart-total">
                                    <label>{ textStrings["header_cart_subtotal"][state.language] }</label>
                                    <div className="subtotal">{ state.cart?.reduce((a, b) => a = a + parseFloat(parseFloat(b.price) * b.quantity), 0).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                </div>

                                <div className="cart-actions">
                                    <Link to="/cart" onClick={ () => handleRedirect(mobile) }><button className="secondary">{ textStrings["header_cart_view"][state.language] }</button></Link>
                                    <Link to="/checkout" onClick={ () => handleRedirect(mobile) }><button className="primary">{ textStrings["header_cart_checkout"][state.language] }</button></Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default CartModal;
