import { React, useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { useStateValue } from "state";
import { apiEndpoint, defaultVouchers } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

import cJson from "categories.json";

function ImportListingModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (state.inputModal?.action != "import") return;

        setInputs(values => (
            { ...values }
        ));
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        console.log(name, value);

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handlePaste(event) {
        event.preventDefault();

        const target = event.target;
        const name = $(target).prev().attr("name");

        navigator.clipboard.readText().then(paste => {
            setInputs(values => (
                { ...values, [name]: paste }
            ));
        });
    };

    async function handleImport(event) {
        event.preventDefault();

        // scrape url for https + potential listing id

        // send listing id to server

            // server checks ebay API for listing data

            // server maps listing categories to storex categories
    };

    return (
        <section className="import-listing">
            <div className="content no-overflow">
                <div className="form-title"><i className="fal fa-paste"></i> { textStrings["import_listing"]?.[state.language] }</div>

                <div className="import-wrapper">
                    <form onSubmit={ handleImport }>
                        <div className="input-group">
                            <label htmlFor="importSite">Platform</label>

                            <div className="radio-wrapper">
                                <div>
                                    <input type="radio" name="importSite" id="importSite-ebay" value="ebay" onChange={ handleChange }/>
                                    <label htmlFor="importSite-ebay"><i className="fab fa-ebay"></i> eBay</label>
                                </div>

                                <div>
                                    <input type="radio" name="importSite" id="importSite-amazon" value="amazon" onChange={ handleChange } disabled/>
                                    <label htmlFor="importSite-amazon" title="Not Supported"><i className="fab fa-amazon"></i> Amazon</label>
                                </div>

                                <div>
                                    <input type="radio" name="importSite" id="importSite-etsy" value="etsy" onChange={ handleChange } disabled/>
                                    <label htmlFor="importSite-etsy" title="Not Supported"><i className="fab fa-etsy"></i> Etsy</label>
                                </div>

                                <div>
                                    <input type="radio" name="importSite" id="importSite-shopify" value="shopify" onChange={ handleChange } disabled/>
                                    <label htmlFor="importSite-shopify" title="Not Supported"><i className="fab fa-shopify"></i> Shopify</label>
                                </div>

                                <div>
                                    <input type="radio" name="importSite" id="importSite-wordpress" value="wordpress" onChange={ handleChange } disabled/>
                                    <label htmlFor="importSite-wordpress" title="Not Supported"><i className="fab fa-wordpress"></i> WooCommerce</label>
                                </div>
                            </div>
                        </div>

                        <div className="input-group">
                            <label htmlFor="importUrl">Listing URL</label>
                            <input type="text" name="importUrl" autoComplete="none" maxLength="17" value={ inputs.importUrl || "" } onChange={ handleChange } required/>
                            <span className="click paste" onClick={ handlePaste }>Paste</span>
                        </div>

                        <button type="submit" className="submit import">Import</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ImportListingModal;